import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
function Mobileheader(){
    let navigate = useNavigate(); 
    function mobilemaplsearch(){
        var mobilemaplmainsearchinput = document.getElementById('mobilemaplmainsearch').value.toLowerCase();
        navigate(mobilemaplmainsearchinput);
        Navclose();
        document.getElementById('mobilemaplmainsearch').value="";
        }
    function Navclose(){
        document.getElementById('navclosebtn').style.display="none";
        document.getElementById('navopenbtn').style.display="block";
        document.getElementById('Mobile_navbar').style.height="0px";
        document.getElementById('mobiledowndiv').style.height="0px";
        setTimeout(function(){
        document.getElementById('Mobile_navbar').style.display="none";
        },580);
        }
        function NavOpen(){
        
        document.getElementById('Mobile_navbar').style.display="block";
        document.getElementById('navopenbtn').style.display="none";
        document.getElementById('navclosebtn').style.display="block";
        setTimeout(function(){
        document.getElementById('Mobile_navbar').style.height="100%";
        document.getElementById('mobiledowndiv').style.height="100px";
        },10)
        }
    return(
<div>
<div id="Mobile_navbar">
<img src="MAPL IMAGES/maplworld_logo.png" alt=""/>
<div id="Home_Searchbox">
<div id="Home_Searchdiv">
            <input type="search" list="MAPLSEARCHLIST" placeholder=" Search......" id="mobilemaplmainsearch"/>
            <button onClick={mobilemaplsearch}><i class="material-icons">&#xe8b6;</i></button>
            <datalist id="MAPLSEARCHLIST">
            <option value="About"/>
            <option value="Career"/>
            <option value="Internship"/>
            <option value="Terms"/>
            <option value="Privacy"/>
            <option value="Contact"/>
            <option value="Jobs"/>
            <option value="Csr"/>
            <option value="FAQ"/>
            <option value="Enterprise management"/>
    <option value="Intelligent service"/>
    <option value="Data AI Management Services"/>
    <option value="Business Processes Services"/>
<option value="Cyber Security"/>
    <option value="Cloud Service"/>
    <option value="Sustainability Service"/>
    <option value="Infrastructure Services"/>
            <option value="SmartCity Solution"/>
 <option value="Data center solutions"/>
<option value="Network Security Solution"/> 
 <option value="WirelessSolutions"/>
 <option value="CCTV Surveillance Solution"/>
 <option value="Unified Communication"/>
 <option value="Rugged Military Grade"/>
<option value="Telecommunication solution"/>
 <option value="CloudSolution"/>
 <option value="Smart ClassRoom solution"/>
<option value="Mobile Video Conferencing"/>
 <option value="Security surveillance solution"/>
 <option value="AVTS AVLS Solution"/>
 <option value="Mobile Surveillance System"/>
 <option value="Network Element Systems solutiion"/>
 <option value="SaaS Development"/>
 <option value="application performance system"/>
 <option value="Custom Software Solution"/>
 <option value="Intelligent infrastructur SmartCity"/>
 <option value="high end security solution"/>
 <option value="AI and Machine Learning Solution"/>
 <option value="LawfulInterceptMonitoring"/>
 <option value="Network Management System"/>
 <option value="Intelligent Traffic Management Solution"/>
 <option value="Managed Services solution"/>
 <option value="FacialRecognitionTechnology"/>
 <option value="Power supply solution"/> 
            
            </datalist>
            </div>
</div>
<nav>
<details>
<summary><a><p>Solutions </p><i class="fa">&#xf107;</i></a></summary>
<ul>
   <li> <Link to="/SmartCity Solution">Smart City Solutions </Link></li>
   <li> <Link to="/data center solutions">Data Centre Solutions </Link></li>
<li><Link to="/Network Security Solution">Network and Security Solutions</Link> </li>
<li> <Link to="/WirelessSolutions">Wireless Solutions</Link></li>
<li> <Link to="/CCTV Surveillance Solution">CCTV Surveillance</Link></li>
<li> <Link to="/Unified Communication">Unified Communication</Link></li>
<li> <Link to="/Rugged Military Grade">Rugged and Mil Grade Solutions</Link></li>
<li><Link to="/telecommunication solution">Telecom Products and RF Solutions </Link></li>
<li> <Link to="/CloudSolution">Cloud Solutions</Link></li>
<li> <Link to="/Smart ClassRoom solution">Smart Class Room solution</Link></li>

<li><Link to="/Mobile Video Conferencing">Mobile Video Conferencing </Link></li>
<li> <Link to="/security surveillance solution">Security and Surveillance Solutions</Link></li>
<li> <Link to="/AVTS AVLS Solution">AVTS/AVLS Solutions</Link></li>
<li> <Link to="/Mobile Surveillance System">Mobile Surveillance Solutions</Link></li>
<li> <Link to="/Network Element Systems solutiion">Elevating Connectivity Solutions</Link></li>
<li> <Link to="/SaaS Development">SaaS Development Solutions</Link></li>
<li> <Link to="/application performance system">Application Performance System Solutions</Link></li>
<li> <Link to="/Custom Software Solution">Custom Software Development Solutions</Link></li>
<li> <Link to="/intelligent infrastructur SmartCity">Intelligent Infrastructure Management</Link></li>
<li> <Link to="/high end security solution">High-End Security Surveillance</Link></li>
<li> <Link to="/AI and Machine Learning Solution">AI and Machine Learning Solutions</Link></li>
<li> <Link to="LawfulInterceptMonitoring">Lawful intercept monitoring</Link></li>
<li> <Link to="/Network Management System">Network Management System Solutions</Link></li>
<li> <Link to="/Intelligent Traffic Management Solution">Intelligent Traffic Management </Link></li>
<li> <Link to="/Managed Services solution">Managed Services Solutions</Link></li>
<li> <Link to="/FacialRecognitionTechnology"> Facial Recognition Solutions</Link></li>
<li> <Link to="/Power supply solution"> Power Supply Solutions</Link></li>

</ul>
<br/>
</details> 
<details>
<summary><a><p>Services </p><i class="fa">&#xf107;</i></a></summary>
<ul>
    <li><Link to="/enterprise management">Enterprise Management</Link></li>
    <li><Link to="/Cyber Security">Cyber Security</Link></li>
    <li><Link to="/Cloud Service">Cloud Service</Link></li>
    <li><Link to="/intelligent service">Intelligent Industry</Link></li>
   <li> <Link to="/Sustainability Service">Sustainability</Link></li>
    <li><Link to="/Data AI Management Services">Data and AI Management</Link></li>
    <li><Link to="/Business Processes Services">Business Processes</Link></li>
    <li><Link to="/Infrastructure Services">Infrastructure</Link></li>

</ul>
<br/>
</details>
<details>
<summary><a><p>Company</p> <i class="fa">&#xf107;</i></a></summary>
<ul>
<li><Link to="/about">About</Link></li>
<li><Link to="/career">Career</Link></li>
<li><Link to="/csr">CSR Activities</Link></li>
<li><Link to="/about/#missionheading">Values and Mission</Link></li>

</ul>
<br/>
</details>
<details>
<summary><a><p>Case Studies</p><i class="fa">&#xf107;</i></a></summary>
<ul>
   <li> <a href="MAPL IMAGES/CASE STUDIES/DELHI POLICE/DELHI POLICE - Design, Supply, Installation, Commissioning and Maintenance of CCTV Cameras in delhi.pdf">Delhi police</a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/DRDO/DRDO  Transformative Partnership MAPL revolutionised Defense Research with Innovative Storage Solutions.pdf">DRDO</a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/DTC/Guardians in Transit  MAPL_s Implemented Advanced Surveillance Solutions in Delhi Transport C.pdf">Delhi E-Mobility</a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/JAP-IT/MAPL_s Revolutionary Impact on Jharkhand_s Legal System with Advanced Video_20240107_175509_0000.pdf">Transforming State </a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/JEPC/Transforming Jharkhands Educational Landscape MAPL_s Visionary Impact with_20240107_175252_0000 (1).pdf">Jharkhand Eduction Project </a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/LIMS/Establishment of Lawful Interception and Monitoring System (LIMS) for providing International.pdf">Electronic Surveillance</a></li>
    <li><a href="MAPL IMAGES/CASE STUDIES/GOVT OF BIHAR - OUTDOOR MOBILE VIDEO CONFERENCING/Supply And Installation Of Outdoor Mobile Video Conferencing Across The State Of Bihar (1).pdf">Govt of Bihar</a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/GOVT OF UTTARAKHAND/GOVT OF UTTARAKHAND-  ENHANCED  VIDEO CONFERENCING  EXPERIENCE FOR UTTARAKHAND.pdf">Elevating Defense Infrastructure</a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/BSF - PARA MILITARY/BSF - INDIAN PARA-MILITARY FORCE (SECURED WIDE AREA NETWORK)_20240107_180321_0000.pdf">BSF </a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/SSB - PARA MILITARY/SSB - INDIAN PARA-MILITARY FORCE (SECURED WIDE AREA NETWORK)_20240107_180017_0000 (3).pdf">SSB</a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/BSF - PARA MILITARY/BSF - GIS Geospatial Revolution MAPL established GIS Labs for Enhanced Border Security.pdf">BSF GIS </a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/Bangalore Municipal Corporation and Transportation Authorities/SECURED MOBILE COMMUNICATION WITH  ASSOCIATED CENTRALISED DEVICE MANAGEMENT_20240107_212025_0000.pdf">BEMP & BMTC</a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/Intelligent Traffic Management Systems (ITMS)/TRAFFIC MANAGEMENT The Dawn of Efficient Urban Mobility Unleashing MAPL_s  Intelligent Traffi.pdf">MCGM & Bengaluru Traffic Police</a></li>
  <li>  <a href="MAPL IMAGES/CASE STUDIES/BEL - IACCS/IACCS Elevating Defense Infrastructure A Pinnacle in Excellence with MAPL_s IACCS Data Wall Solution (1).pdf">BEL</a></li>
  <li>  <a href="MAPL IMAGES/CASE STUDIES/WAC/Command HQ Project - Advancing and Strengthening Security Infrastructure, D_20240107_180414_0000.pdf">Central Command HQ </a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/DEFENCE - WAR ROOMS/Revolutionizing Defence Communication  MAPL_s Cutting-Edge War Room Solutio_20240107_175713_0000.pdf">Defence</a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/Digital Enterprise Management/Enhanced Efficiency Revolutionizing Digital Enterprise Management MAPL_s Strategic Solutions .pdf">Corporate Efficiency </a></li>
   <li> <a href="MAPL IMAGES/CASE STUDIES/SPIC - Emergency Communication/SPIC - Revolutionizing Emergency Communication MAPL_s Swift Response, Fortified Security, and Versatile Solutions for SPIC.pdf">SPIC</a></li>
</ul>
<br/>
</details>

</nav>
<div id="mobiledowndiv">
<div class="nav_buttons_div">
<Link to="/contact">
<button id="nav_secondbutton">Get in Touch</button></Link>
</div>
</div>
</div>
<div id="MobileNavDiv">
<Link to="/"><img src="MAPL IMAGES/maplworld_logo.png" loading="lazy" alt="" /></Link>
<button id="navopenbtn" onClick={NavOpen}><i class="material-icons">&#xe5d2;</i></button>
<button id="navclosebtn" onClick={Navclose}> <i class="material-icons">&#xe5cd;</i> </button>
</div>

</div>

    )
}
export default Mobileheader;