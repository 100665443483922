import { Link } from "react-router-dom";
import React from 'react';
function BusinessDevelopmentJob(){
return(
    <div>
<div id="jobpage">
    <div id="jobhomediv">
        <h2>Business Development</h2>
    </div>
<div id="Jopdescription">
<section>
<div>
<h2>Business Development</h2>
<p> <i class="material-icons" style={{color:"#00437d",display:"inline"}}> &#xe568;</i>Okhla
&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa" style={{color:"#00437d",display:"inline",fontSize:"22px"}}> &#xf017;</i> Full Time
</p>
</div>
<div>
<h4>Job Description</h4>
<br/>
<ul>
<li>Participating in all open/Limited Tenders, Preparation of proposals, Preparation to completion of Pre-qualification criteria of the tender</li>
<li>Conducting market research with regard to clients, industry and competitors</li>
<li>Work as a representative of MAPL in all opportunities</li>
<li>Effective communication and Coordination with technical & Financial team for tender opportunities</li>
<li>Support in the planning, preparation and Coordination of meetings, calls and communications with Gov. Bodies/ /PSUs</li>
<li>Successfully managing the OEMs /stakeholders for synergizing the deliverables related to tenders</li>
<li>Maintain confidential records and contacts database</li>
<li>Being aware of all contract possibilities and add on business opportunities.</li>
<li>Coordinate with the Social Media Team to keep up to date professional marketing materials.</li>
</ul>
</div>
</section>
<section>
<div>
<img src="MAPL IMAGES/JOBS - MAPL WORLD/BUSINESS DEVELOPMENT - MILLENNIUM AUTOMATION PVT LTD (MAPL).png" loading="lazy" alt="" />
<label for="resumegetinpu" onclick="uploadresume()" id="uploadresumeid">Upload Resume</label>
<Link to="mailto:hr@maplworld.com?subject=Job Application with Resume"><button id="applynowjob">Apply now</button></Link> 
</div>
<div>
<p><b>Employment Status : </b> Full-time</p>
<p><b>Experience : </b>2-8 years </p>
<p><b>Job Location : </b> Okhla</p>
</div>
<div>
<h4>HR Manager</h4>
<p>
    Millennium Automation Private Limited </p>
   <p> E 48/9,Okhla Industrial Area, Phase-II, </p>
  <p>  New Delhi 110020</p>
<p>hr@maplworld.com</p>
<p>+91-98710 08021</p>
</div>
</section>
</div>
<div id="getresumediv">
<div>
<p>Ready to join our dynamic team? Share your resume; your skills and passion could be the perfect fit.</p>
</div>
<div>

<a href="mailto:hr@maplworld.com?subject=Job Application with Resume"><label id="labelgetinput">Upload Resume</label></a>
</div>
</div>
</div>
    </div>
)
}
export default BusinessDevelopmentJob;