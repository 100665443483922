import React from 'react';
function InfrastructureServices(){
    return(
        <div>
            <div id="Services_page">
<div id="ServiceHome_div">

</div>
<div id="ServiceHome_Detail">
    <h2>Infrastructure Services with MAPL </h2>
    <h4>Unleashing the Power of Robust Foundations</h4>
    <p>Welcome to MAPL's Infrastructure Services, where we redefine the future of technological foundations. Our commitment to excellence drives us to deliver innovative solutions that lay the groundwork for seamless, secure, and scalable operations.
    </p>
    </div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/Cloud Security Safeguarding Your Digital Frontier/Managed Services_ Elevating Your Cloud Experience - - Millennium Automation Pvt Ltd (MAPL).png" loading="lazy" alt="" /></div>
<section class="ServiceAboutright_section">
<h4>Cloud Solutions</h4>
<p>MAPL specializes in crafting dynamic cloud solutions tailored to your business needs. From migration to optimization, we ensure your journey to the cloud is smooth, cost-effective, and future-ready.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Network Architecture</h4>
<p>Our expertise extends to designing and implementing robust network architectures. MAPL ensures your business stays connected with cutting-edge solutions that enhance efficiency, security, and adaptability.</p>
</section>
<div><img src="MAPL IMAGES/Enterprise Service Management (ESM) (1)/Digital Core.png" loading="lazy" alt="" /></div>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Automated Network Monitoring.png" loading="lazy" alt="" /></div>
<section class="ServiceAboutright_section">
<h4>Data Center Solutions</h4>
<p>MAPL's Data Center Solutions are designed to address the evolving needs of modern businesses. From colocation services to dedicated servers, we optimize your data management strategies for peak performance.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Cybersecurity Measures</h4>
<p>Securing your digital assets is our priority. MAPL employs state-of-the-art cybersecurity measures, including threat detection, risk mitigation, and compliance adherence, to safeguard your business from potential threats.</p>
</section>
<div><img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Streamlined Operations.png" loading="lazy" alt="" /></div>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Dynamic Asset Tracking.png" loading="lazy" alt="" /></div>
<section class="ServiceAboutright_section">
<h4>Infrastructure as a Service (IaaS)</h4>
<p>MAPL's IaaS offerings provide a flexible and scalable infrastructure foundation. Experience the benefits of a tailored IT environment, allowing your business to thrive with enhanced resource management and cost efficiency.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>IT Consultancy and Planning</h4>
<p>Strategic planning is crucial for a resilient infrastructure. MAPL offers expert IT consultancy to help you navigate technological challenges, growth plan, and implement solutions that align with your business objectives.</p>
</section>
<div><img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Adaptability to Growth.png" loading="lazy" alt="" /></div>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Compliance and Documentation Made Easy.png" loading="lazy" alt="" /></div>
<section class="ServiceAboutright_section">
<h4>End-User Support and Training</h4>
<p>We understand the significance of user-centric infrastructure. MAPL provides end-user support and training, ensuring that your team is equipped with the knowledge to harness the full potential of your technological ecosystem. </p>
</section>
</span>
</div>
<div id="ServiceThird_div" >
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Scalability and Future-Ready Designs.png" loading="lazy" alt="" />
<div>
<h4>Elevate Your Business with MAPL's Infrastructure Expertise</h4>
<p>MAPL's Infrastructure Services pave the way for a technologically empowered future. Whether you're aiming for cloud excellence, network resilience, or fortified cybersecurity, we are your partners in building a robust foundation for sustained success.</p>
</div>
</div>
</div>
        </div>
    )
}
export default InfrastructureServices;