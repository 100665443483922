import "./Internship.css";
import React from 'react';
function Internship(){
    return(
        <div>
            <div id="IntershipPage">
<div id="IntershipHomediv">
  
<div>
<h1>Learn to Lead</h1>

</div>
<img src="MAPL IMAGES/Internship_page_-_Millennium_Automation_Pvt_Ltd.png" alt="" loading="lazy"/>
</div>
<div id="main_h3heading_div">
<h3>Internship Perks</h3>
<br/>
<p id="mainpragrap">Millennium Automation dynamic internships prepare talent for successful and fulfilling careers, with access to top leaders across our organization.</p>
</div>
<div id="internshipBenifits_div">
</div>
<div id="main_h3heading_div">
<h3>Type of Internship</h3>
<p id="mainpragrap">Immerse yourself in the industry with
on-the-job training to enhance your expertise</p>
</div>
<div id="TypeofInternship_div">
<img src="MAPL IMAGES/TYPES OF INTERNSHIP 1920x1080.png" loading="lazy" alt=""/>
<br/>
<br/>


</div>
</div>
        </div>
    )
}
export default Internship;