import { Link } from "react-router-dom";
import React from 'react';
function Companynavfunction(){
  
    return(
        <div  className="navigationscrooldiv">
<div id="navigation_information_div">
<h3>Organization</h3>
<br/>
<p>
We value the profound and long lasting relationships we have been able to build and the alliances
we have made in this process. These are some of the organizations and brands that have become
our entrusted partners over the years and have offered us dependable solutions.
</p>

</div>
<div id="navigation_detailsOneDiv">
<h3>Company</h3>
<Link to="/about"> <h4>About Us</h4> <p><i class="material-icons">&#xe315;</i></p></Link>
<Link to="/about"> <h4>Value and Mission</h4> <p><i class="material-icons">&#xe315;</i></p></Link>
<Link to="/career"> <h4>Careers</h4> <p><i class="material-icons">&#xe315;</i></p></Link>

<Link to="/csr"> <h4>CSR Activities</h4> <p><i class="material-icons">&#xe315;</i></p></Link>
</div>
<div id="navigation_images">
<a><img src="MAPL IMAGES/Company of Mapl.jpg"alt=""/></a>

</div>
        </div>
    )
}
export default Companynavfunction;