import { Link } from "react-router-dom";
import React from 'react';
function ContractComplianceJob(){
return(
    <div>
<div id="jobpage">
    <div id="jobhomediv">
        <h2>Contract and Compliance</h2>
    </div>
<div id="Jopdescription">
<section>
<div>
<h2>Contract and Compliance</h2>
<p> <i class="material-icons" style={{color:"#00437d",display:"inline"}}> &#xe568;</i>Okhla
&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa" style={{color:"#00437d",display:"inline",fontSize:"22px"}}> &#xf017;</i> Full Time
</p>
</div>
<div>
<h4>Job Description</h4>
<br/>
<ul>
<li>Qualified Company Secretary /Law graduate with minimum 3 years of experience .</li>
<li>Maintaining statutory books, including registers of directors, minutes book, statutory registers as required by the law and compliance.</li>
<li>Drafting, Vetting ,Reviewing, Finalizing, POA, Affidavits, Letter of Authority, various Agreements, Memorandum of Understanding, Agreements for Security Personnel, Outsourcing Agreements, Non-Disclosure Agreement, Customer Agreements, Notices etc.</li>
<li>Attend project meetings to understand the legality of contracting.</li>
<li>Can work under Advocates and attend legal hearings on behalf of company.</li>
<li>Liasoning with Banks and Government authorities.</li>
<li>Preparing and Managing Company Policies .</li>
<li>Preparing legal documentations for compliance with the help of Legal desk..</li>
<li>Should be well exposed in company's legal and financial cases.</li>
<li>Preparation of Notice, Agenda and Director's Report.</li>
<li>Preparation of documents regarding increase in authorization of share capital, change of name of company, change in registered officce</li>
<li>Filing of forms with MCA as per new act and rules.</li>
<li>Exposure to Joint Ventures and its working all with Compliance under Investment Agreements is critical.</li>

</ul>
</div>
</section>
<section>
<div>
<img src="MAPL IMAGES/JOBS - MAPL WORLD/BUSINESS DEVELOPMENT - MILLENNIUM AUTOMATION PVT LTD (MAPL).png" loading="lazy" alt="" />

<Link to="mailto:hr@maplworld.com?subject=Job Application with Resume"><button id="applynowjob">Apply now</button></Link> 
</div>
<div>
<p><b>Employment Status : </b> Full-time</p>
<p><b>Experience : </b>2-8 years </p>
<p><b>Job Location : </b> Okhla</p>
</div>
<div>
<h4>HR Manager</h4>
<p>
    Millennium Automation Private Limited </p>
   <p> E 48/9,Okhla Industrial Area, Phase-II, </p>
  <p>  New Delhi 110020</p>
<p>hr@maplworld.com</p>
<p>+91-98710 08021</p>
</div>
</section>
</div>
<div id="getresumediv">
<div>
<p>Ready to join our dynamic team? Share your resume; your skills and passion could be the perfect fit.</p>
</div>
<div>

<a href="mailto:hr@maplworld.com?subject=Job Application with Resume"><label id="labelgetinput">Upload Resume</label></a>
</div>
</div>
</div>
    </div>
)
}
export default ContractComplianceJob;