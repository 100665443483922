import { Link } from "react-router-dom";
import React from 'react';
function Servicenavfunction(){
    return(
        <div className="navigationscrooldiv">
            <div id="navigation_information_div">
<h3>Offerings</h3>
<br/>
<p>IT Infrastructure Services are expected to make enterprises more focused on service, secure and agile, and with an ability to deliver converged experiences to their clients. Technology trends such as Analytics, Cloud, IoT and Cognitive Computing are creating new business imperatives.
</p>

</div>
<div id="navigation_detailsDIV">
<h3>Services</h3>
<div class="navigation_details" id="navigationDeatailscroll">
<Link to="/enterprise management"><h4><span> Enterprise Management </span> <i class="material-icons">&#xe315;</i></h4><h6> Think Smarter. Deliver Faster.</h6> </Link>
<Link to="/Cyber Security"><h4><span> Cyber Security </span> <i class="material-icons">&#xe315;</i></h4><h6>Elevate Your Security, Drive Transformation </h6></Link>
<Link to="/intelligent service"><h4><span> Intelligent Industry </span> <i class="material-icons">&#xe315;</i></h4><h6>Embracing the Era of Digital Transformation</h6></Link>
<Link to="/Cloud Service"><h4><span> Cloud Services</span> <i class="material-icons">&#xe315;</i></h4><h6> Start Your Journey to Innovation</h6></Link>
<Link to="/Data AI Management Services"><h4><span> Data and AI Management </span> <i class="material-icons">&#xe315;</i></h4> <h6> Master Your Data, Empower Your Organization</h6></Link>
<Link to="/Sustainability Service"><h4><span> Sustainability </span> <i class="material-icons">&#xe315;</i></h4><h6> Accelerate to Net Zero: A Sustainable Future</h6></Link>
<Link to="/Business Processes Services"> <h4><span> Business Processes </span> <i class="material-icons">&#xe315;</i></h4><h6>Creating Digital Dominance </h6></Link>
<Link to="/Infrastructure Services"> <h4><span> Infrastructure </span> <i class="material-icons">&#xe315;</i></h4><h6> Unleashing the Power of Robust Foundations</h6></Link>
</div>
</div>
<div id="navigation_images">
<a href=""><img src="MAPL IMAGES/Services of MAPL.jpg" alt=""/></a>

</div>
        </div>
    )
}
export default Servicenavfunction;