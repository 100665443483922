import { Link } from "react-router-dom";
import React from 'react';
function WirelessSolutions(){
return(
    <div>
<div id="category_page" >
<div id="category_home_div">
<div class="category_home_Detail_div">
<h1>Unleashing Connectivity: MAPL's Wireless Solutions</h1>
<p><b>Elevate Your Connectivity with MAPL</b>
<br/>
MAPL's Wireless Solutions, where innovation meets connectivity to shape the future. Our expertise in wireless technology redefines possibilities, ensuring your business stays connected, efficient, and ahead of the curve.
</p>
<Link to="/contact"><button>Contact us</button></Link>
</div>
<img src="MAPL IMAGES/WIRELESS/WIRELESS SOLUTIONS.png" loading="lazy" alt="" />
</div>
<div id="category_scrollDiv">
<h3 style={{color:"white"}} >Our Technology Partners</h3>
<div class="category_scrollImageDiv">
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/DELL - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/HP - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/POLY LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/TEJAS LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/BARCO LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
</div>
</div>
<div id="category_about_div">
<h3>Wireless Solutions by MAPL</h3>
<div id="category_about_TwoDiv">
<section>
<img src="MAPL IMAGES/WIRELESS/Wireless Infrastructure Design and Deployment IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
<div>
<h4>Wireless Infrastructure Design and Deployment</h4>
<div id="detailsDiv">
<details>
<summary><h5>&#11166; &nbsp;Tailored Designs</h5></summary>
<p>Tailored Designs: MAPL crafts customized wireless infrastructure designs based on your unique business needs, ensuring optimal coverage and performance.</p>
</details>
<details>
<summary><h5>&#11166; &nbsp;Efficient Deployment</h5></summary>
<p>Efficient Deployment: Our skilled team ensures the seamless implementation of wireless solutions, minimizing downtime and maximizing efficiency.</p>
</details>
</div>
</div>
</section>
<section>
<div>
<h4>Next-Gen Wi-Fi Solutions</h4>
<div id="detailsDiv">
<details>
<summary><h5>&#11166; &nbsp;High-Speed Connectivity</h5></summary>
<p>High-Speed Connectivity: Experience next-level connectivity with our Wi-Fi solutions, designed to deliver high-speed internet access for your business operations.</p>
</details>
<details>
<summary><h5>&#11166; &nbsp;Scalability</h5></summary>
<p>Scalability: MAPL's Wi-Fi solutions grow with your business, providing scalable options to accommodate increasing connectivity demands.</p>
</details>
</div>
</div>
<img src="MAPL IMAGES/WIRELESS/Next-Gen Wi-Fi Solutions - IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
</section>
<section>
<img src="MAPL IMAGES/WIRELESS/Wireless Security and Threat Mitigation - IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
<div>
<h4>Wireless Security and Threat Mitigation</h4>
<div id="detailsDiv">
<details>
<summary><h5>&#11166; &nbsp;Robust Security Measures</h5></summary>
<p>Robust Security Measures: Safeguard your wireless networks with MAPL's robust security protocols, including encryption and threat detection, ensuring data integrity and confidentiality.</p>
</details>
<details>
<summary><h5>&#11166; &nbsp;Threat Mitigation</h5></summary>
<p>Threat Mitigation: Our wireless solutions include proactive measures to identify and mitigate potential security threats, protecting your business from cyber risks.</p>
</details>
</div>
</div>
</section>
<section>
<div>
<h4>IoT Connectivity Solutions</h4>
<div id="detailsDiv">
<details>
<summary><h5>&#11166; &nbsp;Seamless IoT Integration</h5></summary>
<p>Seamless IoT Integration: Connect and manage your IoT devices effortlessly with MAPL's IoT connectivity solutions, providing a reliable and scalable network infrastructure.</p>
</details>
<details>
<summary><h5>&#11166; &nbsp;Optimized Performance</h5></summary>
<p>Optimized Performance: Ensure optimal performance for your IoT ecosystem with our wireless solutions, supporting a wide range of applications.</p>
</details>
</div>
</div>
<img src="MAPL IMAGES/WIRELESS/IoT Connectivity Solutions - IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
</section>
<section>
<img src="MAPL IMAGES/WIRELESS/Wireless Network Management and Optimization - IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
<div>
<h4>Wireless Network Management and Optimization</h4>
<div id="detailsDiv">
<details>
<summary><h5>&#11166; &nbsp;Continuous Monitoring</h5></summary>
<p>Continuous Monitoring: MAPL offers robust network management tools, providing real-time monitoring and optimization to ensure the health and performance of your wireless network.</p>
</details>
<details>
<summary><h5>&#11166; &nbsp;Performance Tuning</h5></summary>
<p>Performance Tuning: Our experts fine-tune your wireless network for optimal performance, addressing potential bottlenecks and enhancing overall efficiency.</p>
</details>
</div>
</div>
</section>
</div>
<h3>Why Choose MAPL for Wireless Solutions?</h3>
<div id="category_about_FourDiv">
<div>
<img src="MAPL IMAGES/WIRELESS/Expertise in Connectivity - IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
<h5>Expertise in Connectivity</h5>
<p>MAPL's seasoned professionals bring a wealth of experience in crafting and managing cutting-edge wireless solutions, ensuring a reliable and future-ready network.</p>
</div>
<div>
<img src="MAPL IMAGES/WIRELESS/Customized Solutions for Your Business - IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
<h5>Customized Solutions for Your Business:</h5>
<p>We understand that each business is unique. MAPL tailors wireless solutions to meet the specific needs and challenges of your industry, ensuring a perfect fit.</p>
</div>
<div>
<img src="MAPL IMAGES/WIRELESS/Scalable and Future-Proof Designs - IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
<h5>Scalable and Future-Proof Designs</h5>
<p>As your business evolves, so should your wireless infrastructure. MAPL's designs are scalable and future-proof, accommodating growth and technological advancements.</p>
</div>
<div>
<img src="MAPL IMAGES/WIRELESS/Commitment to Security and Reliability - Scalable and Future-Proof Designs - IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
<h5>Commitment to Security and Reliability:</h5>
<p>Security is paramount. MAPL integrates robust security measures into wireless solutions, providing a secure environment for your data and business operations.</p>
</div>
</div>
</div>
<div>
<h3>FAQs</h3>
<div id="FAQ_DIV">
<div>
<details>
<summary> <span>Q. What wireless infrastructure services does MAPL provide?</span>&#10010;</summary>
<p>MAPL offers comprehensive wireless infrastructure design and deployment services, ensuring tailored solutions for optimal coverage and efficiency.</p>
</details>
<details>
<summary><span>Q. How does MAPL ensure the security of wireless networks?</span>&#10010;</summary>
<p>We prioritize security with robust measures such as encryption, threat detection, and proactive threat mitigation to safeguard your wireless networks.</p>
</details>
<details>
<summary> <span>Q. Can MAPL's wireless solutions accommodate IoT devices? </span>&#10010;</summary>
<p> Absolutely! Our wireless solutions are designed to seamlessly integrate and support IoT devices, providing a reliable and scalable network infrastructure.</p>
</details>
<details>
<summary><span>Q. What sets MAPL apart in the field of wireless connectivity?</span>&#10010;</summary>
<p>MAPL stands out with its expertise, customized solutions, commitment to security, and designs that are both scalable and future-proof.</p>
</details>
<details>
<summary> <span>Q. How can I get started with MAPL's Wireless Solutions?</span>&#10010;</summary>
<p>Getting started is easy! Contact our experts, and we'll guide you through the process of tailoring wireless solutions that fit your business needs perfectly.</p>
</details>
</div>
<img src="MAPL IMAGES/Faqimage_Mapl.png" loading="lazy" alt="" />
</div>
</div>
</div>
    </div>
)
}
export default WirelessSolutions;