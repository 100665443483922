
import { Link } from 'react-router-dom';
import './Career.css';
import React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';

function Career(){
    
useEffect(()=>{
    const slider = document.querySelector('.careertouchscroll');
let isDown = false;
let startX;
let scrollLeft;

slider.addEventListener('mousedown', (e) => {
  isDown = true;
  slider.classList.add('active');
  startX = e.pageX - slider.offsetLeft;
  scrollLeft = slider.scrollLeft;
});
slider.addEventListener('mouseleave', () => {
  isDown = false;
  slider.classList.remove('active');
});
slider.addEventListener('mouseup', () => {
  isDown = false;
  slider.classList.remove('active');
});
slider.addEventListener('mousemove', (e) => {
  if(!isDown) return;
  e.preventDefault();
  const x = e.pageX - slider.offsetLeft;
  const walk = (x - startX) * 3;
  slider.scrollLeft = scrollLeft - walk;
  console.log(walk);
});
});
    
    let navifind = useNavigate(); 
    function Findjobs(){
        var findjobsinput = document.getElementById('findjobid').value.toLowerCase();
        if(findjobsinput===""){
          navifind('/jobs');
        }else{
          navifind('/'+findjobsinput);
        }
      
        }
    return(
<div>
<div id="CareerPage">
<div id="careerFirstDiv">
<h1>
Create Impact !
</h1>
<div id="careerSearchBox">
<datalist id="Searchbyjobtitledatalist">
<option value="Project Manager Job"/>
<option value="Business Development Job"/>
<option value="Technical consultant Job"/>
<option value="SupplyChainManagementJob"/>
<option value="Account Finance Job"/>


</datalist>
<span><i class="material-icons">&#xe8b6;</i></span>
<input type="search" placeholder='Search by job title' list="Searchbyjobtitledatalist" id="findjobid"/>
<button onClick={Findjobs}>Find jobs</button>
</div>
</div>
<div id="CareerNavDiv">
<div>
<a href="#h3Talent">Talent Management &nbsp; &nbsp;|</a>
<a href="#h3lifeatmapl">Life at MAPL &nbsp; &nbsp;|</a>
<a href="#h3_Work-Life">Work-Life Balance &nbsp; &nbsp;|</a>
<Link to="/Internship">Internships&nbsp; &nbsp;</Link>
</div>
</div>
<div id="Careerbigdiv">
<h3 >Our Teams</h3>

<div id="careersecondDiv" class="careertouchscroll">
<div id="ourTearmdiv">
<img src="MAPL IMAGES/Life at MAPL/Sales & Bidding.png"loading="lazy" alt="" />
<h4>Sales & Bidding</h4>
<p>Comprehend customer requirements, formulate tailored solutions, and create RFP/RFI proposals. Coordinate OEMs, vendors, distributors, and clients in the bidding process.</p>
</div>
<div id="ourTearmdiv">
<img src="MAPL IMAGES/Life at MAPL/Project Management.png"loading="lazy" alt="" />
<h4>Project Management</h4>
<p>Understanding customer needs, the project team contributes to objectives and deliverables. Planning activities and executing high-quality tasks ensure project success and meeting expectations.</p>
</div>
<div id="ourTearmdiv">
<img src="MAPL IMAGES/Life at MAPL/Customer Support.png"loading="lazy" alt="" />
<h4>Customer Support</h4>
<p>Customer support ensures seamless project operations within warranty and AMC phases. It involves managing SLAs, coordinating nationwide teams of engineers, and ensuring efficient service delivery for ultimate client satisfaction.</p>
</div>

<div id="ourTearmdiv">
<img src="MAPL IMAGES/Life at MAPL/Accounts & Finance.png"loading="lazy" alt="" />
<h4>Accounts & Finance</h4>
<p>Accounts and finance encompass pivotal roles like managing receivables, payables, payroll, and financial reporting. It handles project financing, P&L, budgeting, and ensuring financial control and stability within organisations. </p>
</div>
<div id="ourTearmdiv">
<img src="MAPL IMAGES/Life at MAPL/Contracts & Compliance.png"loading="lazy" alt="" />
<h4>Contracts & Compliance</h4>
<p>Contracts & Compliance oversees the lifecycle of legally binding agreements, handling administrative and strategic tasks from initiation to execution. Different stages entail varying levels of organisational and strategic involvement.</p>
</div>
<div id="ourTearmdiv">
<img src="MAPL IMAGES/Life at MAPL/Human Resource.png"loading="lazy" alt="" />
<h4>Human Resource </h4>
<p>Human Resources strategize for the company's future and workforce, influencing functions like talent acquisition, performance management, and succession planning. Their pivotal role shapes organisational development and growth. </p>
</div>
<div id="ourTearmdiv">
<img src="MAPL IMAGES/Life at MAPL/Digital Marketing.png"loading="lazy" alt="" />
<h4>Digital Marketing</h4>
<p>Digital marketers orchestrate campaigns to promote brands and products, which is pivotal in boosting brand visibility, driving traffic, and acquiring leads/customers. Their efforts shape brand recognition and customer acquisition strategies.</p>
</div>
<div id="ourTearmdiv">
<img src="MAPL IMAGES/Life at MAPL/Supply Chain Management.png"loading="lazy" alt="" />
<h4>Supply Chain Management</h4>
<p>Supply Chain Management analyses project material needs and liaises with OEMs, vendors, and dealers for optimal procurement at negotiated rates. It ensures timely delivery with favourable credit terms while minimising risks through backup options. </p>
</div>
</div>


</div>

<div id="careerlife_div">
    <span class="ServiceAboutleft_span" id="h3lifeatmapl">
    <div><img src="MAPL IMAGES/Life at MAPL/Life at MAPLimage.jpg" loading="lazy" alt="" /></div>
    <section class="ServiceAboutright_section">
    <h4>Life at MAPL </h4>
    <p> Experience life at MAPL, where our vibrant culture and unwavering dedication to excellence redefine the essence of professional thriving. Step into the extraordinary world that unfolds within our walls. </p>
    </section>
    </span>
    </div>
    <div id="careerlife_div">
    <span class="ServiceAboutright_span" id="h3_Work-Life">
    <section class="ServiceAboutleft_section">
    <h4>Work-Life Balance</h4>
    <p>At MAPL, we understand that true success goes beyond professional accomplishments and includes a harmonious integration of work and personal life. </p>
    </section>
    <div><img src="MAPL IMAGES/Life at MAPL/Work-Life Balance - mapl.JPG" loading="lazy" alt="" /></div>
    </span>
    </div>
    <div id="careerlife_div">
    <span class="ServiceAboutleft_span" id="h3Talent" >
    <div><img src="MAPL IMAGES/Life at MAPL/Talent Management - mapl.JPG" loading="lazy" alt="" /></div>
    <section class="ServiceAboutright_section">
    <h4>Talent Management</h4>
    <p>At MAPL, our commitment to excellence extends beyond our solutions and services—it begins with our people. Our approach to talent management stands as a testament to our unwavering dedication to nurturing and empowering our workforce. </p>
    </section>
    </span>
    </div>
    <div id="careerlife_div">
        <span class="ServiceAboutright_span" id="h3_Work-Life">
        <section class="ServiceAboutleft_section">
        <h4>Green Management</h4>
        <p>At MAPL, we believe in creating a workplace that drives innovation and champions environmental responsibility. Our commitment to sustainability extends beyond mere words; it's ingrained in our culture and practices</p>
        <p>MAPL is dedicated to reducing its environmental footprint. From energy-efficient office spaces to eco-conscious waste management systems, we integrate sustainable practices into our daily operations. We prioritize!</p>
        </section>
        <div><img src="MAPL IMAGES/Life at MAPL/Green Management - mapl.JPG" loading="lazy" alt="" /></div>
        </span>
        </div>




<div id="careerReadyto_div">
<h2>Ready to get started?</h2>
<Link to="/jobs"> <button>Explore open roles &rarr;</button></Link>
</div>
</div>
</div>


    )
}
export default Career;