
import { Link } from 'react-router-dom';
import React from 'react'
import './sitemap.css';
function Sitemap(){
   
    return(

        <div>
<div id="sitemap_page">
<div id="sitemapHome_div">
<h1>Site Map</h1>
</div>
<div id="sitemapNAV_div">
<a href="#siteCompany">Company </a>
<a href="#siteSolutions"> Solutions</a>
<a href="#siteService">Services</a>
<a href="#siteCase">Case Studies </a>
<a href="#siteCareer">Careers</a>
<a href="#siteTerms">Terms of Service</a>
</div>
<br/>
<br/>
<div class="sitemapslinks_div">
<h4 id="siteSolutions">Solutions</h4>
<br/>
<div class="sitemapsSolution_div">
 <Link to="/SmartCity Solution">Smart City Solutions </Link>
 <Link to="/data center solutions">Data Centre Solutions </Link>
<Link to="/Network Security Solution">Network and Security Solutions</Link> 
 <Link to="/WirelessSolutions">Wireless Solutions</Link>
 <Link to="/CCTV Surveillance Solution">CCTV Surveillance</Link>
 <Link to="/Unified Communication">Unified Communication</Link>
 <Link to="/Rugged Military Grade">Rugged and Mil Grade Solutions</Link>
<Link to="/telecommunication solution">Telecom Products and RF Solutions </Link>
 <Link to="/CloudSolution">Cloud Solutions</Link>
 <Link to="/Smart ClassRoom solution">Smart Class Room solution</Link>

<Link to="/Mobile Video Conferencing">Mobile Video Conferencing </Link>
 <Link to="/security surveillance solution">Security and Surveillance Solutions</Link>
 <Link to="/AVTS AVLS Solution">AVTS/AVLS Solutions</Link>
 <Link to="/Mobile Surveillance System">Mobile Surveillance Solutions</Link>
 <Link to="/Network Element Systems solutiion">Elevating Connectivity Solutions</Link>
 <Link to="/SaaS Development">SaaS Development Solutions</Link>
 <Link to="/application performance system">Application Performance System Solutions</Link>
 <Link to="/Custom Software Solution">Custom Software Development Solutions</Link>
 <Link to="/intelligent infrastructur SmartCity">Intelligent Infrastructure Management</Link>
 <Link to="/high end security solution">High-End Security Surveillance</Link>
 <Link to="/AI and Machine Learning Solution">AI and Machine Learning Solutions</Link>
 <Link to="/LawfulInterceptMonitoring">Lawful intercept monitoring</Link>
 <Link to="/Network Management System">Network Management System Solutions</Link>
 <Link to="/Intelligent Traffic Management Solution">Intelligent Traffic Management </Link>
 <Link to="/Managed Services solution">Managed Services Solutions</Link>
 <Link to="/FacialRecognitionTechnology"> Facial Recognition Solutions</Link>
 <Link to="/Power supply solution"> Power Supply Solutions</Link>
</div>
<h4 id="siteService">Service</h4>
<div class="sitemapsService_div">


<Link to="/enterprise management">Enterprise Management</Link>
    <Link to="/intelligent service">Intelligent Industry</Link>
    <Link to="/Data AI Management Services">Data and AI Management</Link>
    <Link to="/Business Processes Services">Business Processes</Link>
<Link to="/Cyber Security">Cyber Security</Link>
    <Link to="/Cloud Service">Cloud Service</Link>
    <Link to="/Sustainability Service">Sustainability</Link>
    <Link to="/Infrastructure Services">Infrastructure</Link>
</div>
<h4 id="siteCompany">Company</h4>
<div class="sitemapsService_div">
<Link to="/about">About</Link>
<Link to="/about/#missionheading">Values and Mission</Link>
<Link to="/career">Career</Link>
<Link to="/csr">CSR Activities</Link>
<Link to="/contact">Contact</Link>
</div>
<h4 id="siteCase">Case Studies</h4>
<div class="sitemapsService_div">
<a href="MAPL IMAGES/CASE STUDIES/DELHI POLICE/DELHI POLICE - Design, Supply, Installation, Commissioning and Maintenance of CCTV Cameras in delhi.pdf">Delhi police</a>
<a href="MAPL IMAGES/CASE STUDIES/DRDO/DRDO  Transformative Partnership MAPL revolutionised Defense Research with Innovative Storage Solutions.pdf">DRDO</a>
<a href="MAPL IMAGES/CASE STUDIES/DTC/Guardians in Transit  MAPL_s Implemented Advanced Surveillance Solutions in Delhi Transport C.pdf">Delhi E-Mobility</a>
<a href="MAPL IMAGES/CASE STUDIES/JAP-IT/MAPL_s Revolutionary Impact on Jharkhand_s Legal System with Advanced Video_20240107_175509_0000.pdf">Transforming State </a>
<a href="MAPL IMAGES/CASE STUDIES/JEPC/Transforming Jharkhands Educational Landscape MAPL_s Visionary Impact with_20240107_175252_0000 (1).pdf">Jharkhand Eduction Project </a>
<a href="MAPL IMAGES/CASE STUDIES/LIMS/Establishment of Lawful Interception and Monitoring System (LIMS) for providing International.pdf">Electronic Surveillance</a>
<a href="MAPL IMAGES/CASE STUDIES/GOVT OF BIHAR - OUTDOOR MOBILE VIDEO CONFERENCING/Supply And Installation Of Outdoor Mobile Video Conferencing Across The State Of Bihar (1).pdf">Govt of Bihar</a>
<a href="MAPL IMAGES/CASE STUDIES/GOVT OF UTTARAKHAND/GOVT OF UTTARAKHAND-  ENHANCED  VIDEO CONFERENCING  EXPERIENCE FOR UTTARAKHAND.pdf">Elevating Defense Infrastructure</a>
<a href="MAPL IMAGES/CASE STUDIES/BSF - PARA MILITARY/BSF - INDIAN PARA-MILITARY FORCE (SECURED WIDE AREA NETWORK)_20240107_180321_0000.pdf">BSF </a>
<a href="MAPL IMAGES/CASE STUDIES/SSB - PARA MILITARY/SSB - INDIAN PARA-MILITARY FORCE (SECURED WIDE AREA NETWORK)_20240107_180017_0000 (3).pdf">SSB</a>
<a href="MAPL IMAGES/CASE STUDIES/BSF - PARA MILITARY/BSF - GIS Geospatial Revolution MAPL established GIS Labs for Enhanced Border Security.pdf">BSF GIS </a>
<a href="MAPL IMAGES/CASE STUDIES/Bangalore Municipal Corporation and Transportation Authorities/SECURED MOBILE COMMUNICATION WITH  ASSOCIATED CENTRALISED DEVICE MANAGEMENT_20240107_212025_0000.pdf">BEMP & BMTC</a>
<a href="MAPL IMAGES/CASE STUDIES/Intelligent Traffic Management Systems (ITMS)/TRAFFIC MANAGEMENT The Dawn of Efficient Urban Mobility Unleashing MAPL_s  Intelligent Traffi.pdf">MCGM & Bengaluru Traffic Police</a>
<a href="MAPL IMAGES/CASE STUDIES/BEL - IACCS/IACCS Elevating Defense Infrastructure A Pinnacle in Excellence with MAPL_s IACCS Data Wall Solution (1).pdf">BEL</a>
<a href="MAPL IMAGES/CASE STUDIES/WAC/Command HQ Project - Advancing and Strengthening Security Infrastructure, D_20240107_180414_0000.pdf">Central Command HQ </a>
<a href="MAPL IMAGES/CASE STUDIES/DEFENCE - WAR ROOMS/Revolutionizing Defence Communication  MAPL_s Cutting-Edge War Room Solutio_20240107_175713_0000.pdf">Defence</a>
<a href="MAPL IMAGES/CASE STUDIES/Digital Enterprise Management/Enhanced Efficiency Revolutionizing Digital Enterprise Management MAPL_s Strategic Solutions .pdf">Corporate Efficiency </a>
<a href="MAPL IMAGES/CASE STUDIES/SPIC - Emergency Communication/SPIC - Revolutionizing Emergency Communication MAPL_s Swift Response, Fortified Security, and Versatile Solutions for SPIC.pdf">SPIC</a>
</div>
<h4 id="siteCareer">Career</h4>
<div class="sitemapsService_div">
<Link to="/career">Career</Link>
<Link to="/Internship">Internships</Link>
<Link to="/jobs">Job Apply</Link>
<Link to="/Project Manager Job">Project Manager</Link>
<Link to="/Business Development Job">Business Development</Link>
<Link to="/Technical consultant Job">Technical consultant</Link>
<Link to="/SupplyChainManagementJob">Supply Chain Management</Link>
<Link to="/Account Finance Job">Accounts and Finance</Link>
<Link to="/NewBusinessDevelopmentJob">Member Business Development</Link>
<Link to="/ContractComplianceJob">Contract and Compliance</Link>
<Link to="/NewAccountsFinancejob">Member Accounts and Finance</Link>
</div>
<h4 id="siteTerms">Terms of Service</h4>
<div class="sitemapsService_div">
<Link to="/terms">Terms of Service</Link>
<Link to="/privacy">Privacy</Link>
<Link to="/legal">Legal</Link>

</div>
<br/>
</div>
</div>

        </div>
    )
}
export default Sitemap;