import { Link } from "react-router-dom";
import "./jobpage.css";
import React from 'react';
function Jobs(){
    return(
        <div>
            <div id="jobpage">
    <div id="jobhomediv">
        <h2>JOBS</h2>
    </div>

<div id="JopTitle">
<div>
<section>
<h4>Project Manager</h4>
</section>
<Link to="/Project Manager Job"><button>View Details </button></Link>
</div>
<div>
<section>
<h4>Business Development</h4>
</section>
<Link to="/Business Development Job"><button>View Details </button></Link>
</div>
<div>
<section>
<h4>Technical consultant</h4>
</section>
<Link to="/Technical consultant Job"><button>View Details </button></Link>
</div>
<div>
<section>
<h4>Supply Chain Management</h4>
</section>
<Link to="/SupplyChainManagementJob"><button>View Details </button></Link>
</div>
<div>
<section>
<h4>Accounts and Finance</h4>
</section>
<Link to="/Account Finance Job"><button>View Details </button></Link>
</div>
<div>
<section>
<h4>Member Accounts and Finance</h4>
</section>
<Link to="/NewAccountsFinancejob"><button>View Details </button></Link>
</div>
<div>
<section>
<h4>Member Business Development</h4>
</section>
<Link to="/NewBusinessDevelopmentJob"><button>View Details </button></Link>
</div>
<div>
<section>
<h4>Member Contract Compliance </h4>
</section>
<Link to="/ContractComplianceJob"><button>View Details </button></Link>
</div>

</div>
<div id="getresumediv">
<div>
<p>Ready to join our dynamic team? Share your resume; your skills and passion could be the perfect fit.</p>
</div>
<div>
<a href="mailto:hr@maplworld.com?subject=Job Application with Resume"><label id="labelgetinput">Upload Resume</label></a>
</div>
</div>
</div>
        </div>
    )
}
export default Jobs;