import { Routes,Route} from 'react-router-dom';
import './App.css';
import React,{ useEffect} from 'react';
import About from './component/About';
import Home from './component/Home';
import Csr from './component/CSR';
import Career from './component/Career';
import Terms from './component/Terms';
import Sitemap from './component/Sitemap';
import Privacy from './component/Privacy';
import Internship from './component/Internship';
import Faqs from './component/FAQs';
import Jobs from './component/jobs page';
import ProjectManagerJob from './component/ProjectManagerJob';
import BusinessDevelopmentJob from './component/BusinessDevelopmentJob';
import TechnicalconsultantJob from './component/TechnicalconsultantJob';
import AccountsFinanceJob from './component/AccountsFinanceJob';
import NewAccountsFinance from './component/NewAccounts and Finance';
import SupplyChainManagementJob from './component/Supply Chain Management';
import NewBusinessDevelopmentJob from './component/New_BusinessDevelopmentJob';
import ApplyJobForm from './component/Apply job form';
import ContractComplianceJob from './component/Contract and Compliance job';
import SmartCitySolutions from './component/Smart City Solutions';
import DataCenterSolutions from './component/Data Center Solutions';
import TelecommunicationSolution from './component/Telecommunication Solution';
import UnifiedCommunication from './component/Unified Communication';
import SecuritySurveillanceSolution from './component/Security and Surveillance Solutions';
import ApplicationPerformanceSystem from './component/Application Performance System';
import MobileVideoConferencingSolution from './component/Mobile Video Conferencing Solutions';
import SmartClassRoom from './component/Smartclassroom';
import HighEndSecuritySolution from './component/High-End Security Solution';
import IntelligentInfrastructurSmartCitySolutions from './component/Intelligent Infrastructure solution';
import AIandMachineLearningSolution from './component/AI and Machine Learning Solution';
import LawfulInterceptMonitoring from './component/Lawful Intercept Monitoring';
import CustomSoftwareSolution from './component/Custom Software Solutions';
import WirelessSolutions from './component/Wireless Solutions';
import CCTVSurveillanceSolution from './component/CCTV Surveillance Solution';
import NetworkSecuritySolution from './component/Network Security Solution';
import RuggedMilitaryGradeSolutions from './component/Rugged and Military-Grade Solution';
import AVTSAVLSSolution from './component/AVLS Solution';
import MobileSurveillanceSystem from './component/Mobile Surveillance System';
import SaaSDevelopment from './component/SaaS Development';
import NetworkManagementSystem from './component/Network Management System';
import IntelligentTrafficManagementSolution from './component/Intelligent Traffic Management Solution';
import ManagedServicessolution from './component/Managed Services solution';
import FacialRecognitionTechnology from './component/Facial Recognition Technology';
import CloudSolution from './component/Cloud Solution';
import Powersupplysolution from './component/Power supply solution';
import NetworkElementSystemssolutiion from './component/Network Element System solution';
import EnterpriseManagementService from './component/ENTERPRISE_MANAGEMENT_SERVICE';
import CyberSecurityService from './component/Cyber_security_service';
import CloudService from './component/Cloud_Service';
import IntelligentServices from './component/Intelligent Industry Service';
import DataAIManagementServices from './component/Data and AI Management Services';
import SustainabilityService from './component/Sustainability Service';
import InfrastructureServices from './component/Infrastructure Services';
import BusinessProcessesServices from './component/Business Processes Services';
import Page404 from './component/Errorpage';
import Header from './component/Header';
import Mobileheader from './component/Mobileheader';
import Footer from './component/Footer';
import Contact from './component/Contact';



function App() {


  useEffect(()=>{

 
    document.getElementById('navclickbuttons').addEventListener('mouseover',function(){
      var windowHeightnav= window.innerHeight;
      var windowWidthnav= window.innerWidth;
      if(windowWidthnav>windowHeightnav){
      document.getElementById('navigation_div').style.height="fit-content";
      }else{
      document.getElementById('navigation_div').style.height="fit-content";
      }
    });
    document.getElementById('navclickbuttons2').addEventListener('mouseover',function(){
      var windowHeightnav= window.innerHeight;
      var windowWidthnav= window.innerWidth;
      if(windowWidthnav>windowHeightnav){
      document.getElementById('navigation_div').style.height="fit-content";
      }else{
      document.getElementById('navigation_div').style.height="fit-content";
      }
    });
    document.getElementById('navclickbuttons3').addEventListener('mouseover',function(){
      var windowHeightnav= window.innerHeight;
      var windowWidthnav= window.innerWidth;
      if(windowWidthnav>windowHeightnav){
      document.getElementById('navigation_div').style.height="fit-content";
      }else{
      document.getElementById('navigation_div').style.height="fit-content";
      }
    });
    document.getElementById('navclickbuttons4').addEventListener('mouseover',function(){
      var windowHeightnav= window.innerHeight;
      var windowWidthnav= window.innerWidth;
      if(windowWidthnav>windowHeightnav){
      document.getElementById('navigation_div').style.height="fit-content";
      }else{
      document.getElementById('navigation_div').style.height="fit-content";
      }
    });
   
  });

  return (
      <div>
   
     
       <Header/>
       <Mobileheader/>
       
  <Routes>
  <Route path={"/"} element={<Home/>}/>
    <Route path="/About" element={<About/>}/>
    <Route path={"/Career"} element={<Career/>}/>
    <Route path="Csr" element={<Csr/>}/>
    <Route path={"/Contact"} element={<Contact/>}/>
    <Route path={"/Terms"} element={<Terms/>}/>
    <Route path={"/Sitemap"} element={<Sitemap/>}/>
    <Route path={"/Privacy"} element={<Privacy/>}/>
    <Route path={"/Legal"} element={<Privacy/>}/>
    <Route path={"/Internship"} element={<Internship/>}/>
    <Route path={"/Faq"} element={<Faqs/>}/>
    <Route path={"/Jobs"} element={<Jobs/>}/>
    <Route path={"/Project Manager Job"} element={<ProjectManagerJob/>}/>
<Route path="/Business Development Job" element={<BusinessDevelopmentJob/>}/>
 <Route path="/Technical consultant Job" element={<TechnicalconsultantJob/>}/>
<Route path="/Account Finance Job" element={<AccountsFinanceJob/>}/>
<Route path="/NewAccountsFinancejob" element={<NewAccountsFinance/>}/>
<Route path="/SupplyChainManagementJob" element={<SupplyChainManagementJob/>}/>
<Route path="/NewBusinessDevelopmentJob" element={<NewBusinessDevelopmentJob/>}/>
<Route path="/applyJobForm" element={<ApplyJobForm/>}/>
<Route path="/ContractComplianceJob" element={<ContractComplianceJob/>}/>
<Route path="/SmartCity Solution" element={<SmartCitySolutions/>}/>
<Route path="/Data center solutions" element={<DataCenterSolutions/>}/>
<Route path="/Telecommunication solution" element={<TelecommunicationSolution/>}/>
<Route path="/Unified Communication" element={<UnifiedCommunication/>}/>
<Route path="/Security surveillance solution" element={<SecuritySurveillanceSolution/>}/>
<Route path="/Application performance system" element={<ApplicationPerformanceSystem/>}/>
<Route path="/Mobile Video Conferencing" element={<MobileVideoConferencingSolution/>}/>
<Route path="/Smart ClassRoom solution" element={<SmartClassRoom/>}/>
<Route path="/High end security solution" element={<HighEndSecuritySolution/>}/>
<Route path="/Intelligent infrastructur SmartCity" element={<IntelligentInfrastructurSmartCitySolutions/>}/>
<Route path="/AI and Machine Learning Solution" element={<AIandMachineLearningSolution/>}/>
<Route path="/LawfulInterceptMonitoring" element={<LawfulInterceptMonitoring/>}/>
<Route path="/Custom Software Solution" element={<CustomSoftwareSolution/>}/>
<Route path="/WirelessSolutions" element={<WirelessSolutions/>}/>
<Route path="/CCTV Surveillance Solution" element={<CCTVSurveillanceSolution/>}/>
<Route path="/Network Security Solution" element={<NetworkSecuritySolution/>}/>
<Route path="/Rugged Military Grade" element={<RuggedMilitaryGradeSolutions/>}/>
<Route path="/AVTS AVLS Solution" element={<AVTSAVLSSolution/>}/>
<Route path="/Mobile Surveillance System" element={<MobileSurveillanceSystem/>}/>
<Route path="/SaaS Development" element={<SaaSDevelopment/>}/>
<Route path="/Network Management System" element={<NetworkManagementSystem/>}/>
<Route path="/Intelligent Traffic Management Solution" element={<IntelligentTrafficManagementSolution/>}/>
   <Route path="/Managed Services solution" element={<ManagedServicessolution/>}/>
   <Route path="/FacialRecognitionTechnology" element={<FacialRecognitionTechnology/>}/>
   <Route path="/CloudSolution" element={<CloudSolution/>}/>
   <Route path="/Power supply solution" element={<Powersupplysolution/>}/>
   <Route path="/Network Element Systems solutiion" element={<NetworkElementSystemssolutiion/>}/>
    <Route path="/enterprise management" element={<EnterpriseManagementService/>}/>
    <Route path="/Cyber Security" element={<CyberSecurityService/>}/>
      <Route path="/Cloud Service" element={<CloudService/>}/>
      <Route path="/Intelligent service" element={<IntelligentServices/>}/>
      <Route path="/Data AI Management Services" element={<DataAIManagementServices/>}/>
      <Route path="/Sustainability Service" element={<SustainabilityService/>}/>
      <Route path="/Infrastructure Services" element={<InfrastructureServices/>}/>
      <Route path="/Business Processes Services" element={<BusinessProcessesServices/>}/>
      <Route path="/*" element={<Page404/>}/>
  </Routes>
      <Footer/>
 

       </div>
  
  
  );
}

export default App;
