import { Link } from 'react-router-dom';
import './smartcitysolution.css';
import React from 'react';
function SmartCitySolutions(){
return(
    <div>
        <div id="category_page">
<div id="category_home_div">
<div class="category_home_Detail_div">
<h1>Smart City Solutions by MAPL</h1>
<p><b>Connecting Cities, Empowering Lives</b>
<br/>
Modern cities face unprecedented challenges with 55% of the global population residing in metropolitan regions, a number expected to surge by 13% by 2050.
In this dynamic urban landscape, the demand for improved services, public safety, congestion alleviation, and environmental sustainability is ever-growing. Traditional approaches need to be revised, prompting cities to turn to technological innovations.
</p>
<Link to="/contact"><button>Contact us</button></Link>
</div>
<img src="MAPL IMAGES/Smart City/Connecting Cities, Empowering Lives.png"loading="lazy" alt="" />
</div>
<div id="category_scrollDiv">
<h3 style={{color:"white"}} >Our Technology Partners</h3>
<div class="category_scrollImageDiv">
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/DELL - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/HP - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/POLY LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/TEJAS LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/BARCO LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
</div>
</div>
<div id="category_about_div">
<h3>MAPL's Smart City Solutions</h3>
<div id="category_about_TwoDiv">
<section>
<img src="MAPL IMAGES/Smart City/Leading the Future of Urban Living.jpg"loading="lazy" alt="" />
<div id="divcolumns">
<h4>Leading the Future of Urban Living</h4>
<p>In the rapidly evolving landscape of urbanization, MAPL is at the forefront of redefining smart city solutions. Our commitment goes beyond integrating technology; we envision, architect, and implement solutions that cater to the core needs of communities. From enhancing public safety to optimizing city services and promoting sustainability, MAPL's smart city solutions are a testament to our dedication to urban well-being.</p>
</div>
</section>
<section>
<div id="divcolumns">
<h4>5G Connectivity: Fueling the Smart Revolution</h4>
<p>As we embrace the era of 5G connectivity, the possibilities for smart cities become limitless. With the advent of ultra-fast and low-latency communication, MAPL's smart city solutions are geared to leverage this technology, ensuring that cities are not just connected but are at the forefront of technological innovation.</p>
</div>
<img src="MAPL IMAGES/Smart City/5G Connectivity_ Fueling the Smart Revolution.png"loading="lazy" alt="" />
</section>
<section>
<img src="MAPL IMAGES/Smart City/Revolutionizing Urban Mobility.png"loading="lazy" alt="" />
<div id="divcolumns">
<h4>Revolutionizing Urban Mobility</h4>
<p>In the quest for efficient and sustainable urban mobility, MAPL's smart city solutions extend beyond traffic optimization. We redefine how people and goods move within cities, offering real-time alerts, optimized routes, and smart parking solutions. The result is a seamless flow of urban life.</p>
</div>
</section>
<section>
<div id="divcolumns">
<h4>AI-Powered Insights: Shaping Future Cities</h4>
<p>Artificial Intelligence (AI) is a game-changer in shaping the future of cities. By integrating AI-driven analytics into our solutions, we empower city administrators to extract meaningful insights from vast datasets. This not only optimizes city operations but also aids in making informed decisions for the benefit of residents.</p>
</div>
<img src="MAPL IMAGES/Smart City/AI-Powered Insights_ Shaping Future Cities.png"loading="lazy" alt="" />
</section>
<section>
<img src="MAPL IMAGES/Smart City/Elevating Citizen Engagement.png"loading="lazy" alt="" />
<div id="divcolumns">
<h4>Elevating Citizen Engagement</h4>
<p>At the heart of MAPL's smart city solutions is the idea of citizen-centric services. Our smart buttons for citizen service requests provide a direct line of communication between residents and city authorities. This not only enhances the customer experience but also contributes to efficient service delivery.</p>
</div>
</section>
<section>
<div id="divcolumns">
<h4>Smart Cities for All Sizes: Scalable and Adaptable</h4>
<p>Whether you're a sprawling metropolis or a smaller urban centre, MAPL's smart city solutions are designed to be scalable and adaptable. We understand that each city has unique requirements, and our solutions can be tailored to meet the specific needs of any community.</p>
</div>
<img src="MAPL IMAGES/Smart City/Smart Cities for All Sizes_ Scalable and Adaptable.png"loading="lazy" alt="" />
</section>
<section>
<img src="MAPL IMAGES/Smart City/Sustainable Urban Living_ An Environmental Imperative.png"loading="lazy" alt="" />
<div id="divcolumns">
<h4>Sustainable Urban Living: An Environmental Imperative</h4>
<p>As environmental concerns take centre stage, MAPL's commitment to sustainability is unwavering. Our environmental monitoring solutions empower cities to understand, mitigate, and adapt to changing environmental conditions. This contributes to reduced downtime, lower maintenance costs, and a greener urban footprint.</p>
</div>
</section>
</div>
<h3>Advantages of Embracing Smart Cities</h3>
<div id="category_about_FourDiv">
<div>
<h5>Enhanced Public Safety</h5>
<p>Integrated cameras and sensors identify accidents, notify responders, and transmit real-time video, ensuring continuous awareness.</p>
</div>
<div>
<h5>Efficient City Services</h5>
<p>Automation optimizes services, from smart waste bins to early detection of issues like potholes, enabling swift responses.</p>
</div>
<div>
<h5>Enhanced Sustainability and Resilience</h5>
<p>Technology aids in understanding surroundings, mitigating environmental impact, and fostering sustainable futures.</p>
</div>
<div>
<h5>Advanced Public Health Resources</h5>
<p>Smart city solutions, including face mask detection and sanitation protocols, establish intelligent and secure environments.</p>
</div>
<div>
<h5>Reduced Congestion and Enhanced Urban Mobility</h5>
<p>Optimized traffic signals, real-time alerts, and traffic rerouting ensure the smooth flow of people and goods.</p>
</div>
</div>
<div id="category_about_OneDiv">
<img src="MAPL IMAGES/Smart City/Key Features of MAPL's Comprehensive IoT Solutions.png" loading="lazy" alt="" />
<div>
<h5>
Key Features of MAPL's Comprehensive IoT Solutions</h5>
<ul>
<li>Dedicated Low-Power Wide Area Network (LPWAN) for extended device range and low power consumption</li>
<li>Highly scalable plug-and-play solutions with rapid deployment capabilities</li>
<li>Network and application-level security for data protection</li>
<li>Cost-effective solutions ensure a swift return on investment.</li>
</ul>
</div>
</div>
<div id="category_about_OneDiv2">
<div id="category_about_box" >
<div>
<img src="MAPL IMAGES/Smart City/Empowering Your Community with Connectivity and Safety.png" loading="lazy" alt="" />
</div>
<section>
<h4> Empowering Your Community with Connectivity and Safety</h4>
<br/>
<small> MAPL's mission is to enhance urban well-being and optimize city operations. Beyond technology, our focus is on addressing fundamental desires and necessities. Through collaboration, we revolutionize service delivery, empowering communities and making cities smarter.
</small>
</section>
</div>
<div id="category_about_box" >
<div>
<img src="MAPL IMAGES/Smart City/Connecting Cities, Empowering Lives.png" loading="lazy" alt="" />
</div>
<section>
<h4> What Defines Smart City Solutions?
</h4>
<br/>
<small> Smart city solutions span from energy-efficient lighting to traffic analysis and monitoring, leveraging cutting-edge technologies. MAPL contributes to a heightened understanding of communities, resulting in improved data, expanded insights, and more interconnected infrastructure.
</small>
</section>
</div>
<div id="category_about_box" >
<div>
<img src="MAPL IMAGES/Smart City/How Do Smart City Solutions Operate.png" loading="lazy" alt="" />
</div>
<section>
<h4> How Do Smart City Solutions Operate?
</h4>
<br/>
<small> Connected Smart Cities and Community solutions scrutinize data through wireless networking technologies. Sensors and devices gather data for real-time interpretation, processing, and analysis. MAPL's smart city solutions provide tools to disseminate findings, empower decision-makers, and enhance services.
</small>
</section>
</div>
</div>
</div>
<br/>
<p id="ctalandingpage"> Embark on a transformative journey where technology and purpose converge. In this dynamic space, innovation knows no bounds, lighting the way to a future of limitless potential.
<br/>
<br/> <b>Join us in this exciting growth journey, as together we shape a boundless and inspiring future.</b></p>
<div>
<br/>
<h3>FAQs</h3>
<div id="FAQ_DIV">
<div>
<details>
<summary> <span>Q. What makes MAPL's smart city solutions stand out?</span>&#10010;</summary>
<p>MAPL stands out due to its comprehensive approach, cutting-edge technology, and commitment to community well-being. Our solutions address diverse urban challenges with a focus on sustainability and efficiency.</p>
</details>
<details>
<summary> <span>Q. How does MAPL contribute to public safety in smart cities?</span>&#10010;</summary>
<p>MAPL's integrated cameras and sensors enhance public safety by identifying accidents, notifying responders, and providing real-time video from the field to control centers, ensuring continuous awareness.</p>
</details>
<details>
<summary> <span>Q. Can MAPL's solutions adapt to different city sizes and requirements?</span>&#10010;</summary>
<p>Absolutely. MAPL's smart city solutions are scalable and adaptable, catering to the unique needs of cities, whether large metropolises or smaller urban centers.</p>
</details>
<details>
<summary> <span>Q. How does MAPL ensure data security in its IoT solutions? </span>&#10010;</summary>
<p>Data security is a top priority for MAPL. Our IoT solutions include network and application-level security measures to ensure data protection across all smart city solutions.</p>
</details>
<details>
<summary> <span>Q. How does MAPL support cities after implementing smart solutions?</span>&#10010;</summary>
<p>Our commitment extends beyond implementation. MAPL provides dedicated support to cities, ensuring the continuous and efficient operation of smart city solutions. Our expert team is available 24/7 to address any issues and concerns.</p>
</details>
</div>
<img src="MAPL IMAGES/Faqimage_Mapl.png" loading="lazy" alt="" />
</div>
</div>
</div>
    </div>
)
}
export default SmartCitySolutions;
