import { Link } from "react-router-dom";
import React from 'react';
function TechnicalconsultantJob(){
    return(
        <div>
            <div id="jobpage">
    <div id="jobhomediv">
        <h2>Technical consultant</h2>
    </div>
<div id="Jopdescription">
<section>
<div>
<h2>Technical consultant</h2>
<p> <i class="material-icons" style={{color:"#00437d",display:"inline"}}> &#xe568;</i>Okhla
&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa" style={{color:"#00437d",display:"inline",fontSize:"22px"}}> &#xf017;</i> Full Time
</p>
</div>
<div>
<h4>Job Description</h4>
<br/>
<b><p>We are looking for high performing Solution Manager/ Presales professional with proven successful experience in IT /Networking, Security/Surveillance/Telecom industry and Handling Enterprise, Banking , Government and Defense Clients.</p></b>
<br/>
<ul>
<li>Understand the requirement of the customer. Solution designing, and proposal writing while responding to RFP and RFI and conducting POC's.</li>
<li>OEM Coordination, Technical Qualification preparation, Solution Design and Optimization. Preparation of Tender summary, Risk analysis, Technical Bid, Commercial bid </li>
<li>Maintain relationship with OEMs, Vendors, Distributors.</li>
<li>To analyze competition prior to submitting comprehensive. comparing and evaluating various proposals on sound grounds to select the best one.</li>
<li>Meet customers and OEMs for specific requirement.</li>
<li>Solution Designing, Solution optimization, Bid Preparation, and Customer presentation.</li>
<li>Expertise in CCTV/Surveillance, Server, Storage, LAN, WAN, Network and Data Center Technical Solution preparation </li>
<li>Coordinate with Bid/Proposal stitching team and obtain the bid documents from OEM partners. </li>
<li>Work ranging from Designing, Implementation and Commissioning to Delivery and Go-Live, Management and monitoring of complete work on these sites ranging from civil, electrical, networking and team management. </li>
<li>Good problem solving, analytical and logical thinking skills. </li>
<li>More than 8 years of experience would be preferred, should be willing to travel in India as per need.</li>
</ul>
</div>
</section>
<section>
<div>
<img src="MAPL IMAGES/JOBS - MAPL WORLD/TECHNICAL SUPPORT  - MILLENNIUM AUTOMATION PVT LTD (MAPL).png" loading="lazy" alt=""/>
<label for="resumegetinput" onclick="uploadresume()" id="uploadresumeid">Upload Resume</label>
<Link to="mailto:hr@maplworld.com?subject=Job Application with Resume"><button id="applynowjob">Apply now</button></Link> 
</div>
<div>
<p><b>Employment Status : </b> Full-time</p>
<p><b>Experience : </b>2-8 years </p>
<p><b>Job Location : </b> Okhla</p>
</div>
<div>
<h4>HR Manager</h4>
<p>
    Millennium Automation Private Limited </p>
   <p> E 48/9,Okhla Industrial Area, Phase-II, </p>
  <p>  New Delhi 110020</p>
<p>hr@maplworld.com</p>
<p>+91-98710 08021</p>
</div>
</section>
</div>
<div id="getresumediv">
<div>
<p>Ready to join our dynamic team? Share your resume; your skills and passion could be the perfect fit.</p>
</div>
<div>

<a href="mailto:hr@maplworld.com?subject=Job Application with Resume"><label id="labelgetinput">Upload Resume</label></a>
</div>
</div>
</div>
        </div>
    )
}
export default TechnicalconsultantJob;