import { Link } from "react-router-dom";
import React from 'react';
function Solutionnavfunction(){
    return(
        <div className="navigationscrooldiv">
            <div id="navigation_information_div">
    <h3>About us</h3>
    <br/>
    
    <p> With continuous innovation and research, we strive hard to deliver A - grade IT solutions to boost productivity and efficiency of enterprises.We redefine possibilities as your ultimate "Customer Experience Partner," unlocking potential and conquering the Indian Sub-Continent.
    </p>
    
    </div>
    <div id="navigation_detailsDIV">
    <h3>Solutions</h3>
    <div class="navigation_details" id="navigationDeatailscroll">
    <Link to="/SmartCity Solution"><h4><span> Smart City Solutions</span><i class="material-icons">&#xe315;</i></h4> <h6>Elevate Urban Living</h6> </Link>
    <Link to="/CCTV Surveillance Solution"><h4> <span>CCTV Surveillance </span><i class="material-icons">&#xe315;</i></h4> <h6> Vigilant Watchful Eyes</h6></Link>
    <Link to="/data center solutions"><h4> <span>Data Centre Solutions </span><i class="material-icons">&#xe315;</i></h4> <h6>Powering Digital Hubs</h6></Link>
    <Link to="/Unified Communication"><h4><span>Unified Communication </span><i class="material-icons">&#xe315;</i> </h4> <h6>Connected Conversations Unleashed</h6></Link>
    <Link to="/Network Security Solution"><h4><span>Network and Security Solutions </span><i class="material-icons">&#xe315;</i></h4> <h6>Secure Connectivity Shield</h6></Link>
    <Link to="/Rugged Military Grade"><h4><span>Rugged and Mil Grade Solutions </span><i class="material-icons">&#xe315;</i></h4> <h6>Resilient Strength Unleashed</h6></Link>
    <Link to="/WirelessSolutions"> <h4><span>Wireless Solutions </span><i class="material-icons">&#xe315;</i></h4> <h6>Seamless Connectivity Everywhere</h6></Link>
    <Link to="/telecommunication solution"> <h4><span>Telecom Products and RF Solutions </span><i class="material-icons">&#xe315;</i></h4> <h6>Communication Beyond Limits</h6></Link>
    
    </div>
    <Link to="/sitemap" id="navigation_detailsReadBtn">Read more &#x2192;</Link>
    </div>
    <div id="navigation_images"> 
    <a download href="MAPL_PDF.pdf"><img src="MAPL IMAGES/Solution of MAPL.jpg" alt=""/></a>
    
    </div>
        </div>
    )
    }
    export default Solutionnavfunction;