import "./ApplyJobForm.css";
import React from "react";

function ApplyJobForm(){
  
    return(
        <>
        <div className="Applyjobfrombackground">
         
             <form action="mailto:info@maplworld.com?subject=New Job Apply" method="post" enctype="text/plain"id="applicationForm">
                <h2>Apply for Job</h2>
    <input type="text" id="firstName" name="Name" placeholder="&#x26A7; |  Name" required />
    <input type="email" id="email" name="Email" placeholder="&#x2709; |  Email" required />
 <input type="tel" id="phone" name="Phone" placeholder=" &#x260E; |  Phone" required />
    <input type="number" id="Age" name="Age" placeholder=" &#x1F3D8; |  Age" required />
    <input type="text" id="address" name="Address" placeholder="&#x1F3D8;  |  Address" required />
    <input type="text" id="Qualification" name="Qualification" placeholder="&#x1F3D8;  |  Qualification" required />
    <select id="position" name="position" required >
        <option value="desired position">Select your position</option>
        <option value="Internship">Internship</option>
        <option value="Fresher">Fresher </option>
        <option value="Experience"> Experience</option>
    </select>
    <select id="applypost" name="Post" required >
        <option value="desired position">Choose desired position</option>
        <option value="Project Manager">Project Manager</option>
        <option value="Technical consultant">Technical consultant</option>
        <option value="Supply Chain Management">Supply Chain Management</option>
        <option value="Accounts and Finance">Accounts and Finance</option>
        <option value="Business Development">Business Development</option>
        
    </select>
    {/* <label for="JoiningCV"> <i class="material-icons">&#xe2c4;</i>  Upload CV</label>
    <input type="file" name="CV" id="JoiningCV" accept=".pdf,.doc,.docx,.xls,.xlsx" required /> */}
   
    <textarea id="additionalInfo" name="Description" placeholder="Additional info"></textarea>
    <button type="submit">Send</button>
    <br/>
</form>
<img src="" alt=""/>
        </div>
        <div id="getresumediv">
<div>
<p>Ready to join our dynamic team? Share your resume; your skills and passion could be the perfect fit.</p>
</div>
<div>

<a href="mailto:info@maplworld.com"><label id="labelgetinput">Upload Resume</label></a>
</div>
</div>
        </>
    )
}
export default ApplyJobForm;