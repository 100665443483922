import { Link } from "react-router-dom";
import React from 'react';
function IntelligentInfrastructurSmartCitySolutions(){
return(
    <div>
        <div id="category_page">
<div id="category_home_div">
<div class="category_home_Detail_div">
<h1>Intelligent Infrastructure Management: Elevating Efficiency and Connectivity</h1>
<p><b>Transform Your Infrastructure, Empower Your Future.</b>
<br/>
</p>
<Link to="/contact"><button>Contact us</button></Link>
</div>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Compliance and Documentation Made Easy.png" loading="lazy" alt="" />
</div>
<div id="category_scrollDiv">
<h3 style={{color:"white"}} >Our Technology Partners</h3>
<div class="category_scrollImageDiv">
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/DELL - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/HP - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/POLY LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/TEJAS LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/BARCO LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
</div>
</div>
<div id="category_about_div">
<h3>Why Choose Custom Software Solutions?</h3>
<div id="category_about_OneDiv2">
<div id="category_about_box" >
<div>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Streamlined Operations.png"loading="lazy" alt="" />
</div>
<section>
<h4>Streamlined Operations</h4>
<br/>
<small>MAPL's Intelligent Infrastructure Management brings order to complexity. Streamline your operations with automated monitoring, tracking, and documentation, enhancing overall efficiency. </small>
</section>
</div>
<div id="category_about_box" >
<div>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Enhanced Connectivity.png"loading="lazy" alt="" />
</div>
<section>
<h4>Enhanced Connectivity </h4>
<br/>
<small>Ensure seamless connectivity with proactive fault detection and efficient cable management. MAPL's solutions empower your network to provide reliable and uninterrupted service. </small>
</section>
</div>
<div id="category_about_box" >
<div>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Adaptability to Growth.png"loading="lazy" alt="" />
</div>
<section>
<h4>Adaptability to Growth</h4>
<br/>
<small> With scalability at the core, MAPL's designs enable your infrastructure to grow seamlessly. Stay ahead of the curve, adapting to technological advancements and business expansion.</small>
</section>
</div>
<div id="category_about_box" >
<div>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Transform Your Infrastructure, Empower Your Future.png"loading="lazy" alt="" />
</div>
<section>
<h4>Data-Driven Decision Making </h4>
<br/>
<small>Harness the power of data with MAPL. Our solutions provide valuable insights for informed decision-making, ensuring that your infrastructure aligns with your business objectives. </small>
</section>
</div>
<div id="category_about_box" >
<div>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Compliance and Documentation Made Easy.png"loading="lazy" alt="" />
</div>
<section>
<h4>Compliance and Documentation Made Easy</h4>
<br/>
<small> Simplify compliance and documentation processes. MAPL automates these critical tasks, reducing the burden on your team and ensuring accuracy in records.</small>
</section>
</div>
<div id="category_about_box" >
<div>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Focus on Sustainability.png"loading="lazy" alt="" />
</div>
<section>
<h4>Focus on Sustainability </h4>
<br/>
<small> MAPL's commitment to energy optimization contributes to a sustainable future. Optimize energy usage, reduce your carbon footprint, and align your operations with environmental goals.</small>
</section>
</div>
</div>
<div id="category_about_TwoDiv">
<section>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Automated Network Monitoring.png"loading="lazy" alt="" />
<div >
<h4>Automated Network Monitoring </h4>
<p> Leverage MAPL's Intelligent Infrastructure Management for real-time automated monitoring of your network. Gain insights into performance, identify bottlenecks, and enhance overall efficiency.</p>
</div>
</section>
<section>
<div >
<h4>Dynamic Asset Tracking </h4>
<p>MAPL offers dynamic asset-tracking solutions, allowing you to monitor and manage your assets seamlessly. Track movement, access detailed information, and streamline your operations.</p>
</div>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Dynamic Asset Tracking.png"loading="lazy" alt="" />
</section>
<section>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Efficient Cable Management.png"loading="lazy" alt="" />
<div >
<h4>Efficient Cable Management </h4>
<p>Simplify cable management with MAPL's intelligent solutions. Enjoy efficient organization, quick troubleshooting, and the flexibility to adapt to evolving infrastructure needs.</p>
</div>
</section>
<section>
<div >
<h4>Proactive Fault Detection</h4>
<p>Our Intelligent Infrastructure Management system goes beyond monitoring; it proactively detects faults. Identify issues before they impact operations, ensuring uninterrupted connectivity.</p>
</div>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Proactive Fault Detection.png"loading="lazy" alt="" />
</section>
<section>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Automated Documentation.png"loading="lazy" alt="" />
<div >
<h4> Automated Documentation</h4>
<p>MAPL automates the documentation of your infrastructure. Enjoy accurate records, simplify compliance, and eliminate the challenges associated with manual documentation.</p>
</div>
</section>
<section>
<div >
<h4> Scalability and Future-Ready Designs</h4>
<p>Future-proof your infrastructure with MAPL's scalable designs. Our solutions adapt to your growing needs, ensuring that your infrastructure remains efficient and relevant over time. </p>
</div>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Scalability and Future-Ready Designs.png"loading="lazy" alt="" />
</section>
<section>
<img src="MAPL IMAGES/Intelligent Infrastructure Management Elevating Efficiency and Connectivity/Energy Optimization.png"loading="lazy" alt="" />
<div >
<h4>Energy Optimization</h4>
<p> MAPL focuses on energy efficiency in infrastructure management. Optimize power consumption, reduce costs, and contribute to a sustainable and environmentally friendly operation.</p>
</div>
</section>
</div>
</div>
<div>
<h3 >FAQs</h3>
<div id="FAQ_DIV">
<div>
<details>
<summary> <span>Q. What is Intelligent Infrastructure Management? </span>&#10010;</summary>
<p> Intelligent Infrastructure Management is a comprehensive system that automates the monitoring, tracking, and management of network assets and connectivity, enhancing operational efficiency. </p>
</details>
<details>
<summary> <span>Q. How does MAPL's system adapt to business growth? </span>&#10010;</summary>
<p> MAPL's Intelligent Infrastructure Management is designed with scalability in mind. The system easily accommodates business growth, ensuring your infrastructure remains efficient and future-ready. </p>
</details>
<details>
<summary> <span>Q. Can MAPL's solutions integrate with existing infrastructure?</span>&#10010;</summary>
<p> Yes, MAPL's Intelligent Infrastructure Management solutions are designed for seamless integration with existing infrastructure, minimizing disruptions and maximizing efficiency. </p>
</details>
<details>
<summary> <span>Q.  How does automated documentation benefit our organization?</span>&#10010;</summary>
<p>Automated documentation ensures accurate and up-to-date records of your infrastructure. This simplifies compliance, troubleshooting, and overall infrastructure management. </p>
</details>
<details>
<summary> <span>Q. What sets MAPL apart in energy optimization?</span>&#10010;</summary>
<p> MAPL prioritizes energy efficiency by incorporating smart designs and technologies. Our solutions are geared towards optimizing power consumption, reducing costs, and promoting environmental sustainability. </p>
</details>
</div>
<img src="MAPL IMAGES/Faqimage_Mapl.png" loading="lazy" alt="" />
</div>
</div>
</div>
    </div>
)
}
export default IntelligentInfrastructurSmartCitySolutions;