import { Link } from "react-router-dom";
import React from 'react';
function ProjectManagerJob(){
    return(
        <div>
            <div id="jobpage">
    <div id="jobhomediv">
        <h2>Project Manager</h2>
    </div>
    
<div id="Jopdescription">
<section>
<div>
<h2>Project Manager</h2>
<p> <i class="material-icons" style={{color:"#00437d",display:"inline"}}> &#xe568;</i>Okhla
&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa" style={{color:"#00437d",display:"inline",fontSize:"22px"}}> &#xf017;</i> Full Time </p>
</div>
<div>
<h4>Job Description</h4>
<br/>
<ul>
<li>BE/B. Tech/MCA/B.Sc/BCA/Any Garduate</li>
<li>Experience in IT Hardware & Networking/Security and Surveillance/Telecom industry in the areas of designing solution and end to end Project Delivery, service delivery and, customer relationship management.</li>
<li>Liasoning with the Government authorities for concern approvals during Project execution.</li>
<li>Should be Responsible for end to end Project installation and commissioning of sites.</li>
<li>Should be able to allocate and manage team of field engineers.</li>
<li>Coordinate with Vendors, OEMS, and Distributers for purchase and delivery.</li>
<li>Ensure Project timelines and deliveries on time.</li>
<li>Ensure Monthly and Quarterly bill submission to client and follow up with client for payment realization.</li>
<li>Extensive interaction with Clients and End-Users for any query & Support.</li>
<li>Male Candidate with Own conveyance</li>
<li>Salary is open for right candidate</li>
</ul>
</div>


</section>
<section>
<div>
<img src="https://media.istockphoto.com/id/1463692288/photo/diverse-coworkers-writing-on-whiteboard.jpg?s=612x612&w=0&k=20&c=KrwcPqXZOCzmEDikb2TwPpJ_aFQWYFpy_B1i_-l89r4=" loading="lazy" alt="" />
<label for="resumegetinput" onclick="uploadresume()" id="uploadresumeid">Upload Resume</label>
<Link to="mailto:hr@maplworld.com?subject=Job Application with Resume"><button id="applynowjob">Apply now</button></Link> 
</div>
<div>
<p><b>Employment Status : </b> Full-time</p>
<p><b>Experience : </b>2-8 years </p>
<p><b>Job Location : </b> Okhla</p>
</div>
<div>
<h4>HR Manager</h4>
<p>
    Millennium Automation Private Limited </p>
   <p> E 48/9,Okhla Industrial Area, Phase-II, </p>
  <p>  New Delhi 110020</p>
<p>hr@maplworld.com</p>
<p>+91-98710 08021</p>
</div>
</section>
</div>

<div id="getresumediv">
<div>
<p>Ready to join our dynamic team? Share your resume; your skills and passion could be the perfect fit.</p>
</div>
<div>

<a href="mailto:hr@maplworld.com?subject=Job Application with Resume"><label id="labelgetinput">Upload Resume</label></a>
</div>
</div>

</div>
        </div>
    )
}
export default ProjectManagerJob;