
import React from 'react';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';

function Home(){
    window.addEventListener('pageshow',function(){
        mainvideoheight();
        autovideoplay();
      });
          function autovideoplay(){
              document.getElementById('firstvideo').play();
              }

          
              function mainvideoheight(){
                var windowHeight= window.innerHeight;
                var windowWidth= window.innerWidth;
                if(windowWidth>600){
                if(windowWidth>windowHeight){
                var windowheight = windowHeight - 100;
                if(windowWidth>700){
                document.getElementById('HOME_VIDEO_DIV').style.height=windowheight+"px";
              
                
                }
                }else{
                document.getElementById('HOME_VIDEO_DIV').style.height="600px";
             
                }
                }
                }
        
        function DownBroNone(){
            document.getElementById('DownloadBrochurebtn').style.display="none";
            document.getElementById('buttonDiv').style.display="block";
            document.getElementById('BrochureDiv').style.display="grid";
            
            }
            function brochure_close(){
              document.getElementById('BrochureDiv').style.display="none";
              
             }
  

return(
<div>

<div id="homeDiv_Page">
<div id="HOME_DIV">
<div id="HOME_VIDEO_DIV">
<video src="MAPL VIDEO/MaplWorldVideos.mp4" id="firstvideo" loop playsinline muted ></video>
</div>
</div>
<div id="main_h3heading_div">
    <h3 id="home_Heading1">How Can We Help Grow Your Business?</h3>
    <h8 id="mainpragrap">Partnering for Business Growth</h8>
    </div>
<div id="about_grow_div">

<div id="growYourBusiness_div">
<div class="flip-card" >
<div class="flip-card-inner">
<div id="growDiv_idfront">
<img src="MAPL IMAGES/Scalability mapl.jpeg"  alt="" />
<div id="growDiv_detailDiv">
<h4>Scalability</h4>
<p>With our innovative solutions, we've achieved a remarkable 70-75% increase in the ease of integrating new tools and systems, ensuring your organization remains agile and future-ready. </p>
<input type="button" value="Read more &#x276F;" id="growDiv_button"/>
</div>
</div>
<div class="growDiv_idback">
<h4>Scalability</h4>
<p>Scalability is at the core of what we do. Our solutions are meticulously crafted to adapt seamlessly to businesses of all sizes and stages. Whether you're a budding startup or an established enterprise, our offerings grow with you. We understand that one size doesn't fit all, and that's why our scalable solutions are tailored to meet your unique needs, ensuring your success at every step. </p>
</div>
</div>
</div>
<div class="flip-card" >
<div class="flip-card-inner">
<div id="growDiv_idfront">
<img src="MAPL IMAGES/Reliability mapl.jpeg" alt="" />
<div id="growDiv_detailDiv">
<h4>Reliability</h4>
<p>With a remarkable 65-70% reduced workload, your teams can focus on strategic initiatives and drive innovation instead of being weighed down by day-to-day operational tasks. </p>
<input type="button" value="Read more &#x276F;" id="growDiv_button"/>
</div>
</div>
<div class="growDiv_idback">
<h4>Reliability</h4>
<p>We go beyond promises, delivering a steadfast commitment to ensuring unwavering service consistency and dependability. With a focus on reliability, our aim is to provide you with more than just solutions; we offer a foundation of trust, allowing your business to operate with confidence and certainty. </p>
</div>
</div>
</div>
<div class="flip-card" >
<div class="flip-card-inner">
<div id="growDiv_idfront">
<img src="https://i.pinimg.com/474x/ec/e5/ae/ece5aea36d3ce8f4985fab6d4103fef0.jpg" alt="" />
<div id="growDiv_detailDiv">
<h4>Market Adaptability</h4>
<p>Leveraging cutting-edge technologies and agile strategies, we have achieved notable success, with clients experiencing a 25% increase in market responsiveness. </p>
<br/>
<input type="button" value="Read more &#x276F;" id="growDiv_button"/>
</div>
</div>
<div class="growDiv_idback">
<h4>Market Adaptability</h4>
<p>Market trends evolve, and so do we. is committed to market adaptability, ensuring that our offerings evolve with the ever-changing business landscape. From shifting consumer behaviors to emerging trends, our solutions are agile and ready to pivot, providing you with the adaptability needed to thrive in dynamic markets. With us, you're not just adapting to market changes; you're shaping them.</p>
</div>
</div>
</div>
<div class="flip-card" >
<div class="flip-card-inner">
<div id="growDiv_idfront">
<img src="MAPL IMAGES/Vendor Diversity mapl.jpeg"  alt="" />
<div id="growDiv_detailDiv">
<h4>Vendor Diversity</h4>
<p>Partnering with diverse tech OEMs, we offer curated solutions tailored to your unique needs, ensuring a spectrum of options to meet and exceed your expectations effectively. </p>
<input type="button" value="Read more &#x276F;" id="growDiv_button"/>
</div>
</div>
<div class="growDiv_idback">
<h4>Vendor Diversity</h4>
<p>Partnering with a spectrum of tech OEMs, we bring you a curated selection of solutions tailored to your unique needs. This diversity ensures that you have access to the best-in-class technologies, handpicked to elevate your business operations. We believe that innovation is born out of collaboration, and our vendor diversity reflects our commitment to delivering excellence through partnerships. </p>
</div>
</div>
</div>
</div>
<div class="impactdiv">
<div><h2 id="imapactfistCounter">100%</h2>
<p>Compliance offered</p></div>
<div><h2 id="imapactsecondCounter">100%</h2>
<p>Trust rate guaranteed</p></div>
<div><h2 id="imapactthirdCounter">100%</h2>
<p>Business excellence </p></div>
</div>
</div>
<div id="main_h3heading_div">
    <h3 id="home_Heading3">Continuous Growth Momentum</h3>
   
    </div>
<div id="Milestones_DIV">

<img src="MAPL IMAGES/NEW ROADMAP - MAPL - MILLENNIUM AUTOMATION PVT LTD - MILESTONES ACHIEVED.png"  alt="" />
<div>
   
    <p> Embarking on a journey of relentless progress, we uphold a steadfast commitment to continuous growth. Our foundation rests on pillars of operational excellence, strategic planning, and unmatched technical expertise. With a Pan India presence, we deliver customized solutions fortified by financial strength, ensuring a trajectory of success in every endeavor.</p>
   <br/>
   <Link to="about"><button>About us </button></Link>
   

</div>

</div>
<div id="main_h3heading_div">
    <h3 id="home_Heading4" >Our Technology Partners</h3>
    <h8 id="mainpragrap" >Partnering with industry pioneers</h8>
    </div>

<div id="ourPartners_DIV">


<div id="ourPartners_box">
<Marquee pauseOnHover speed={100} id="ourpartnerMarquee1">
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/DELL - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/HP - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/POLY LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/TEJAS LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/BARCO LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/SCHNEIDER LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/SAMSUNG - LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/RADWARE LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/MICROSOFT LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/RED HAT LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/CHECK POINT - LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/FORTINET LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/VMWARE LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/CISCO LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/JUNIPER LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/SOPHOS LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/CP PLUS LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/I2V LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/VEHANT LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/EVEREST IMS LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/TYRONE LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" alt="" />
</Marquee>
<Marquee pauseOnHover speed={100}id="ourpartnerMarquee2">
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/MICRO FOCUS LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/TREND MICRO LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/UNILINE LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/FORCEPOINT LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/AIRTEL LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/F5 LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/D LINK LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/COMMSCOPE LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/DAHUA LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/LENOVO LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/LG LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/NOKIA LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/HITACHI LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/SONY LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/EPSON LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/NUMERIC LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/VIEWSONIC LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/NVENT LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/RITTAL LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/MANIFOLD RITTAL LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
<img src="MAPL IMAGES/OFFICIAL LOGOS PNG/ACER LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png"  alt="" />
</Marquee>
</div>



{/* <div id="ourPartnersButtonDiv">
<span ><i class="material-icons">&#xe314;</i></span>
<section></section>
<span ><i class="material-icons">&#xe315;</i></span>
</div> */}

</div>

<div id="main_h3heading_div">
    <h3 id="home_Heading2" >What We Do</h3>
    <h8 id="mainpragrap">
    Choose Us, Choose Excellence!
    </h8>
    </div>
    <div id="whatDO_WE_DIV">
    <div id="category_about_box">
    <div>
    <img src="MAPL IMAGES/What Do We Do/Secure Infrastructure & Network.png"  alt="" />
    </div>
    <section>
    <h4>Secure Infrastructure & Network
    </h4>
    <br/>
    <small>MAPL's distinctive unified communication solutions guarantee uninterrupted 24/7 connectivity, virtually eliminating downtime for all stakeholders: the workforce, peers, customers, partners, and suppliers, both internal and external. Everyone is included in our seamless communication network.
    </small>
    </section>
    </div>
    <div id="category_about_box">
    <div>
    <img src="MAPL IMAGES/What Do We Do/Efficient, Robust, & Reliable IT Solutions.png"  alt="" />
    </div>
    <section>
    <h4>Robust, & Reliable IT Solutions
    </h4>
    <br/>
    <small> MAPL's unique computer-intensive solutions are highly customisable and cost-efficient, so we can deliver and implement our projects seamlessly, addressing the specific needs of our clients, and be flawlessly integrated into the current work systems and work culture.
    </small>
    </section>
    </div>
    <div id="category_about_box" >
    <div>
    <img src="MAPL IMAGES/What Do We Do/Unified Communication Solutions.jpeg"  alt="" />
    </div>
    <section>
    <h4>Unified Communication Solutions
    </h4>
    <br/>
    <small> MAPL's unique unified communication solutions ensure absolute 24*7 connectivity with almost ZERO downtime between all stakeholders—workforce, peers, customers, partners, and suppliers—everyone included. both internal and external.
    </small>
    </section>
    </div>
    <div id="category_about_box" >
    <div>
    <img src="MAPL IMAGES/What Do We Do/IT & Telecom Infra Solutions.png"  alt="" />
    </div>
    <section>
    <h4>IT & Telecom Infra Solutions
    </h4>
    <br/>
    <small> MAPL is well-known for providing highly stable, latency-proof, scalable, and very reliable IT infrastructure that is network optimised with absolute negligible downtime while also ensuring highly efficient storage virtualization and leakage-proof system security round the clock.
    </small>
    </section>
    </div>
    <div id="category_about_box" >
    <div>
    <img src="MAPL IMAGES/What Do We Do/Wiring the Wireless Solutions.jpeg"  alt="" />
    </div>
    <section>
    <h4>Wiring the Wireless Solutions
    </h4>
    <br/>
    <small>MAPL, boasts of seamless integration for complete range of wireless solutions to include WWANS, WLANS, OFC, WMANS, Wi-Fi, RF, AP, and smart pole. We only make use of best quality, high-end routers and LAN cables to ensure seamless last-mile connectivity amongst end users.
    </small>
    </section>
    </div>
    <div id="category_about_box" >
    <div>
    <img src="MAPL IMAGES/What Do We Do/Smart Solutions for Smart Cities.png"  alt="" />
    </div>
    <section>
    <h4>Smart Solutions for Smart Cities
    </h4>
    <br/>
    <small> MAPL's Smart City solutions can sense, process, and rapidly deliver bits of information in a seamless fashion to make informed decisions easier to implement and to improve the overall environment and improved safety for its end users.
    </small>
    </section>
    </div>
    </div>
<div id="main_h3heading_div">
    <h3 id="home_Heading5" >Pioneering the Industry</h3>
    <h8 id="mainpragrap" >At the Forefront of Innovation and Excellence</h8>
    </div>
<div id="Trophyimg_DIV">

<img src="MAPL IMAGES/MAPL ACHIEVEMENTS AND RECOGNITIONS.jpg"  alt="" />
</div>
<br/>
<br/>
<br/>
<br/>
<div id="main_h3heading_div">
    <h3 id="home_Heading7" >Pride in Numbers</h3>
    <h8 id="mainpragrap">Data narratives</h8>
    </div>
<div id="OurNumbers_counter_DIV">

<div class="OurNumbers_counter_BOX">
<div class="Number_counter_div" >
<h2 id="counter1">23+ </h2>
<h5>Years Of Experience</h5>
</div>
<div class="Number_counter_div">
<h2 id="counter2"> 2500+</h2>
<h5>Customers</h5>
</div>
<div class="Number_counter_div">
<h2 id="counter3"> 30+</h2>
<h5>Accredited Specialists</h5>
</div>
<div class="Number_counter_div">
<h2 id="counter4"> 100%</h2>
<h5>Compliance</h5>
</div>
<div class="Number_counter_div">
<h2 id="counter5"> 300+</h2>
<h5>Employees Worldwide</h5>
</div>
<div class="Number_counter_div">
<h2 id="counter6"> 90%</h2>
<h5>
    Customer Retention</h5>
</div>
<div class="Number_counter_div">
<h2 id="counter7">24x7 </h2>
<h5>
    National Support</h5>
</div>
<div class="Number_counter_div">
<h2 id="counter8">6</h2>
<h5>
    Offices</h5>
</div>
</div>
</div>
<div id="professional_service_div">
<div class="professional_service_content_div">
    <h2>Overseas Presence in Singapore</h2>
<h4>Creating a Global Sourcing Hub with Logistic Capabilities (Year 2020)</h4>
<br/>
<br/>
<b>Larsen & Toubro Limited, Construction (Singapore)</b>
<br/>
<br/>
<p>Supply of Telepresence Room Material & EMS Hardware & Software and setting up of Integrated Telepresence Command Control Center including Networking, AV, Display, Electrical, Furniture & SIP Telephone with Universal Power Supply with Warranty Support for ARMY IP MPLS Project.</p>
<br/>
<b>Himachal Futuristic Communications Limited (Singapore)</b>
<br/>
<br/>
<p><b> </b> Supply of 719 Cyber Security Firewall & EMS Software for ARMY IMPLS Project
</p>
<br/>
<p>End Customer : Army </p>
</div>
<div id="professional_divImg">
</div>
</div>
<div id="main_h3heading_div">
<h3 >Recognitions</h3>
<p id="mainpragrap">Excellence Acknowledged</p>
</div>
<div id="Recognition_div">
<div ><img src="MAPL IMAGES/Recognition_1image.jpg" alt='' style={{width:"100%"}}/></div>
<div ><img src="https://5.imimg.com/data5/LY/PC/CH/SELLER-10064375/cmmi-certification-service-500x500-500x500.jpg"  alt=""  style={{width:"100%"}}/></div>
<div ><img src="MAPL IMAGES/ISO-mapl-image.png"  alt=""  style={{width:"80%"}}/><h6>9001</h6></div>
<div ><img src="MAPL IMAGES/ISO-mapl-image.png"  alt=""  style={{width:"80%"}}/><h6>14001</h6></div>
<div ><img src="MAPL IMAGES/ISO-mapl-image.png"  alt=""  style={{width:"80%"}}/><h6>20000</h6></div>
<div ><img src="MAPL IMAGES/ISO-mapl-image.png"  alt=""  style={{width:"80%"}}/><h6>27000</h6></div>
</div>

<div id="HomePagecta">
    <p> Embark on a transformative journey where technology and purpose converge. 
        <br/>
       <b>Explore new horizons with our cutting-edge IT solutions.</b></p>
        <a id="DownloadBrochurebtn" onClick={DownBroNone}><button><i class="material-icons">&#xe2c4;</i> &nbsp; Download Brochure</button></a>
        <div id="buttonDiv"></div>
</div>
<div id="BrochureDiv">
    <div> 
        <img src="MAPL IMAGES/maplworld_logo.png"/> 
    <a href="MAPL PDFS/MAPL_PDF.pdf" title='Share'> <i class="material-icons">&#xe80d;</i></a>
    <a href="MAPL PDFS/MAPL_PDF.pdf" title='Print'> <i class="material-icons">&#xe8ad;</i></a>
    &nbsp;&nbsp;
        <i class="material-icons" onClick={brochure_close} title='Close'>&#xe5cd;</i>
        
        </div>
    

    <img src="MAPL IMAGES/Why MAPL.jpg"/>
   
   <a download href="MAPL PDFS/MAPL_PDF.pdf"> <button><i class="material-icons">&#xe2c4;</i> Download</button></a>
</div>
</div>
</div>

)



}
export default Home;
