import { Link } from 'react-router-dom';
import './errorpage.css'
import React from 'react';
function Page404(){

    return(
        <div>
            <br/>
            <br/>
            <br/>
            <div id="errorpage"><h1>404 page</h1>
            <div>
            <Link to="/">Home</Link>
            <Link to="/sitemap">Sitemap</Link>
            <Link to="/contact">Contact</Link>
            </div>
            </div>
    
           
        </div>
    )
}
export default Page404;