
import { Link } from "react-router-dom";
import Companynavfunction from './companynavfunction';
import Solutionnavfunction from './solutionnavfunction';
import Servicenavfunction from './servicenavfunction';
import Caseanvfunction from './caseanvfunction';
import { useState } from "react";

import { useNavigate } from "react-router-dom";

function Header(){
const [page, Navigationbution] = useState('company');

let navigate = useNavigate();
function maplsearch(){
    var maplmainsearchinput = document.getElementById('maplmainsearch').value.toLowerCase();
    navigate(maplmainsearchinput);
    document.getElementById('maplmainsearch').value="";

    }
    window.addEventListener('load',function(){
        var locationcheck = window.location.pathname;
        console.log(locationcheck);
        navigate(locationcheck)
      });
function Headergroup(){
    document.getElementById('navigation_div').style.height="0px";
}

        
           
            
            function homesearchbox_open(){
       document.getElementById('nav_secondbutton').style.display="none";
       document.getElementById('Home_Searchdiv').style.display="grid";
            document.getElementById('homesearchboxopenbtn').style.display="none";
            document.getElementById('homesearchboxclosebtn').style.display="block";
            }
            function homesearchbox_close(){
                document.getElementById('nav_secondbutton').style.display="block";
                document.getElementById('Home_Searchdiv').style.display="none";
            document.getElementById('homesearchboxopenbtn').style.display="block";
            document.getElementById('homesearchboxclosebtn').style.display="none";
            }

return(
<div>
<div id="headergroup" onMouseLeave={Headergroup}>

<header id="navbar_tap">
<Link to="/"><img src="MAPL IMAGES/maplworld_logo.png"  alt="" /></Link>
<nav>
<a to="/company" onMouseEnter={()=>Navigationbution("company")} id="navclickbuttons">Company <i class="fa">&#xf107;</i></a>
<a to="/solution" onMouseEnter={()=>Navigationbution("solution")} id="navclickbuttons2">Solutions <i class="fa">&#xf107;</i> </a>
<a to="/service" onMouseEnter={()=>Navigationbution("service")} id="navclickbuttons3">Services <i class="fa">&#xf107;</i></a>
<a to="/case studies" onMouseEnter={()=>Navigationbution("case")} id="navclickbuttons4">Case Studies <i class="fa">&#xf107;</i></a>



<Link to="/career">Careers</Link>
 
</nav>
<div>

    <i class="material-icons" onClick={homesearchbox_close} id="homesearchboxclosebtn"> &#xe5cd;</i>
    <i class="material-icons" onClick={homesearchbox_open} id="homesearchboxopenbtn"> &#xe8b6;</i>
</div>
<div id="Home_Searchdiv">
            <input type="search" list="MAPLSEARCHLIST" placeholder=" Search......" id="maplmainsearch"/>
            <button onClick={maplsearch}><i class="material-icons">&#xe8b6;</i></button>
            <datalist id="MAPLSEARCHLIST">
            <option value="About"/>
            <option value="Career"/>
            <option value="Internship"/>
            <option value="Terms"/>
            <option value="Privacy"/>
            <option value="Contact"/>
            <option value="Jobs"/>
            <option value="Csr"/>
            <option value="FAQ"/>
            <option value="Sitemap"/>
            <option value="Enterprise management"/>
    <option value="Intelligent service"/>
    <option value="Data AI Management Services"/>
    <option value="Business Processes Services"/>
<option value="Cyber Security"/>
    <option value="Cloud Service"/>
    <option value="Sustainability Service"/>
    <option value="Infrastructure Services"/>
            <option value="SmartCity Solution"/>
 <option value="Data center solutions"/>
<option value="Network Security Solution"/> 
 <option value="WirelessSolutions"/>
 <option value="CCTV Surveillance Solution"/>
 <option value="Unified Communication"/>
 <option value="Rugged Military Grade"/>
<option value="Telecommunication solution"/>
 <option value="CloudSolution"/>
 <option value="Smart ClassRoom solution"/>

<option value="Mobile Video Conferencing"/>
 <option value="Security surveillance solution"/>
 <option value="AVTS AVLS Solution"/>
 <option value="Mobile Surveillance System"/>
 <option value="Network Element Systems solutiion"/>
 <option value="SaaS Development"/>
 <option value="application performance system"/>
 <option value="Custom Software Solution"/>
 <option value="Intelligent infrastructur SmartCity"/>
 <option value="high end security solution"/>
 <option value="AI and Machine Learning Solution"/>
 <option value="LawfulInterceptMonitoring"/>
 <option value="Network Management System"/>
 <option value="Intelligent Traffic Management Solution"/>
 <option value="Managed Services solution"/>
 <option value="FacialRecognitionTechnology"/>
 <option value="Power supply solution"/> 
            </datalist>
            </div>
<div id="navtap_buttonsdiv">
<div class="nav_buttons_div">

<Link to="/contact">
    <button id="nav_secondbutton"><i class="material-icons">&#xe913;</i>Get in touch</button>
    </Link> 
 
</div>
</div>

</header>
<div id="slide_nav_soical_div">
<a href="https://www.linkedin.com/company/maplworld/"><i class="fa"><img src="https://th.bing.com/th/id/R.a330e248626552a23af35e5c46526234?rik=DZhkgnpER0YViQ&riu=http%3a%2f%2fpngimg.com%2fuploads%2flinkedIn%2flinkedIn_PNG8.png&ehk=4bFzIDABrAypqOis7809R99fdbUW93GC4XfvnNxZfdA%3d&risl=&pid=ImgRaw&r=0"  alt="" /></i> <p></p></a>
<a href="https://twitter.com/MAPLWorld"><i class="fa"><img src="https://img.freepik.com/premium-vector/new-twitter-logo-x-2023-twitter-x-logo-vector-download_691560-10809.jpg"  alt="" /></i> <p></p></a>
<a href="https://www.facebook.com/people/Millennium-Automation-Pvt-Ltd/61555239089074/"><i class="fa"><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Facebook_logo_36x36.svg/2048px-Facebook_logo_36x36.svg.png"  alt="" /></i> <p></p></a>
<a href="https://www.instagram.com/maplworld/?hl=en"><i class="fa"><img src="https://th.bing.com/th/id/OIP.A_QLZ7Y9nBwaWnkhCbvGmQHaHZ?rs=1&pid=ImgDetMain"  alt="" /></i><p></p></a>
<a href="https://www.youtube.com/@MAPLWorld"><i class="fa"><img src="https://1.bp.blogspot.com/-KMQ1apsoGXc/XeI_12uOXzI/AAAAAAAAF4I/kM644FmfGmsq0ZS5aG_OLKsOLllAAaIUQCLcBGAsYHQ/s320/Youtube-logo-2342x1642-px.png"  alt="" /></i> <p></p></a>
<a href="https://wa.me/9311240651" id="whatsappslidebtn"><i class="fa">&#xf232;</i> </a>
</div>
<div id="navigation_div">
{page === "company"&&<Companynavfunction/>}
{page === "solution"&&<Solutionnavfunction/>}
{page === "service"&&<Servicenavfunction/>}
{page === "case"&&<Caseanvfunction/>}
</div>
</div>
</div>

)


}
export default Header;