import React from 'react';
function BusinessProcessesServices(){
    return(
        <div>
            <div id="Services_page">
<div id="ServiceHome_div">

</div>
<div id="ServiceHome_Detail">
    <h2>Business Processes Services with MAPL</h2>
    <h4>Creating Digital Dominance</h4>
    <p>At MAPL, we redefine business operations by leveraging cutting-edge technology and expertise. Our Digital Operations and Platforms (DOP) lead the industry in providing next-generation solutions to global enterprises. Combining core business knowledge with digital technologies, robotics process automation, cognitive technologies, and analytics, we empower businesses with powerful intelligence. This enhances business visibility, enabling leaders to respond swiftly to evolving market needs.
    </p>
    </div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/MAPL Business Processes Services/Data Reporting Analytics and Research.png" loading="lazy" alt="" fetchpriority="auto"/></div>
<section class="ServiceAboutright_section">
<h4>Data Reporting Analytics and Research</h4>
<p>MAPL excels in delivering comprehensive data reporting, analytics, and research solutions. We harness the power of data to provide valuable insights, enabling informed decision-making and strategic planning. Our approach ensures that your business is equipped with the right information to stay ahead in today's dynamic market landscape.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Financial Risk Mitigation</h4>
<p>MAPL understands the intricacies of financial landscapes. Our services are designed to help you navigate and mitigate financial risks effectively. By employing robust strategies and leveraging technology, we provide solutions that contribute to the financial stability and success of your business.</p>
</section>
<div><img src="MAPL IMAGES/MAPL Business Processes Services/Financial Risk Mitigation.png" loading="lazy" alt="" fetchpriority="auto"/></div>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/MAPL Business Processes Services/Content Management Services.png" loading="lazy" alt="" fetchpriority="auto"/></div>
<section class="ServiceAboutright_section">
<h4>Content Management Services</h4>
<p>Efficiently manage and organize your content with MAPL's Content Management Services. We offer comprehensive solutions to handle digital content, ensuring accessibility, security, and relevance. Our services empower businesses to streamline content workflows and enhance overall content management efficiency.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Human Resource Outsourcing</h4>
<p>MAPL provides top-notch Human Resource Outsourcing services, allowing businesses to focus on their core activities while we manage HR functions. From talent acquisition to payroll processing, our comprehensive HR outsourcing solutions ensure optimal workforce management.</p>
</section>
<div><img src="MAPL IMAGES/MAPL Business Processes Services/Human Resource Outsourcing.png" loading="lazy" alt="" fetchpriority="auto"/></div>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/MAPL Business Processes Services/Product Quality Complaints and Management.png" loading="lazy" alt="" fetchpriority="auto"/></div>
<section class="ServiceAboutright_section">
<h4>Product Quality Complaints and Management</h4>
<p>Ensure the quality and reputation of your products with MAPL's Product Quality Complaints and Management services. We employ advanced strategies to address and resolve product quality issues swiftly, minimizing the impact on your brand and customer satisfaction.</p>
</section>
</span>
</div>
<div id="ServiceThird_div" >
<img src="MAPL IMAGES/MAPL Business Processes Services/Marketing as a Service.png" loading="lazy" alt="" fetchpriority="auto"/>
<div>
<h4>Marketing as a Service</h4>
<p>Transform your marketing strategies with MAPL's Marketing as a Service. Our innovative and data-driven approach to marketing ensures that your brand stands out in the competitive landscape. From digital marketing to campaign management, we provide end-to-end marketing solutions.</p>
</div>
</div>
<div id="ServiceThird_div" >
<div>
<h4>Digital Procurement Services</h4>
<p>Streamline your procurement processes with MAPL's Digital Procurement Services. Our solutions leverage digital technologies to enhance efficiency, transparency, and cost-effectiveness in procurement activities. From vendor management to procurement analytics, we cover it all.</p>
</div>
<img src="MAPL IMAGES/MAPL Business Processes Services/Digital Procurement Services.png" loading="lazy" alt="" fetchpriority="auto"/>
</div>
<div id="ServiceThird_div" >
<img src="MAPL IMAGES/MAPL Business Processes Services/Digital Order Management.png" loading="lazy" alt="" fetchpriority="auto"/>
<div>
<h4>Digital Order Management</h4>
<p>Efficiently manage your orders in the digital era with MAPL's Digital Order Management services. We provide solutions that optimize order processing, inventory management, and order fulfillment, ensuring a seamless and responsive order-to-cash cycle.</p>
</div>
</div>
<div id="ServiceThird_div" >
<div>
<h4>Customer Management Services</h4>
<p>Enhance customer relationships with MAPL's Customer Management Services. From customer support to relationship management, our services are designed to create positive and lasting connections with your customers. We prioritize customer satisfaction, loyalty, and overall experience.</p>
</div>
<img src="MAPL IMAGES/MAPL Business Processes Services/Customer Management Services.png" loading="lazy" alt="" fetchpriority="auto"/>
</div>
</div>
        </div>
    )
}
export default BusinessProcessesServices;