import { Link } from "react-router-dom";
import React from "react";
function AccountsFinanceJob(){
return(
    <div>
        <div id="jobpage">
    <div id="jobhomediv">
        <h2>Accounts and Finance</h2>
    </div>
<div id="Jopdescription">
<section>
<div>
<h2>Accounts and Finance</h2>
<p><i class="material-icons" style={{color:"#00437d",display:"inline"}}> &#xe568;</i>Okhla
&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa" style={{color:"#00437d",display:"inline",fontSize:"22px"}}> &#xf017;</i> Full Time
</p>
</div>
<div>
<h4>Job Description</h4>
<br/>
<ul>
<li>Internal Fund Management of Group of Company</li>
<li>Preparation of Letter of Credit Documents and same submit to Bank for Discounting Processing. </li>
<li>Preparation of Bill Discounting Documents and same submit to NBFC Letter of Credit Documents and same submit to Bank for Discounting Processing.</li>
<li>Preparation of Bill Discounting Documents and same submit to NBFC and Bank.</li>
<li>LC Issuance from Bank as per requirement.</li>
<li>Arrange Working Capital Fund Base & Non Fund Base from Bank.</li>
<li>Arrange LC Discounting Limited from Bank.</li>
<li>Arrange Bill Discounting Limited from Bank or NBFC.</li>
<li>Arrange Business Loan and any kind of Loan from Bank and NBFC .and CC Limit Renewal Liaoning with Bank & Correspondence with Bank & Parties</li>
<li>Preparation of Performa Invoice as per Purchase Order Term & Condition.</li>
<li>Co-Ordination with the SCM team.</li>
<li>Prepare cash flow of ongoing or upcoming projects.</li>
<li>Manage Project Accounting.</li>
<li>Minimum10 years' experience</li>
<li>Salary is not a constraint for right candidate</li>
</ul>
</div>
</section>
<section>
<div>
<img src="MAPL IMAGES/JOBS - MAPL WORLD/ACCOUNTS & FINANCE  - MILLENNIUM AUTOMATION PVT LTD (MAPL).png" loading="lazy" alt=""/>

<Link to="mailto:hr@maplworld.com?subject=Job Application with Resume"><button id="applynowjob">Apply now</button></Link> 
</div>
<div>
<p><b>Employment Status : </b> Full-time</p>
<p><b>Experience : </b>2-8 years </p>
<p><b>Job Location : </b> Okhla</p>
</div>
<div>
<h4>HR Manager</h4>
<p>
    Millennium Automation Private Limited </p>
   <p> E 48/9,Okhla Industrial Area, Phase-II, </p>
  <p>  New Delhi 110020</p>
<p>hr@maplworld.com</p>
<p>+91-98710 08021</p>
</div>
</section>
</div>
<div id="getresumediv">
<div>
<p>Ready to join our dynamic team? Share your resume; your skills and passion could be the perfect fit.</p>
</div>
<div>

<a href="mailto:hr@maplworld.com?subject=Job Application with Resume"><label id="labelgetinput">Upload Resume</label></a>
</div>
</div>
</div>
    </div>
)
}
export default AccountsFinanceJob;
