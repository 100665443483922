import { Link } from "react-router-dom";
import React from 'react';
function NetworkSecuritySolution(){
return(
    <div>
        <div id="category_page" >
<div id="category_home_div">
<div class="category_home_Detail_div">
<h1>Elevate Your Security: MAPL's Network Security Solutions</h1>
<p><b>Unparalleled Protection, Unmatched Expertise</b>
<br/>
At MAPL, we redefine network security with cutting-edge solutions. Safeguard your digital assets and elevate your business security to new heights.
</p>
<Link to="/contact"><button>Contact us</button></Link>
</div>
<img src="MAPL IMAGES/Network Management System at MAPL/NETWORK MANAGEMENT.png" loading="lazy" alt="" />
</div>
<div id="category_scrollDiv">
<h3 style={{color:"white"}} >Our Technology Partners</h3>
<div class="category_scrollImageDiv">
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/DELL - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/HP - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/POLY LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/TEJAS LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/BARCO LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
</div>
</div>
<div id="category_about_div">

<div id="category_aboutHalfwidth">
<img src="MAPL IMAGES/Network Management System at MAPL/Modernizing Network Management - IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
<div>
<h5>Comprehensive Threat Detection and Prevention</h5>
<p>MAPL's advanced threat detection systems identify and neutralize potential risks before they impact your network. Our proactive approach ensures airtight security against evolving threats.</p>
</div>
</div>
<div id="category_aboutHalfwidth">
<div>
<h5>Next-Gen Firewall Solutions</h5>
<p>Deploy state-of-the-art firewall solutions tailored to your business needs. MAPL's expertise lies in implementing next-gen firewalls that provide robust defense against cyber threats, ensuring your network stays secure.</p>
</div>
<img src="MAPL IMAGES/Network Management System at MAPL/Meeting Hybrid Work Challenges - IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
</div>
<div id="category_aboutHalfwidth">
<img src="MAPL IMAGES/Network Management System at MAPL/Operational Models Cloud-based and On-premises - IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
<div>
<h5>Intrusion Detection and Prevention Systems (IDPS)</h5>
<p>Stay one step ahead with our IDPS solutions. MAPL employs sophisticated systems to detect and prevent unauthorized access, ensuring your network remains a fortress against intruders.</p>
</div>
</div>
<div id="category_aboutHalfwidth">
<div>
<h5>Secure VPN Solutions for Remote Access</h5>
<p>In the era of remote work, our secure VPN solutions guarantee encrypted connections. MAPL ensures your team stays connected securely, regardless of their location, without compromising on data integrity.</p>
</div>
<img src="MAPL IMAGES/Network Management System at MAPL Elevating Connectivity Solutions/secure vpn solution for remote access mapl.png" loading="lazy" alt="" />
</div>
<div id="category_aboutHalfwidth">
<img src="MAPL IMAGES/Network Management System at MAPL Elevating Connectivity Solutions/Robust Endpoint Security mapl.jpg" loading="lazy" alt="" />
<div>
<h5>Robust Endpoint Security</h5>
<p> Protect your devices and endpoints with MAPL's robust security measures. Our solutions safeguard every entry point, providing a comprehensive defense against malware, ransomware, and other cyber threats.</p>
</div>

</div>
<div id="category_aboutHalfwidth">
<div>
<h5>Network Security Audits and Compliance </h5>
<p>Ensure your network meets industry standards with our thorough security audits. MAPL identifies vulnerabilities, assesses risks, and ensures compliance, providing you with a secure and compliant infrastructure.</p>
</div>
<img src="MAPL IMAGES/Network Management System at MAPL Elevating Connectivity Solutions\Fault Identification.png" loading="lazy" alt="" />
</div>
<h3>Unveiling the Power of Network Management</h3>
<div id="category_about_FourDiv">
<div>
<h5>Expertise</h5>
<p>Benefit from MAPL's years of experience in the ever-evolving landscape of network security. Our team of experts brings unparalleled knowledge to design, implement, and manage robust security solutions.</p>
</div>
<div>
<h5>Customization</h5>
<p>MAPL understands that one size doesn't fit all. Our network security solutions are tailored to meet the unique needs and challenges of your business, ensuring a perfect fit for your security requirements.</p>
</div>
<div>
<h5>24/7 Support</h5>
<p>Rest easy knowing that MAPL's support team is available around the clock. We provide rapid response and resolution to any security concerns, ensuring your network remains secure and operational.</p>
</div>
<div>
<h5>Innovation</h5>
<p>Stay ahead of emerging threats with MAPL's commitment to innovation. We continuously update our security protocols to address new challenges, keeping your network secure against the latest threats.</p>
</div>
</div>
</div>
<div>
<h3>FAQs</h3>
<div id="FAQ_DIV">
<div>
<details>
<summary> <span>Q. Why is network security important for businesses?</span>&#10010;</summary>
<p>Ans. Network security is crucial for safeguarding sensitive data, maintaining customer trust, and preventing disruptions to business operations. It protects against cyber threats and ensures the integrity of digital assets.</p>
</details>
<details>
<summary><span>Q. How often should network security audits be conducted?</span>&#10010;</summary>
<p>Ans. Network security audits should be conducted regularly, at least annually. However, the frequency may vary based on industry regulations, changes in infrastructure, or emerging security threats. </p>
</details>
<details>
<summary><span>Q. How does MAPL ensure compliance with industry standards?</span>&#10010;</summary>
<p>Ans. MAPL conducts thorough security audits, assesses vulnerabilities, and implements measures to ensure compliance with industry standards. Our experts stay updated on regulatory requirements to provide the latest security protocols.</p>
</details>
<details>
<summary><span>Q. Can MAPL's network security solutions handle remote work scenarios?</span>&#10010;</summary>
<p>Ans. Yes, MAPL's network security solutions include secure VPN options, ensuring encrypted connections for remote work scenarios. We prioritize secure remote access without compromising on data protection.</p>
</details>

</div>
<img src="MAPL IMAGES/Faqimage_Mapl.png" loading="lazy" alt="" />
</div>
</div>
</div>
    </div>
)
}
export default NetworkSecuritySolution;