import { Link } from "react-router-dom";

function SecuritySurveillanceSolution(){
    return(
        <div>
            <div id="category_page" >
<div id="category_home_div">
<div class="category_home_Detail_div">
<h1>Creating a Secure Future: MAPL Security and Surveillance Solutions </h1>
<p><b>Empowering Safety, Ensuring Security</b>
<br/>
At MAPL, we go beyond conventional security. Our comprehensive Security and Surveillance solutions redefine safety, providing a shield for businesses and communities. Explore the world of unparalleled protection.
</p>
<Link to="/contact"><button>Contact us</button></Link>
</div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Mobile Surveillance Units.png" loading="lazy" alt="" />
</div>
<div id="category_scrollDiv">
<h3 style={{color:"white"}} >Our Technology Partners</h3>
<div class="category_scrollImageDiv">
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/DELL - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy"/></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/HP - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy"/></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/POLY LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy"/></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/TEJAS LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy"/></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/BARCO LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy"/></div>
</div>
</div>
<div id="category_about_div">
<h3>Our Security and Surveillance Solutions</h3>
<div id="category_about_TwoDiv">
<section>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Advanced CCTV Surveillance.png" loading="lazy" alt="" />
<div id="divcolumns">
<h4>Advanced CCTV Surveillance</h4>
<p>MAPL's CCTV solutions redefine surveillance with cutting-edge technology. Our cameras offer high-definition video capture, advanced analytics, and seamless integration. From basic monitoring to sophisticated analytics, we provide a range of CCTV solutions tailored to your security needs.</p>
</div>
</section>
<section>
<div id="divcolumns">
<h4>Access Control Systems</h4>
<p>Safeguard your premises with MAPL's Access Control Systems. Our solutions range from traditional card access to biometric recognition, ensuring only authorized individuals gain entry. Manage and monitor access points seamlessly, enhancing security without compromising convenience.</p>
</div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Access Control Systems.png" loading="lazy" alt="" />
</section>
<section>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Intrusion Detection and Prevention.png" loading="lazy" alt=""  />
<div id="divcolumns">
<h4>Intrusion Detection and Prevention</h4>
<p>Prevent unauthorized access with MAPL's Intrusion Detection Systems. Our solutions combine sensors, alarms, and real-time monitoring to detect and deter intruders swiftly. Ensure the security of your property with our robust intrusion prevention measures.</p>
</div>
</section>
<section>
<div id="divcolumns">
<h4>Video Analytics for Intelligent Insights</h4>
<p>Harness the power of data with MAPL's Video Analytics Solutions. Our systems go beyond mere video recording, offering intelligent insights. Facial recognition, object detection, and behavioral analysis contribute to enhanced situational awareness and proactive security measures.</p>
</div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Video Analytics for Intelligent Insights.png"loading="lazy" alt="" />
</section>
<section>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Perimeter Security Solutions.png" loading="lazy" alt="" />
<div id="divcolumns">
<h4>Perimeter Security Solutions</h4>
<p>Define your boundaries with MAPL's Perimeter Security Solutions. From robust fencing to advanced sensors, we create a comprehensive perimeter security infrastructure. Monitor and secure your premises effectively, providing an additional layer of protection.</p>
</div>
</section>
<section>
<div id="divcolumns">
<h4>Security Information and Event Management (SIEM)</h4>
<p>Stay ahead of potential threats with MAPL's SIEM solutions. Our systems aggregate and analyze security data from various sources, offering real-time insights into security events. Proactively identify and mitigate security risks with our SIEM solutions.</p>
</div> 
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Security Information and Event Management (SIEM).png"loading="lazy" alt="" />
</section>
<section>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Mobile Surveillance Units.png" loading="lazy" alt="" />
<div id="divcolumns">
<h4>Mobile Surveillance Units</h4>
<p>Wherever your security needs take you, MAPL's Mobile Surveillance Units follow. These portable, self-contained units provide on-the-go surveillance for events, construction sites, or temporary installations. Stay secure with flexible and mobile surveillance solutions.</p>
</div>
</section>
<section>
<div id="divcolumns">
<h4>Emergency Communication Systems</h4>
<p>In times of crisis, effective communication is paramount. MAPL's Emergency Communication Systems ensure seamless communication during emergencies. Integrated with security systems, they facilitate swift response and coordination.</p>
</div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Emergency Communication Systems.png" loading="lazy" alt="" />
</section>
<section>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Cloud-Based Security Solutions.png" loading="lazy" alt="" />
<div id="divcolumns">
<h4>Cloud-Based Security Solutions</h4>
<p>Embrace the flexibility of the cloud with MAPL's Cloud-Based Security Solutions. Monitor and manage your security infrastructure remotely, ensuring real-time access and control. Scalable and secure, our cloud solutions offer efficiency without compromising on security.</p>
<p>MAPL's Security and Surveillance Solutions are not just products; they are a comprehensive approach to ensuring safety, security, and peace of mind. From innovative technologies to tried-and-tested solutions, MAPL brings a holistic perspective to security, making us your trusted partner in safeguarding what matters most.</p>
</div>
</section>
</div>
<br/>
<h3>Why Choose MAPL for Security and Surveillance?</h3>
<div id="category_about_OneDiv2">
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Expertise Across Diverse Sectors.png" loading="lazy" alt="" />
</div>
<section>
<h4>Expertise Across Diverse Sectors</h4>
<br/>
<small>MAPL brings a wealth of experience to the table, catering to the security needs of diverse sectors. Whether safeguarding corporate offices, industrial complexes, or public spaces, our expertise ensures tailored security solutions that meet specific industry requirements.</small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Customization for Scalability.png" loading="lazy" alt="" />
</div>
<section>
<h4>Customization for Scalability</h4>
<br/>
<small>One size does not fit all. MAPL understands that security needs to evolve. Our solutions are scalable, seamlessly adapting to the changing needs of your business or community. From small-scale installations to expansive surveillance networks, MAPL provides a flexible and scalable security infrastructure.</small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/24_7 Monitoring and Support.png" loading="lazy" alt="" />
</div>
<section>
<h4>24/7 Monitoring and Support</h4>
<br/>
<small>Security doesn't take a break, and neither do we. MAPL's dedicated support team ensures round-the-clock monitoring and swift response to any security incidents. Our commitment to vigilance offers you peace of mind, knowing that your security is our top priority.</small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Innovation for Future-Proofing.png" loading="lazy" alt="" />
</div>
<section>
<h4>Innovation for Future-Proofing</h4>
<br/>
<small>In a rapidly evolving technological landscape, staying ahead is crucial. MAPL's commitment to innovation ensures that your security infrastructure is future-proof. We continuously integrate the latest technologies, keeping your security solutions one step ahead of emerging threats.</small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Compliance and Regulations.png" loading="lazy" alt="" />
</div>
<section>
<h4>Compliance and Regulations</h4>
<br/>
<small>Navigating the complex landscape of security regulations is challenging. MAPL takes the burden off your shoulders by ensuring that our security solutions align with industry standards and regulations. Stay compliant and protect your business from potential liabilities with MAPL's commitment to legal and regulatory adherence.</small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Seamless Integration.png" loading="lazy" alt="" />
</div>
<section>
<h4>Seamless Integration</h4>
<br/>
<small>Your existing infrastructure matters. MAPL specializes in seamless integrations, ensuring that our security solutions work harmoniously with your current systems. Our approach minimizes disruptions, allowing for a smooth transition to advanced security measures.</small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Focus on Privacy.png" loading="lazy" alt="" />
</div>
<section>
<h4>Focus on Privacy </h4>
<br/>
<small>Privacy is paramount. MAPL prioritizes the protection of sensitive information. Our security systems adhere to strict privacy guidelines, employing advanced encryption and access controls to safeguard privacy while ensuring robust security.</small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Comprehensive Video Analytics.png" loading="lazy" alt="" />
</div>
<section>
<h4>Comprehensive Video Analytics</h4>
<br/>
<small>Understanding your environment is key to effective security. MAPL's Video Analytics Solutions provide advanced insights, including facial recognition, object detection, and anomaly identification. Enhance situational awareness and make informed decisions with our comprehensive analytics.</small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/Creating a Secure Future MAPL Security and Surveillance Solutions/Swift Incident Response.png" loading="lazy" alt="" />
</div>
<section>
<h4>Swift Incident Response</h4>
<br/>
<small>In the event of a security incident, time is critical. MAPL's 24/7 support team ensures rapid response times, tailoring actions to the urgency of the situation. Rest assured that MAPL is committed to swift and effective incident resolution.</small>
</section>
</div>
</div>
</div>
<div>
<h3>FAQs</h3>
<div id="FAQ_DIV">
<div>
<details>
<summary> <span>Q.  Why should I choose MAPL for security and surveillance solutions?</span>&#10010;</summary>
<p> MAPL combines cutting-edge technology with extensive industry expertise, offering comprehensive security solutions tailored to your unique needs. Our commitment to innovation, reliability, and customer satisfaction sets us apart.</p>
</details>
<details>
<summary><span>Q.  What types of CCTV solutions does MAPL provide?</span>&#10010;</summary>
<p>MAPL provides a diverse range of CCTV solutions, including high-definition cameras, advanced analytics, and seamless integration options. From basic monitoring to intelligent video analytics, we cater to various security requirements. </p>
</details>
<details>
<summary> <span>Q.  How does MAPL ensure access control without compromising convenience?</span>&#10010;</summary>
<p> Our Access Control Systems strike the perfect balance between security and convenience. From traditional card access to advanced biometric recognition, we design systems that are both secure and user-friendly.</p>
</details>
<details>
<summary><span>Q. What is the significance of Video Analytics in security solutions? </span>&#10010;</summary>
<p>MAPL's Video Analytics Solutions offer intelligent insights, including facial recognition, object detection, and behavioral analysis. This ensures proactive security measures, enhancing situational awareness and threat identification. </p>
</details>
<details>
<summary> <span>Q.  Can MAPL's security solutions be customized for specific needs?</span>&#10010;</summary>
<p> Absolutely. MAPL specializes in tailoring security solutions to meet specific requirements. Whether it's for residential, commercial, or industrial needs, our solutions are scalable and adaptable.</p>
</details>
<details>
<summary> <span>Q. How effective are MAPL's Perimeter Security Solutions?</span>&#10010;</summary>
<p>MAPL's Perimeter Security Solutions provide a robust infrastructure with advanced fencing and sensor technologies. They effectively monitor and secure premises, adding an extra layer of protection.</p>
</details>
<details>
<summary> <span>Q. What role does SIEM play in enhancing security?</span>&#10010;</summary>
<p>Security Information and Event Management (SIEM) from MAPL aggregates and analyzes security data in real time. This proactive approach helps identify and mitigate security risks promptly. </p>
</details>
<details>
<summary> <span>Q. Are Mobile Surveillance Units suitable for temporary installations?</span>&#10010;</summary>
<p>Yes, MAPL's Mobile Surveillance Units are designed for flexibility. They are ideal for events, construction sites, or temporary installations, providing on-the-go surveillance solutions.</p>
</details>
<details>
<summary> <span>Q.  How does MAPL ensure efficient License Plate Recognition (LPR)?</span>&#10010;</summary>
<p>Our LPR systems are designed for accuracy and efficiency. They enhance access control and tracking, making them suitable for parking management, secure entry points, and law enforcement applications. </p>
</details>
<details>
<summary> <span>Q. Can I manage security infrastructure remotely with MAPL's Cloud-Based Solutions?</span>&#10010;</summary>
<p> Yes, MAPL's Cloud-Based Security Solutions offer remote monitoring and management. They provide real-time access and control, ensuring scalability and efficiency without compromising security.</p>
</details>
</div>
<img src="MAPL IMAGES/Faqimage_Mapl.png" loading="lazy" alt="" />
</div>
</div>
</div>
        </div>
    )
}
export default SecuritySurveillanceSolution;