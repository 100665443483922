import { Link } from 'react-router-dom';

import React from 'react';
import './about.css';
function About(){
    return(
        <div>
            <div id="AboutPage">
<div id="About_FirstDiv">
</div>
<div id="Ourcompany_div">
<section>
<h4>About us</h4><br/>
<p>Discover MAPL where brilliance meets innovation in integrated IT infrastructure solutions. As one of India's leading IT and TELECOM solutions providers, we've established ourselves as trailblazers in envisioning and delivering exceptional IT infrastructure across diverse industry verticals.
We transcend the boundaries of a typical technology partner, going beyond to become your ultimate "Customer Experience Partner." From unlocking the hidden potential to conquering the Indian Subcontinent, we redefine possibilities.
</p>
<br/>
<Link to="/faq"><button>Read More</button></Link>

</section>
<div>
<img src="https://static.wixstatic.com/media/078202_645f42eccc5a413cb0140c21927387d7~mv2.jpg/v1/fill/w_640,h_434,al_b,q_80,usm_0.66_1.00_0.01,enc_auto/078202_645f42eccc5a413cb0140c21927387d7~mv2.jpg" loading="lazy" alt="" />
</div>
</div>
<div id="main_h3heading_div">
<h3 id="missionheading">Mission</h3>
<h8 id="mainpragrap"> Visionary Perspective: Shaping Tomorrow's Technological Landscape</h8>
</div>
<div id="ourMission_div">

<div>
<img src="MAPL IMAGES/ABOUT/20.png" loading="lazy" alt=""/>
<section>
    <h4>Reliability & Scalability</h4>
    <p>The foundation of our solutions lies in the pillars of reliability and scalability. We ensure robust infrastructure, ensuring seamless operations in demanding environments. </p>
    </section>
</div>
<div>
<img src="MAPL IMAGES/ABOUT/21.png" loading="lazy" alt=""/>
  <section>  <h4>Visionary Mission</h4>
    <p> Our mission is to use technology to shape a future where connectivity, efficiency, and reliability come together. We create tailored tech solutions, streamline operations, and support growth for governments and businesses.</p> </section>
</div>
<div>
<img src="MAPL IMAGES/ABOUT/22.png" loading="lazy" alt=""/>
   <section> <h4>Innovation & Pioneering</h4>
    <p>External environmental elements that present option to adjust your business & implement strategy to increase profitability. </p> </section>
</div>
<div>
<img src="MAPL IMAGES/ABOUT/23.png" loading="lazy" alt=""/>
   <section> <h4>Global Connectivity</h4>
    <p> In our vision of a globally connected future, Our solutions transcend boundaries, bridging the digital divide. By fostering connectivity across regions, We ensure that technology is accessible to everyone.</p> </section>
</div>
</div>
<div id="main_h3heading_div">
<h3>Our Values</h3>
<span id="mainpragrap"> Visionary Perspective: Shaping Tomorrow's Technological Landscape</span>
</div>
<div id="AboutpageValue">
<span id="CeoTalksVideosDiv">


<section>
<span>
<iframe src="https://www.youtube.com/embed/69GVW7CXDWE?si=JXgEVvOv3dDxaTPT&controls=0?autoplay=1&mute=1" allow='autoplay; encrypted-media'frameborder="0" allowfullscreen></iframe>
<h6>By leveraging resourcefulness trust, and strong relationships, we have not only overcome adversity but also transformed it into enduring  partnerships and innovation.</h6>
</span>
<span>
<iframe src="https://www.youtube.com/embed/5mdcUXsrhdA?si=fN2_DmIMu4b6oC-k&controls=0?autoplay=1&mute=1" allow='autoplay; encrypted-media'frameborder="0" allowfullscreen ></iframe>
<h6>Millennium's computing and defense technology innovaton safeguards 21 Air Force bases in india, exemlifying our commitment to national security.</h6>
</span>
</section>

</span>
<span>
<div>
<img src="MAPL IMAGES/aboutimage5.png" loading="lazy" alt="" />
<section> <h5>Ethical Foundation </h5>
<p>We operate with integrity, transparency, and fairness, building trust and enduring relationships.</p></section>
</div>
<div>
<img src="MAPL IMAGES/aboutimage6.png" loading="lazy" alt="" />
<section><h5> Commitment</h5>
<p>Our dedication to your success is unwavering. We tailor solutions to your needs, going above and beyond to exceed expectations.</p></section>
</div>
<div>
<img src="MAPL IMAGES/aboutimage7.png" loading="lazy" alt="" />
<section> <h5> Perfection</h5>
<p>We strive for excellence in every aspect. Meticulous planning and precision execution ensure exceptional outcomes.</p></section>
</div>
<div>
<img src="MAPL IMAGES/aboutimage8.png" loading="lazy" alt="" />
<section> <h5> Loyalty</h5>
<p>Your success is our priority. We stand by your side as trusted partners, working together to achieve mutual growth.</p></section>
</div>

</span>
</div>
<div id="main_h3heading_div">
<h3>WHAT WE DO ?</h3>
<span id="mainpragrap"> Delivering excellence through partnerships.</span>
</div>
<div id="whatDO_WE_DIV">
<div id="category_about_box" >
<div>
<img src="MAPL IMAGES/ABOUT/Unleash Limitless Potential, Redefine IT Excellence -IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
</div>
<section>
<h4>Unleash Limitless Potential, Redefine IT Excellence</h4>
<br/>
<small>Ignite innovation and redefine excellence as we unleash limitless potential, setting new benchmarks in IT excellence for a dynamic and thriving future.</small>
</section>
</div>
<div id="category_about_box" >
<div>
<img src="MAPL IMAGES/ABOUT/Harnessing the Power of Connectivity and Collaboration.jpg" loading="lazy" alt="" />
</div>
<section>
<h4>Harnessing the Power of Connectivity and Collaboration
</h4>
<br/>
<small>We power your success by harnessing the dynamic synergy of connectivity and collaboration, ensuring your ventures transcend boundaries and thrive in a connected world.
</small>
</section>
</div>
<div id="category_about_box" >
<div>
<img src="MAPL IMAGES/ABOUT/Future-Proofing Your Success - IT INFRASTRUCTURE AND TECHNOLOGY EXPERT - MAPL WORLD - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" />
</div>
<section>
<h4>Future-Proofing Your Success
</h4>
<br/>
<small>Future-proof your success with us - where strategic foresight meets cutting-edge technology, ensuring your endeavors remain ahead of the curve and ready for tomorrow's challenges
</small>
</section>
</div>
</div>
</div>
        </div>
    )
}
export default About;