import './service.css';
import React from 'react';
function EnterpriseManagementService(){
    return(
<div>
<div id="Services_page">
<div id="ServiceHome_div">

</div>
<div id="ServiceHome_Detail">
    <h2>Enterprise Management with  MAPL</h2>
    <h4>Think Smarter. Deliver Faster.</h4>
    <p>In a landscape of growing competition and advancing technology, elevating business operations' energy and efficiency is paramount. MAPL stands as your partner in transformation, bringing innovation and sustainability to the forefront of your enterprise.</p>
    </div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/Cloud Security Safeguarding Your Digital Frontier/Managed Services_ Elevating Your Cloud Experience - - Millennium Automation Pvt Ltd (MAPL).png" loading="lazy" alt="" /></div>
<section class="ServiceAboutright_section">
<h4>Application Development and Management</h4>
<p>At MAPL, we recognize that modern brands are defined by their apps. With MAPL, you can not only develop cutting-edge applications but also strategically manage them to create the customer experiences that drive business value.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Digital Core</h4>
<p>MAPL's Digital Core isn't just a technological shift; it's a business opportunity for enduring value. Our next-generation platform is simple, comprehensive, and evolves with your growing needs. Take the transformative journey to a renewable enterprise, from strategy to outcomes, and from the edge to the core.</p>
</section>
<div><img src="MAPL IMAGES/Enterprise Service Management (ESM) (1)/Digital Core.png" loading="lazy" alt="" /></div>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/Enterprise Service Management (ESM) (1)/Digital Workplace.png" loading="lazy" alt="" /></div>
<section class="ServiceAboutright_section">
<h4>Digital Workplace</h4>
<p> Employee satisfaction and productivity are paramount for business success. MAPL understands the importance of a fantastic employee experience. We collaborate with you to ensure that this experience permeates every aspect of your digital workplace, translating into exceptional business results.</p>
</section>
</span>
</div>"
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Enterprise Service Management (ESM)</h4>
<p>Improving service quality in today's complex business environment requires a comprehensive approach. MAPL specializes in Enterprise Service Management (ESM), offering solutions to rein in the complexities and enhance the quality of your enterprise services.</p>
</section>
<div><img src="MAPL IMAGES/Enterprise Service Management (ESM) (1)/Enterprise Service Management (ESM).png" loading="lazy" alt="" /></div>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/Enterprise Service Management (ESM) (1)/Enterprise Transformation.png" loading="lazy" alt="" /></div>
<section class="ServiceAboutright_section">
<h4>Enterprise Transformation</h4>
<p> MAPL is your partner in transforming happen. We guide you in becoming more agile, resilient, relevant, and sustainable. Our approach involves a purpose-led strategy, data-driven business processes, a focus on employee experience, intelligent HR, and a culture that fosters success, all supported by an enabling technology landscape.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Intelligent Business Operations</h4>
<p> MAPL's Intelligent Business Operations aim to create a Frictionless Enterprise. Seamlessly connecting processes and people, and intelligently addressing points of friction, we empower your business operations to flow smoothly. Experience the transformation with MAPL and achieve a new level of efficiency in your enterprise.</p>
</section>
<div><img src="MAPL IMAGES/Enterprise Service Management (ESM) (1)/Intelligent Business Operations.png" loading="lazy" alt="" /></div>
</span>
</div>
<div id="ServiceThird_div">
<img src="MAPL IMAGES/Enterprise Service Management (ESM) (1)/Application Development and Management.png" loading="lazy" alt="" />
<div>
<p>At MAPL, we don't just offer services; we provide tailored solutions that propel your enterprise forward. Whether it's app development, digital transformation, or optimizing your workplace, choose MAPL for a journey toward sustainable and impactful business outcomes.</p>
</div>
</div>
</div>
</div>

    )
}
export default EnterpriseManagementService;