import './service.css';
import React from 'react';
function CloudService(){
    return(
<div>
<div id="Services_page">
<div id="ServiceHome_div">

</div>
<div id="ServiceHome_Detail">
    <h2>MAPL CLOUD SERVICES</h2>
    <h4>Start Your Journey to Innovation</h4>
    <p>Embark on a transformative adventure with MAPL's Cloud services. We redefine business transformation, offering an exhilarating experience for your entire organization. The real excitement unfolds as you reach your cloud destination, a world where possibilities are limitless, and innovation knows no bounds.
    </p>
    </div>
    <br/>


<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/MAPL CLOUD SERVICES/Cloud for Enterprise Management_ Redefining Operational Efficiency.png" loading="lazy" alt="" fetchpriority="auto"/></div>
<section class="ServiceAboutright_section">
<h4>End-to-End Cloud Services</h4>
<p>MAPL seamlessly integrates end-to-end cloud services with sector-specific expertise, leveraging advanced cloud technologies and global resources. Our commitment is to guide your cloud journey at every step, ensuring a tailored approach that aligns with your unique business objectives.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Accelerate Your Cloud Journey with MAPL</h4>
<p>Our robust partnerships with the world's leading hyper scalers empower us to harness cutting-edge innovations, accelerating your digital transformation. With MAPL, your technology platform becomes the catalyst for driving meaningful and sustainable change in your organization.

</p>
</section>
<div><img src="MAPL IMAGES/MAPL CLOUD SERVICES/Cloud for Enterprise Management_ Redefining Operational Efficiency (2).png" loading="lazy" alt="" fetchpriority="auto"/></div>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/MAPL CLOUD SERVICES/Accelerate Your Cloud Journey with MAPL.png" loading="lazy" alt="" fetchpriority="auto"/></div>
<section class="ServiceAboutright_section">
<h4>Cloud for Customer First: Elevating Customer Experiences</h4>
<p>MAPL's Cloud services prioritize customer-centric solutions, ensuring your business puts customers first. From enhanced engagement to personalized experiences, we tailor cloud solutions to elevate your customer interactions.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Cloud for Enterprise Management: Redefining Operational Efficiency</h4>
<p>Experience operational excellence with MAPL's Cloud for Enterprise Management. We optimize core functions, enhance efficiency, and provide a smart, frictionless environment for your enterprise to thrive.</p>
</section>
<div><img src="MAPL IMAGES/MAPL CLOUD SERVICES/Cloud for Customer First_ Elevating Customer Experiences.png" loading="lazy" alt="" fetchpriority="auto"/></div>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/MAPL CLOUD SERVICES/End-to-End Cloud Services.png" loading="lazy" alt="" fetchpriority="auto"/></div>
<section class="ServiceAboutright_section">
<h4>Cloud for Intelligent Industry: Transforming Industries with Smart Solutions</h4>
<p>MAPL revolutionizes industries through intelligent cloud solutions. From data-driven insights to streamlined processes, our Cloud for Intelligent Industry services drive innovation, efficiency, and competitiveness.</p>
</section>
</span>
</div>

</div>
</div>

    )
}
export default  CloudService;