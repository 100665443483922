import './service.css';
import React from 'react';
function IntelligentServices(){
    return(
        <div>
            <div id="Services_page">
<div id="ServiceHome_div">

</div>
<div id="ServiceHome_Detail">
    <h2>Intelligent Industry Solutions with MAPL</h2>
    <h4>Embracing the Era of Digital Transformation</h4>
    <p>MAPL welcomes you to the era of 'Intelligent Industry,' a transformative phase characterized by the convergence of products, software, data, and services across all industries. This new digital landscape is powered by rapid advancements in technologies such as cloud computing, artificial intelligence (AI), the internet of Things (IoT), edge computing, and 5G. The synergy of the physical and virtual worlds is reshaping customer interactions, business operations, R&D, engineering, manufacturing, and supply chains.
    </p>
    </div>
    <br/>
<h3 >Key Offerings in Intelligent Industry by MAPL</h3>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/Intelligent Industry Service with MAPL/Intelligent Products and Services.png" loading="lazy" alt="" /></div>
<section class="ServiceAboutright_section">
<h4>Intelligent Products and Services</h4>
<p>Empower your products with intelligence. MAPL enhances connectivity, leveraging technologies like 5G and advanced data intelligence to enable hyper-personalized, smart, and connected products. This not only increases uptime and efficiency but also opens avenues for new revenue streams through innovative servitization models.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Intelligent Operations</h4>
<p>Rethink your operations with the Intelligent Industry. MAPL utilizes emerging technologies and data to revolutionize operations, transcending traditional boundaries. Connected systems improve efficiency, reduce costs, and set the stage for innovative servitization models that go beyond supply chain and manufacturing activities.</p>
</section>
<div><img src="MAPL IMAGES/Intelligent Industry Service with MAPL/Intelligent Operations.png" loading="lazy" alt="" /></div>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/Intelligent Industry Service with MAPL/Digital Continuity and Convergence.png" loading="lazy" alt="" /></div>
<section class="ServiceAboutright_section">
<h4>Digital Continuity and Convergence</h4>
<p>Unleash the potential of disruptive innovation and smart connected products with MAPL's expertise in digital continuity and convergence. We ensure the continuous flow of complete information, enabling the digitization of core processes and the creation and management of the virtual world. This paves the way for smart design, superior manufacturing operations.</p>
</section>
</span>
</div>
<div id="ServiceThird_div">
<img src="MAPL IMAGES/Intelligent Industry Service with MAPL/1.png" loading="lazy" alt="" />
<div>
<h4>Trustworthy Cybersecurity for Business Transformation</h4>
<p>MAPL understands that cybersecurity is not just a protective measure; it's a strategic enabler. With our services, you can accelerate your business transformation, confident in the security measures that underpin your success.</p>
</div>
</div>
<div id="ServiceThird_div">
<div>
<h4>Choose MAPL for Cybersecurity You Can Trust.</h4>
<p>Empower your business with a cybersecurity partner that transforms challenges into opportunities, securing your digital future with confidence.</p>
</div>
<img src="MAPL IMAGES/Intelligent Industry Service with MAPL/Welcome to the Future of Intelligent Industry with MAPL!.png" loading="lazy" alt="" />
</div>
</div>
        </div>
    )
}
export default IntelligentServices;