import './contact.css';

import React from 'react';
function Contact(){
    return(

        <div>
             <div id="Contactpage">
        <div id="ContactfirstDIV">
            <div>
            <h1>How can we help you today?</h1>
      
        </div>
        </div>

        <div id="ContactsecondDIV">
         <div><i class="fa">&#xf1d7;</i>
        <h5>Chat online with us </h5>
        <p>Connect with us online! Chat live Monday to Saturday, 9 am to 6 pm ET.</p>
        <a href="https://wa.me/9311240651">Start chat</a>
        </div>
      
        <div><i class="material-icons">&#xe0b0;</i>
            <h5>Request a call back</h5>
            <p>Need assistance? Request a call back. Our team is available Monday to Saturday, 9 am to 6 pm ET.</p>
            <a href="tel:9311240651">Go to Call</a>
            </div>
        </div>
<h3>Contact us</h3>

        <div id="Contactthirddiv">  
            <div id="Contactdiv_BranchDetails"> 
                <div> 
            <h4>CORPORATE OFFICE</h4>
            <p>Millennium Automation Private Limited
                <br/>
            E-48/9, Okhla Industrial Area, Phase-II,
           <br/> New Delhi 110020</p>
           
        </div>
        <div>
            <a href="info@maplworld.com">Email : info@maplworld.com</a>
            <a href="tel:+91 9311240651">Mobile : +91 9311240651</a>
            <a href="tel:+91 1126387915">Landline : +91 1126387915/16</a>
            <a href="tel:+91 1126387918">Fax No.: +91 1126387918</a> 
        </div>    
                    </div>
             
            
            <div id="contactform_div">
                <h4>Contact</h4>
                <form action='javascript:sendEmail()'>
 
                    <input type="text" placeholder="NAME :" name="Name:" id="footerinputname" required />
                    <input type="email" placeholder="EMAIL :" name="Email:" id="footerinputemail" required />
                    <input type="text" placeholder="MESSAGE :" name="Message:" id="footerinputmessage" required />
                  
                    <button id="sendcontactform">Send Message</button>
                </form>
              
            </div>
            </div>
    <div class="contactMap_div">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.112841543054!2d77.2685316!3d28.536327800000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce32f2b34989f%3A0xf3bed9e5bb949db8!2sMillennium%20Automation%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1700805141757!5m2!1sen!2sin" frameborder="0" allowfullscreen="" id="IframeMAPL_Map"></iframe>
        <a href="https://maps.app.goo.gl/yRYwU1ezHn2XVgLv7" onclick="getLocation()"><button>Go to Map</button></a>
    </div>
         
           
            <h3>Branch office</h3>
            <div id="Contactdiv_BranchOfficesimgs">  

                <div class="BranchesDiv"  id="BranchesDivfirst">
                <div class="BranchesblendDivimges">
                <h5>Mumbai</h5>
                </div>
                </div>
                <div class="BranchesDiv" id="BranchesDivsecond">
                <div class="BranchesblendDivimges">
                <h5>Bengaluru</h5>
                </div>
                </div>
                <div class="BranchesDiv" id="BranchesDivthird">
                <div class="BranchesblendDivimges">
                <h5>
                    Ahmedabad</h5>
                </div>
                </div>
                <div class="BranchesDiv" id="BranchesDivfour">
                <div class="BranchesblendDivimges">
                <h5>Mohali</h5>
                </div>
                </div>
                <div class="BranchesDiv" id="BranchesDivfive">
                <div class="BranchesblendDivimges">
                <h5>Ranchi</h5>
                </div>
                </div>
                <div class="BranchesDiv" id="BranchesDivsix">
                <div class="BranchesblendDivimges">
                <h5>Singapore</h5>
                </div>
                </div>
                
                            </div>

                            <h3>FAQs</h3>
                            <div id="FAQ_DIV">

<div>
    <details>
        <summary> <span>Q. What services does MAPL Cloud Solutions offer?</span>&#10010;</summary>
        <p>MAPL Cloud Solutions offers a comprehensive suite of Cloud offerings, including migration and deployment services, managed services, cloud security solutions, and expert consulting.</p>
    </details>

    <details>
        <summary><span>Q. Can MAPL Cloud Solutions help with seamless migration and deployment?</span>&#10010;</summary>
        <p>Yes, MAPL Cloud Solutions specializes in seamless migration and deployment, ensuring a smooth transition to the cloud for your business.</p>
    </details>

    <details>
        <summary> <span>Q. How can MAPL Cloud Solutions optimize my business through managed services?</span>&#10010;</summary>
        <p>MAPL Cloud Solutions offers managed services to optimize your business by taking care of day-to-day cloud operations, monitoring, and maintenance, allowing you to focus on core business activities.</p>
    </details>

    <details>
        <summary><span>Q. How does MAPL Cloud Solutions fortify cloud security?</span>&#10010;</summary>
        <p>MAPL Cloud Solutions provides robust cloud security solutions to protect your data and applications in the cloud, ensuring compliance and mitigating security risks.</p>
    </details>

    <details>
        <summary> <span>Q. How can MAPL Cloud Solutions provide expert consulting?</span>&#10010;</summary>
        <p>MAPL Cloud Solutions offers expert consulting services to help you strategize and make informed decisions about your cloud journey, ensuring maximum efficiency and ROI.</p>
    </details>
</div>

</div>

    </div>
        </div>
    )
}
export default Contact;