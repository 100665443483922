import { Link } from "react-router-dom";
import React from 'react';
function SupplyChainManagementJob(){
return(
    <div>
        <div id="jobpage">
    <div id="jobhomediv">
        <h2>Supply Chain Management</h2>
    </div>
<div id="Jopdescription">
<section>
<div>
<h2>Supply Chain Management</h2>
<p> <i class="material-icons" style={{color:"#00437d",display:"inline"}}> &#xe568;</i>Okhla
&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa" style={{color:"#00437d",display:"inline",fontSize:"22px"}}> &#xf017;</i> Full Time
</p> </div>
<div>
<h4>Job Description</h4>
<br/>
<ul>
<li>Create and maintain the company's supply chain strategy.</li>
<li>Identify and Onboard new Vendors.</li>
<li>Negotiate with the new vendors.</li>
<li>Generate Purchase Orders.</li>
<li>Handle end to end documentations ,related to billing, shipment dispatch and delivery. </li>
<li>Analyze data from shipping and delivering processes to find bottlenecks and other issues</li>
<li>Maintain supply chain inventory and records</li>
<li>Find cost-effective solutions for supply chain processes</li>
<li>Resolve issues that come up (e.g. delays in delivery, accidents)</li>
<li>Coordination with Logistics partners for timely dispatch and delivery</li>
<li>Develop and implement safety guidelines in all aspects of the supply chain (e.g. use of trucks, forklifts)</li>
<li>Ensure supply chain processes meet legal requirements and standards</li>
<li>Communicate and negotiate with suppliers and vendors to land more profitable deals.</li>
<br/>
<h4>Required Skills</h4>
<br/>
<li>Previous experience as a supply chain management.</li>
<li>Working experience of relevant software (e.g. SAP MM)</li>
<li>Strong communication skills</li>
<li>Excellent organizational and project management skills</li>
<li>strategic and analytical thinker</li>
</ul>
</div>
</section>
<section>
<div>
<img src="MAPL IMAGES/JOBS - MAPL WORLD/SUPPLY CHAIN MANAGEMENT  - MILLENNIUM AUTOMATION PVT LTD (MAPL).png" loading="lazy" alt=""/>
<label for="resumegetinput" onclick="uploadresume()" id="uploadresumeid">Upload Resume</label>
<Link to="mailto:hr@maplworld.com?subject=Job Application with Resume"><button id="applynowjob">Apply now</button></Link> 
</div>
<div>
<p><b>Employment Status : </b> Full-time</p>
<p><b>Experience : </b>2-8 years </p>
<p><b>Job Location : </b> Okhla</p>
</div>
<div>
<h4>HR Manager</h4>
<p>
    Millennium Automation Private Limited </p>
   <p> E 48/9,Okhla Industrial Area, Phase-II, </p>
  <p>  New Delhi 110020</p>
<p>hr@maplworld.com</p>
<p>+91-98710 08021</p>
</div>
</section>
</div>
<div id="getresumediv">
<div>
<p>Ready to join our dynamic team? Share your resume; your skills and passion could be the perfect fit.</p>
</div>
<div>

<a href="mailto:hr@maplworld.com?subject=Job Application with Resume"><label id="labelgetinput">Upload Resume</label></a>
</div>
</div>
</div>
    </div>
)
}
export default SupplyChainManagementJob;
