import { Link } from "react-router-dom";
import React from 'react';
function Caseanvfunction(){
    return(
        <div className="navigationscrooldiv">
            <div id="navigation_information_div">
    <h3>Showcases</h3>
    <br/>
    <p>IT Infrastructure Services are expected to make enterprises more focused on service, secure and agile, and with an ability to deliver converged experiences to their clients. Technology trends such as Analytics, Cloud, IoT and Cognitive Computing are creating new business imperatives.</p>
    
    </div>
    <div id="navigation_detailsDIV">
    <h3>Case Studies</h3>
    <div class="navigation_details" id="navigationDeatailscroll">

    <a href="MAPL IMAGES/CASE STUDIES/DELHI POLICE/DELHI POLICE - Design, Supply, Installation, Commissioning and Maintenance of CCTV Cameras in delhi.pdf"><h4><span>Delhi Police</span><i class="material-icons">&#xe315;</i></h4><h6>CCTV Surveillance and safety across state
    </h6> </a>
    <a href="MAPL IMAGES/CASE STUDIES/DRDO/DRDO  Transformative Partnership MAPL revolutionised Defense Research with Innovative Storage Solutions.pdf"><h4><span>DRDO</span><i class="material-icons">&#xe315;</i></h4><h6> Software Defined Storage and Virtualisation
    </h6></a>
    <a href="MAPL IMAGES/CASE STUDIES/DTC/Guardians in Transit  MAPL_s Implemented Advanced Surveillance Solutions in Delhi Transport C.pdf"><h4><span>Delhi E-mobility</span><i class="material-icons">&#xe315;</i></h4><h6>Enhanced Surveillance in Public Buses
    </h6></a>
    <a href="MAPL IMAGES/CASE STUDIES/JAP-IT/MAPL_s Revolutionary Impact on Jharkhand_s Legal System with Advanced Video_20240107_175509_0000.pdf"><h4><span>Transforming State Judiciary </span><i class="material-icons">&#xe315;</i></h4><h6> Advanced Video Conferencing
    </h6></a>
    <a href="MAPL IMAGES/CASE STUDIES/JEPC/Transforming Jharkhands Educational Landscape MAPL_s Visionary Impact with_20240107_175252_0000 (1).pdf"><h4><span>Jharkhand Education Project</span><i class="material-icons">&#xe315;</i></h4><h6>1000 Smart Classrooms</h6></a>
    <a href="MAPL IMAGES/CASE STUDIES/LIMS/Establishment of Lawful Interception and Monitoring System (LIMS) for providing International.pdf"><h4><span>Electronic Surveillance</span><i class="material-icons">&#xe315;</i></h4> <h6>Implementing LIMS for ILD Connectivity</h6></a>
    <a href="MAPL IMAGES/CASE STUDIES/GOVT OF BIHAR - OUTDOOR MOBILE VIDEO CONFERENCING/Supply And Installation Of Outdoor Mobile Video Conferencing Across The State Of Bihar (1).pdf"> <h4><span> Government of Bihar</span><i class="material-icons">&#xe315;</i></h4><h6>Remote Wireless Video Conferencing 
    </h6></a>
    <a href="MAPL IMAGES/CASE STUDIES/GOVT OF UTTARAKHAND/GOVT OF UTTARAKHAND-  ENHANCED  VIDEO CONFERENCING  EXPERIENCE FOR UTTARAKHAND.pdf"><h4><span>Elevating Defense Infrastructure</span> <i class="material-icons">&#xe315;</i></h4><h6>Integrated Air Command and Control</h6></a>
    
    
    </div>
    <Link to="/sitemap" id="navigation_detailsReadBtn">Read more &#x2192;</Link>
    </div>
    <div id="navigation_images">
    <a href=""><img src="MAPL IMAGES/Case studies of MAPL.jpg" alt=""/></a>
    
    </div>
        </div>
    )
    }
export default Caseanvfunction;