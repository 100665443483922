import { Link } from "react-router-dom";
import React from 'react';
function MobileSurveillanceSystem(){
return(
    <div>
        <div id="category_page">
<div id="category_home_div">
<div class="category_home_Detail_div">
<h1>MAPL's Vision: Redefining Security with Mobile Surveillance Systems</h1>
<p><b>Evolution of Surveillance: Embracing Mobility</b>
<br/>
Traditional surveillance systems need more mobility, scalability, and real-time monitoring. Enter Mobile Surveillance Systems, a technological leap offering flexibility, scalability, and mobility, making them ideal for diverse applications.
</p>
<Link to="/contact"><button>Contact us</button></Link>
</div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Real-Time GPS Tracking.png" loading="lazy" alt="" />
</div>
<div id="category_scrollDiv">
<h3 style={{color:"white"}} >Our Technology Partners</h3>
<div class="category_scrollImageDiv">
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/DELL - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/HP - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/POLY LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/TEJAS LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
<div><img src="MAPL IMAGES/OFFICIAL LOGOS PNG/BARCO LOGO - MAPL - MILLENNIUM AUTOMATION PVT LTD.png" loading="lazy" alt="" /></div>
</div>
</div>
<div id="category_about_div">
<div id="category_about_TwoDiv">
<section>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Applications Across Industries.png" loading="lazy" alt="" />
<div>
<h4>Applications Across Industries</h4>
<p>MAPL's Mobile Surveillance System finds applications in various industries</p>
<div id="detailsDiv">
<details>
<summary><h5>&#11166; &nbsp;Transportation</h5></summary>
<p> Enhance passenger safety and monitor transit routes in real-time.</p>
</details>
<details>
<summary><h5>&#11166; &nbsp;Construction</h5></summary>
<p> Prevent theft, vandalism, and accidents while ensuring safety compliance.</p>
</details>
<details>
<summary><h5>&#11166; &nbsp;Event Management</h5></summary>
<p> Provide security and crowd management during large events.</p>
</details>
<details>
<summary><h5>&#11166; &nbsp;Logistics and Fleet Management</h5></summary>
<p> Track vehicle locations, monitor driver behavior, and protect valuable cargo.</p>
</details>
<details>
<summary><h5>&#11166; &nbsp;Law Enforcement</h5></summary>
<p> Equip agencies with mobile surveillance units for evidence collection and situational awareness.</p>
</details>
</div>
</div>
</section>
</div>
<div id="main_h3heading_div">
<h3>Advantages of Mobile Surveillance Systems</h3>
</div>
<div id="category_horizontalDiv">
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Real-Time Monitoring.png" loading="lazy" alt="" />
<section> <h5>Real-Time Monitoring</h5>
<p> Swift response to incidents, reducing security breaches or accidents.</p></section>
</div>
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Scalability.png" loading="lazy" alt="" />
<section><h5>Scalability</h5>
<p> Easily expand infrastructure to cover growing areas or fleets.</p></section>
</div>
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Mobility.png" loading="lazy" alt="" />
<section> <h5>Mobility</h5>
<p> Flexibility in deploying security where it's needed most.
</p></section> 
</div>
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Evidence Collection.png" loading="lazy" alt="" />
<section> <h5> Evidence Collection</h5>
<p> Capture valuable evidence for investigations and accountability.</p></section>
</div>
</div>
<div id="category_about_OneDiv">
<div>
<h5>
Redefine Security and Efficiency with MAPL</h5>
<p>In an interconnected world, MAPL's Mobile Surveillance System represents a visionary solution. Whether securing public transportation, safeguarding construction sites, managing events, monitoring logistics, or enhancing law enforcement capabilities, MAPL's solution offers the flexibility, scalability, and real-time monitoring capabilities required to succeed.</p>
</div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Redefine Security and Efficiency with MAPL.png" loading="lazy" alt="" />
</div>
<h3>MAPL's Mobile Surveillance System with NVR Technology</h3>
<div id="category_about_OneDiv2">
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/High-Definition Video Recording.png" loading="lazy" alt="" />
</div>
<section>
<h4>High-Definition Video Recording</h4>
<br/>
<small>Capture every detail with MAPL's high-definition cameras, ensuring superior image quality. </small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Real-Time Remote Monitoring.png" loading="lazy" alt="" />
</div>
<section>
<h4>Real-Time Remote Monitoring </h4>
<br/>
<small> Empower personnel with real-time remote monitoring from any location with internet access. </small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Intelligent Analytics.png" loading="lazy" alt="" />
</div>
<section>
<h4>Intelligent Analytics </h4>
<br/>
<small> Enhanced with intelligent analytics for motion detection, facial recognition, and license plate recognition. </small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Secure Data Storage mapl.png" loading="lazy" alt="" />
</div>
<section>
<h4>Secure Data Storage</h4>
<br/>
<small>Ensure data integrity with encrypted storage and redundancy options for recovery. </small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Scalability and Modularity mapl.png" loading="lazy" alt="" />
</div>
<section>
<h4>Scalability and Modularity</h4>
<br/>
<small>Easily scale the system with a modular design, adapting to evolving needs. </small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Real-Time GPS Tracking.png" loading="lazy" alt="" />
</div>
<section>
<h4> Real-Time GPS Tracking</h4>
<br/>
<small> Integrate real-time GPS tracking for applications like public transportation and fleet management.</small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Mobile Application mapl.png" loading="lazy" alt="" />
</div>
<section>
<h4>Mobile Application </h4>
<br/>
<small> Access live feeds, receive alerts, and view recorded footage on the go with the user-friendly mobile application.</small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Redundancy and Failover.png" loading="lazy" alt="" />
</div>
<section>
<h4>Redundancy and Failover</h4>
<br/>
<small>Designed for continuous surveillance, the system ensures uninterrupted operation with redundancy and failover mechanisms. </small>
</section>
</div>
<div id="category_about_box">
<div>
<img src="MAPL IMAGES/MAPL's Vision Redefining Security with Mobile Surveillance Systems/Integration and Compatibility.png" loading="lazy" alt="" />
</div>
<section>
<h4>Integration and Compatibility </h4>
<br/>
<small>Seamlessly integrate with existing security infrastructure, access control systems, and third-party applications.</small>
</section>
</div>
</div>
</div>
<div>
<h3>FAQs</h3>
<div id="FAQ_DIV">
<div>
<details>
<summary> <span> How does MAPL ensure a smooth cloud adoption process?</span>&#10010;</summary>
<p>MAPL's cloud consulting services provide a detailed roadmap, ensuring a seamless transition by addressing individual, department-specific, or enterprise-wide requirements.</p>
</details>
<details>
<summary><span>What makes MAPL's cloud migration approach unique?</span>&#10010;</summary>
<p>We offer both standardized migration and customized solutions, ensuring flexibility based on each client's specific needs and making cloud migration efficient and tailored.</p>
</details>
<details>
<summary> <span>How does MAPL handle cybersecurity in the cloud? </span>&#10010;</summary>
<p>Security is a top priority. Our cloud security solutions are customizable, addressing the specific needs of your business while ensuring compliance with regulatory standards.</p>
</details>
<details>
<summary><span>What sets MAPL's managed cloud services apart?</span>&#10010;</summary>
<p>We go beyond routine IT management, focusing on cybersecurity, future-led technology integration, and providing flexibility in service plans for optimal IT support.</p>
<p>Elevate your business to new heights with MAPL Cloud Solutions. Connect with us today and embark on a transformative journey into the digital future.</p>
</details>
</div>
<img src="MAPL IMAGES/Faqimage_Mapl.png" loading="lazy" alt="" />
</div>
</div>
</div>
    </div>
)
}
export default MobileSurveillanceSystem;