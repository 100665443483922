import { Link } from "react-router-dom";
import React from 'react';
function NewAccountsFinance(){
return(
    <div>
        <div id="jobpage">
    <div id="jobhomediv">
        <h2>Accounts and Finance</h2>
    </div>
<div id="Jopdescription">
<section>
<div>
<h2>Accounts and Finance</h2>
<p> <i class="material-icons" style={{color:"#00437d",display:"inline"}}> &#xe568;</i>Okhla
&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa" style={{color:"#00437d",display:"inline",fontSize:"22px"}}> &#xf017;</i> Full Time
</p> </div>
<div>
<h4>Job Description</h4>
<br/>
<ul>
<li>Financial accounting, Auditing forecasting and reporting, across the business. </li>
<li>Identify and understand business challenges, propose and create solutions., </li>
<li>Responsibility for producing accurate and timely management reports, including an analysis of sales, operational expenditure</li>
<li>Support and challenge the sales and operations teams with financial issues including providing financial training.</li>
<br/>
<h4>Required Skills</h4>
<br/>
<li>Qualified Chartered Accountant with more than 2 years of experience in Accounting, financial Planning and Auditing</li>
<li>Experience in developing financial reports and metrics</li>
<li>Manage Overall Accounting, Taxation, Auditing and general book keeping for the Company</li>
<li>Forecast financial results within the broadest scope.for the organisation</li>
<li>Manage and execute audits, including identifying and analyzing risks, planning and prioritizing the audit work, observing operations, documenting and analyzing procedures and controls, performing audit tests, preparing appropriate work papers and developing recommendations</li>
<li>Develop and maintain collaborative working relationships with management, learning and understanding the business to better provide value-added services, establishing credibility as a management consultant and internal controls resource, and ensuring business partner satisfaction</li>
<li>Provide complex and occasionally highly complex financial analysis for a financial function or a business, typically exercising a strong understanding of the business to determine the best method for achieving objectives</li>
<li>Salary is not a constraint for right candidate.</li>
<li>Female candidates with good communication skills</li>

</ul>
</div>
</section>
<section>
<div>
<img src="MAPL IMAGES/JOBS - MAPL WORLD/SUPPLY CHAIN MANAGEMENT  - MILLENNIUM AUTOMATION PVT LTD (MAPL).png" loading="lazy" alt=""/>
<label for="resumegetinput" onclick="uploadresume()" id="uploadresumeid">Upload Resume</label>
<Link to="mailto:hr@maplworld.com?subject=Job Application with Resume"><button id="applynowjob">Apply now</button></Link> 
</div>
<div>
<p><b>Employment Status : </b> Full-time</p>
<p><b>Experience : </b>2-3 years  </p>
<p><b>Job Location : </b> Okhla</p>
</div>
<div>
<h4>HR Manager</h4>
<p>
    Millennium Automation Private Limited </p>
   <p> E 48/9,Okhla Industrial Area, Phase-II, </p>
  <p>  New Delhi 110020</p>
<p>hr@maplworld.com</p>
<p>+91-98710 08021</p>
</div>
</section>
</div>
<div id="getresumediv">
<div>
<p>Ready to join our dynamic team? Share your resume; your skills and passion could be the perfect fit.</p>
</div>
<div>

<a href="mailto:hr@maplworld.com?subject=Job Application with Resume"><label id="labelgetinput">Upload Resume</label></a>
</div>
</div>
</div>
    </div>
)
}
export default NewAccountsFinance;
