import './service.css';
import React from 'react';
function CyberSecurityService(){
    return(
    <div>
        <div id="Services_page">
<div id="ServiceHome_div">

</div>
<div id="ServiceHome_Detail">
    <h2>Cyber Security Services with MAPL</h2>
    <h4>Elevate Your Security, Drive Transformation</h4>
    <p>MAPL does more than mitigate cybersecurity risks; we turn it into a catalyst for change. It's more than avoiding vulnerabilities; it's about giving your approach confidence. We deliver the most elusive element in cybersecurity today: confidence.
    Through our business-focused approach, sector-specific expertise, advanced technology, and thousands of skilled professionals, we provide end-to-end portfolio services.
    </p>
    </div>
    <br/>
    <br/>
<h3 >Comprehensive Cybersecurity Services</h3>
<p id="mainpragrap" >MAPL offers an extensive range of cybersecurity services designed to safeguard your business:</p>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/cyber security/Business-Focused Cybersecurity.png" loading="lazy" alt="" fetchpriority="auto"/></div>
<section class="ServiceAboutright_section">
<h4>Business-Focused Cybersecurity</h4>
<p>Our approach goes beyond technicalities. MAPL focuses on aligning cybersecurity strategies with your overall business goals, ensuring a comprehensive and business-centric security framework.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Sector-Specific Expertise</h4>
<p>Recognizing that different industries have unique cybersecurity challenges, MAPL provides sector-specific expertise. Our tailored solutions address the distinct security needs of your industry.</p>
</section>
<div><img src="MAPL IMAGES/cyber security/1.png" loading="lazy" alt="" fetchpriority="auto"/></div>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/cyber security/Cutting-edge Technology Integration.png" loading="lazy" alt="" fetchpriority="auto"/></div>
<section class="ServiceAboutright_section">
<h4>Cutting-edge Technology Integratione</h4>
<p>Stay ahead in the cybersecurity landscape with MAPL's integration of advanced technologies. From AI-driven threat detection to blockchain security, we deploy the latest tools to fortify your defenses.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Skilled Professionals</h4>
<p>MAPL boasts a team of thousands of skilled cybersecurity professionals. Their expertise spans the entire spectrum of cyber threats, providing you with a dedicated and knowledgeable defense force.</p>
</section>
<div><img src="MAPL IMAGES/cyber security/Skilled Professionals.png" loading="lazy" alt="" fetchpriority="auto"/></div>
</span>
</div>
<div id="ServiceAbout_div" >
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/cyber security/End-to-End Portfolio Services mapl.png" loading="lazy" alt="" fetchpriority="auto"/></div>
<section class="ServiceAboutright_section">
<h4>End-to-End Portfolio Services</h4>
<p>Our cybersecurity services cover every aspect of your digital landscape. From risk assessments and vulnerability management to incident response and compliance, MAPL offers end-to-end portfolio services.</p>
</section>
</span>
</div>
<div id="ServiceThird_div" >
<img src="MAPL IMAGES/cyber security/Trustworthy Cybersecurity for Business Transformation.png" loading="lazy" alt="" fetchpriority="auto"/>
<div>
<h4>Trustworthy Cybersecurity for Business Transformation</h4>
<p>MAPL understands that cybersecurity is not just a protective measure; it's a strategic enabler. With our services, you can accelerate your business transformation, confident in the security measures that underpin your success.</p>
</div>
</div>
<div id="ServiceThird_div" >
<div>
<h4>Choose MAPL for Cybersecurity You Can Trust.</h4>
<p>Empower your business with a cybersecurity partner that transforms challenges into opportunities, securing your digital future with confidence.</p>
</div>
<img src="MAPL IMAGES/cyber security/Choose MAPL for Cybersecurity You Can Trust.png" loading="lazy" alt="" fetchpriority="auto"/>
</div>
</div>
    </div>
    )
}
export default CyberSecurityService;