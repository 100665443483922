import React from 'react';
import { Link } from 'react-router-dom';
function Footer(){
    
    function cookiesopen(){
        document.getElementById('cookies_div').style.bottom="1%";
        }
    var chatviews =`<h6 id="ChatStartedtime">Chat Started at 00:00 AM</h6>`;
    
var chatstartview =` <img src="MAPL IMAGES/maplworld_logo.png">
<h5 id="loadingdiv">
</h5>`;

    function cookiesCancelbtn(){
        localStorage.removeItem("Cookiesname");
        document.getElementById('cookies_div').style.bottom="-400px";
        }
        function cookiesAcceptbtn(){
        localStorage.setItem("Cookiesname","MAPLWORLD.COM");
        document.getElementById('cookies_div').style.bottom="-400px";
        document.cookie = "username=MaplWorld; path=/;";
        }
        function chatboxOpen(){
            getLocation();
            document.getElementById('ChatBox').style.height="500px";
            setTimeout(chatview,2000);
            document.getElementById('chatpage').innerHTML=chatstartview;
        
            }
            function chatview(){
            document.getElementById('chatpage').innerHTML=chatviews;
            document.getElementById('navPid').innerHTML="MAPL Assistant";
            setTimeout(chatgetquestion,1000);
            }
            function chatboxClose(){
            document.getElementById('ChatBox').style.height="0px";
            questionnumber = 0;
            }
            function chatsendbtncheck(){
            var chatcheckinput =document.getElementById('ChatInputValue').value;
            if(chatcheckinput===""){
            document.getElementById('chatsendbtn_id').style.color="red";
            alert("Please' Enter Your Message");
            }else{
            chatsendbtn();
            document.getElementById('chatsendbtn_id').style.color="var(--main-bg-color30)";
            }
            }
            function chatsendbtn(){
            var date = new Date();
            var hour =date.getHours();
            var minutes = date.getMinutes();
            if(hour>12){
            var timesfunction = "PM";
            }else{
            var timesfunction = "AM";
            }
            document.getElementById('ChatStartedtime').innerText ="Chat Started at "+ hour +":"+ minutes+timesfunction;
            var todaytimer=hour +":"+ minutes+timesfunction;
            var chatinputvalue = document.getElementById('ChatInputValue').value;
            var chattime = "<h6>"+"Chat "+todaytimer+"</h6>";
            var spantagvalue= "<span>"+chatinputvalue+"</span>";
            var spantagdiv = "<section>"+chattime+spantagvalue+"</section>";
            var chatpagevar= document.getElementById('chatpage').innerHTML;
            var checkpage = chatpagevar+spantagdiv;
            document.getElementById('chatpage').innerHTML=checkpage;
            document.getElementById('ChatInputValue').value="";
            setTimeout(chatgetquestion,1000);
            }
            var questionnumber = 0;
            function chatgetquestion(){
                if(questionnumber <4){
            var questiontable= document.getElementById('QuestionTable');
            var questionTRs = questiontable.getElementsByTagName('tr')[questionnumber];
            var questionTD = questionTRs.getElementsByTagName('td')[0];
            var questionText= questionTD.innerText;
            var chatpagevar= document.getElementById('chatpage').innerHTML;
           
            var checkpage = chatpagevar+"<div><img src='MAPL IMAGES/5-2-globe-png-image.png'>"+questionText+"</div>";
            document.getElementById('chatpage').innerHTML=checkpage;
            document.getElementById('ChatInputValue').value="";
            
                 questionnumber++
                 if(questionnumber>3){
                  
                    document.getElementById('ChatInputValue').value="OK";
                    setTimeout(chatsendbtncheck,1000);
           
                 } 
                }else{
                   alert("Send your Chats");
                   var chatpagesend =document.getElementById('chatpage').innerText;
               
                   questionnumber=0;
                   chatboxClose();
                
var whatsappsendlink= "mailto:info@maplworld.com?subject=Mapl New Chat&body="+chatpagesend;

                   window.open(whatsappsendlink);
                }
         } 
            
function getLocation(){
   
if (navigator.geolocation) {
navigator.geolocation.getCurrentPosition(showPosition);
} else {
    console.warn("Geolocation is not supported by this browser.");
}
}
function showPosition(position) {
console.warn("Latitude: " + position.coords.latitude +
"<br>Longitude: " + position.coords.longitude);
}
    return(

        <div>

<div id="cookies_div">
<div>
    <p>
Our website uses cookies to give you a great online experience. You are at liberty to turn the cookies on or off any time except strictly necessary cookies. If you wish to manage your cookies, please click on “Manage Cookies”. By continuing to use this website, you are agreeing to our <Link to="/privacy">Privacy</Link> & <Link to="/terms">Terms of service</Link>
</p></div>

<button id="cookiesAcceptbtn" onClick={cookiesAcceptbtn}>Accept All</button>
<button id="cookiesCancelbtn" onClick={cookiesCancelbtn}>Decline All</button>

</div>

<div id="footerGroup">
    <img src="MAPL IMAGES/maplworld white logo.png" id="footerwhitemaplLogo" alt=''/>
    <footer>
    <div class="footerFirstDiv" id="footerDiv">
     
    <h4>About</h4>
    <Link to="/about">Our Story</Link>
    <Link to="/career">Careers</Link>
    <Link to="/legal">Legal</Link>
    <Link to="/sitemap">Sitemap</Link>
  
    <h4>Support</h4>
    <Link to="/faq"><i style={{fontSize:"15px"}} class="fa">&#xf29c;</i> FAQs</Link>
    

    <a href="tel:+91 1126387915"><i style={{fontSize:"15px"}} class="material-icons">&#xe551;</i> +91-1126387915/16</a>
  
    <a href="https://maps.app.goo.gl/GM7nV4GcjVtC6HHVA"><i style={{fontSize:"15px"}} class="material-icons">&#xe0c8;</i> E 48/9, Okhla Industrial Area, 
        <br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Phase-II, New Delhi-110020 </a>
    <a href="info@maplworld.com"><i class="material-icons" style={{fontSize:"15px"}} >&#xe0e1;</i> info@maplworld.com</a>
    
 
    
    <div id="desktopfootericons">
    <a href="https://www.facebook.com/people/Millennium-Automation-Pvt-Ltd/61555239089074/"><i  class="fa">&#xf09a;</i> <p></p></a>
    <a href="https://www.linkedin.com/company/maplworld/"><i  class="fa">&#xf0e1;</i><p></p></a>
    <a href="https://www.youtube.com/@MAPLWorld"><i  class="fa">&#xf16a;</i> <p></p></a>
    <a href="https://www.instagram.com/maplworld/?hl=en"><i  class="fa">&#xf16d;</i><p></p></a>
    <a href="https://twitter.com/MAPLWorld"><i class="fa"><img src="MAPL IMAGES/twitterpngimage.png" loading="lazy" alt="" /></i> <p></p></a>
    
    <a href="https://wa.me/9311240651"><i  class="fa">&#xf232;</i> <p></p></a>
    
    </div>
    </div>
    <div class="footersecondtDiv" id="footerDiv">
    <h4>Solutions</h4>
    <Link to="/SmartCity Solution">Smart City Solutions </Link>
    <Link to="/data center solutions">Data Centre Solutions </Link>
     <Link to="/CCTV Surveillance Solution">CCTV Surveillance Solutions</Link>
  
    <Link to="/Rugged Military Grade">Rugged and Mil Grade Solutions</Link>
 <Link to="/WirelessSolutions">Wireless Solutions</Link>

    <Link to="/telecommunication solution">Telecom Products Solutions </Link>

    <h4>Services</h4>
    <Link to="/Cyber Security">Cyber Security</Link>
    <Link to="/Cloud Service">Cloud Service</Link>
    <Link to="/enterprise management">Enterprise Management</Link>
    <Link to="/intelligent service">Intelligent Industry</Link>
    <Link to="/Data AI Management Services">Data and AI Management</Link>
    <Link to="/Business Processes Services">Business Processes</Link>
   
 
    </div>
    <div class="footerthirdDiv" id="footerDiv">
    <h4>Contact</h4>
    <form action='javascript:sendEmail()'>
 
    <input type="text" placeholder="NAME :" name="Name:" id="footerinputname" required/>
    <input type="email" placeholder="EMAIL :" name="Email:" id="footerinputemail" required/>
    <input type="text" placeholder="MESSAGE :" name="Message:" id="footerinputmessage" required/>
  
    <button id="sendcontactform">Send Message</button>
  
    </form>
    </div>
    </footer>
    <div class="footerDownDivfirst"><a>Millennium Automation Private Limited <i>( Formerly known as Millennium Automation and Systems Pvt. Ltd.)</i> CIN: U31900DL2017PTC313995</a></div>
    <div class="footerDownDiv">
    <div id="mobile_footerlinks">
        <a href="https://www.facebook.com/people/Millennium-Automation-Pvt-Ltd/61555239089074/"><i  class="fa">&#xf09a;</i> <p></p></a>
        <a href="https://www.linkedin.com/company/maplworld/"><i  class="fa">&#xf0e1;</i><p></p></a>
        <a href="https://www.youtube.com/@MAPLWorld"><i  class="fa">&#xf16a;</i> <p></p></a>
        <a href="https://www.instagram.com/maplworld/?hl=en"><i  class="fa">&#xf16d;</i><p></p></a>
        <a href="https://twitter.com/MAPLWorld"><i class="fa"><img src="MAPL IMAGES/twitterpngimage.png" loading="lazy" alt="" /></i> <p></p></a>
        
        <a href="https://wa.me/9311240651"><i  class="fa">&#xf232;</i> <p></p></a>
    </div>
    <div id="footer_copyrightid">
    <Link to="/terms">© Copyright 2023 | All rights reserved.</Link>
       </div>
    <div id="footer_sitemaplinks">
      <Link to="/sitemap">Sitemap </Link>
      <Link to="/terms">| Terms of Service </Link>
      <Link to="/privacy">| Privacy </Link>
      <a onClick={cookiesopen}>| Cookies </a>
      <Link to="/contact">| Contact </Link>
      
    </div>
    </div>
    <div id="ChatBox">
    <div class="chatNav">
    <h5 id="navPid">Waiting to Chat</h5>
    <h5 onClick={chatboxClose}>_</h5>
    <h5 onClick={chatboxClose}>&#x2613;</h5>
    </div>
    <div id="chatpage">
    <img src="MAPL IMAGES/5-2-globe-png-image.png"/>
    <h5 id="loadingdiv">
    </h5>
    </div>
    <div id="chatinput">
    <input type="text" placeholder="Type your message..." ID="ChatInputValue"/>
    <button onClick={chatsendbtncheck} id="chatsendbtn_id"><i class="material-icons">&#xe163;</i></button>
    </div>
    </div>
    <div id="chatOpenButton" onClick={chatboxOpen}>
    <i class="material-icons">&#xe0c9;</i>
    <span id="letschat_text">Let's Chat!</span>
    </div>
    <table id="QuestionTable">
    <tr>
    <td>How can we help you today?</td>
    </tr>
    <tr>
    <td>Ok, please' Enter Your Email...</td>
    </tr>
    <tr>
    <td> Enter Your Name</td>
    </tr>
    <tr>
    <td>Our team will contact you. Thankyou!</td>
    </tr>
    </table>
    </div>
        </div>
    )
}
export default Footer;