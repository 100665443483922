import './Csr.css'
import React from 'react';
function Csr(){

    function csrpageloadfunction(){
       
        var windowHeightcsrnav= window.innerHeight;
        var windowWidthcsrnav= window.innerWidth;
        if(windowWidthcsrnav>windowHeightcsrnav){
        document.getElementById('CsrHome').style.height=windowHeightcsrnav+"px";
        }else{
        document.getElementById('CsrHome').style.height="100%";
        }
        
    }
    function csrpageloadfunction(){
       
        var windowHeightcsrnav= window.innerHeight;
        var windowWidthcsrnav= window.innerWidth;
        if(windowWidthcsrnav>windowHeightcsrnav){
        document.getElementById('CsrHome').style.height=windowHeightcsrnav+"px";
        }else{
        document.getElementById('CsrHome').style.height="100%";
        }
        
    }
    return(
        <div>
            <div id="Csrpage">
<div id="CsrHome">
    <div id="Csrhomebackgrounddiv">
<div id="Csrhomebackgroundimg"></div>

<div id="Csrhomebackgroundcontant">
    <div>
    <h2>CSR: A Commitment!</h2>
</div>
</div>
    </div>
<div id="CsrhomeDetaildiv">
<section style={{backgroundColor:"var(--main-bg-color30)"}}>
<div id="CsrhomeDetailIcondiv">
<i class="fa">&#xf21e;</i>
</div>
<div id="CsrhomeDetailcontantdiv">
<h4>Health Care</h4>
<p>Transforming lives through accessible healthcare, promoting wellness, and fostering community resilience with compassion and dedication. </p>
</div>
</section>
<section style={{backgroundColor:"var(--main-bg-color50)"}}>
<div id="CsrhomeDetailIcondiv">
<i class="fa">&#xf02d;</i>
</div>
<div id="CsrhomeDetailcontantdiv">
<h4>Education and Skills Development</h4>
<p>Illuminating futures through education: Equipping with skills to thrive, creating paths to success and prosperity.</p>
</div>
</section>
<section style={{backgroundColor:"var(--main-bg-color30)"}}>
<div id="CsrhomeDetailIcondiv">
<i class="fa">&#xf182;</i>
</div>
<div id="CsrhomeDetailcontantdiv">
<h4>Community Feast</h4>
<p>Nourishing communities, fostering unity, providing free feasts, and sharing love through the joy of food. </p>
</div>
</section>
</div>
</div>
<div id="CSrNav" onMouseEnter={csrpageloadfunction}>
<i class="material-icons">&#xe2c4;</i>&nbsp;
<a href="MAPL PDFS/CSR Annual Plan.pdf">CSR Annual Plan</a>&nbsp;|&nbsp;
<i class="material-icons">&#xe2c4;</i>&nbsp;
<a href="MAPL PDFS/CSR policy.pdf">CSR Policy</a>
</div>
<h3>CSR Initiatives</h3>
<div id="CsrAboutDiv">

<div id="CsrhealthProject_Div">

<div id="csrAbout_div" >

<span class="ServiceAboutleft_span">
<div>
    <iframe src="https://www.youtube.com/embed/9JB-1BXOqOo?si=fufLL4ngns8XlahH&controls=0?autoplay=1&mute=1" allow='autoplay; encrypted-media'frameborder="0" allowfullscreen id="casestudies"></iframe>
</div>
<section class="ServiceAboutright_section">
<h4>Sponsored Gas Generator </h4>
<p>We have strategically contributed to the cause by supplying a high-quality 125 KVA Gas Generator to Mata Gujri Medical Center. This cutting-edge equipment will play a crucial role in ensuring a seamless and uninterrupted power supply to the medical center. </p>
</section>
</span>
</div>
<div id="csrAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Sponsoring Dialysis Treatment</h4>
<p>We have also taken up the noble initiative of sponsoring dialysis and required vaccinations for the patients at the Mata Gujri Medical Center.
Our commitment extends to addressing the challenges faced by patients lacking the means for life-saving treatments.</p>
</section>
<div><iframe src="https://www.youtube.com/embed/QJR2vqqGvsQ?si=kO29JOToWb3_3E9g&controls=0?autoplay=1&mute=1" allow='autoplay; encrypted-media'frameborder="0" allowfullscreen id="casestudies"></iframe></div>
</span>
</div>
</div>
</div>

<div id="csrAbout_div" >
<span class="ServiceAboutleft_span">
<div><iframe src="https://www.youtube.com/embed/108KtRm5y_o?si=un62VPM7RU_pU4kr&controls=0?autoplay=1&mute=1" allow='autoplay; encrypted-media'frameborder="0" allowfullscreen id="casestudies"></iframe></div>
<section class="ServiceAboutright_section">
<h4>Feast, Laugh, Connect </h4>
<p>We organized a heartwarming community feast on January 17, 2024! The event brought joy with delicious food, laughter, and a strong sense of community. It was a tremendous success, fostering stronger bonds and celebrating the unique diversity that enriches our
special community. </p>
</section>
</span>
</div>
<div id="csrAbout_div" >
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Success in Togetherness</h4>
<p> On April 1, 2023, we hosted a fantastic community feast! There was a spread of delicious food, laughter, and a wonderful community atmosphere. The event was a success, strengthening our connections and showcasing the fantastic diversity that defines our unique community.</p>
</section>
<div><iframe src="https://www.youtube.com/embed/esNJxWu3epg?si=GEeKWry-haUo8OZW&controls=0?autoplay=1&mute=1" allow='autoplay; encrypted-media'frameborder="0" allowfullscreen id="casestudies"></iframe></div>
</span>
</div>
<div id="csrAbout_div" >
<span class="ServiceAboutleft_span">
<div><iframe src="https://www.youtube.com/embed/4MTIGfE5fls?si=VPVti39-0INh1q88&controls=0?autoplay=1&mute=1" allow='autoplay; encrypted-media'frameborder="0" allowfullscreen id="casestudies"></iframe></div>
<section class="ServiceAboutright_section">
<h4>Shared Joyful Plates</h4>
<p>In our community feasting initiatives, we celebrate unity through nourishing meals. Committed to promoting well-being, we prioritize food and nutrition as essential pillars. Our gatherings foster a sense of togetherness, sharing wholesome meals that contribute to physical health, social bonding, and the overall vitality of our community.</p>
</section>
</span>
</div>
<div id="CSRInitiativesDiv" data-aos="fade-up">
<section>
<h4>Education and Skills Development</h4>
<p>MAPL is unwavering in its commitment to nurturing a future where education is a beacon of hope and skills are pathways to success. In our pursuit of educational excellence, we have established coaching centers dedicated to providing free education to underprivileged students. These centers are not just classrooms; they are transformative spaces where knowledge is a catalyst for change.</p>
<p>Our focus goes beyond traditional education. We believe in equipping individuals with practical skills that empower them in the real world. Through skill development programs, MAPL ensures that learners not only acquire knowledge but also gain the expertise needed to thrive in their chosen fields.</p>
</section>
<div id="CSRInitiativesImgDiv">
</div>
</div>
</div>
        </div>
    )
}
export default Csr;