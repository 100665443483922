import React from 'react';
function SustainabilityService(){
    return(
        <div>
            <div id="Services_page">
<div id="ServiceHome_div">

</div>
<div id="ServiceHome_Detail">
    <h2>Sustainability With MAPL</h2>
    <h4>Accelerate to Net Zero: A Sustainable Future</h4>
    <p>At MAPL, we propel organizations toward net zero, navigating the entire journey from commitment to tangible sustainable results. Our comprehensive sustainability framework is designed to expedite your progress across key stages, ensuring a robust net zero strategy and fostering enduring sustainable value and impact.
    </p>
    </div>
<div id="ServiceAbout_div">
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/SUSTAINABILITY/Commit_ Define Your Sustainable Strategy.png" loading="lazy" alt="" /></div>
<section class="ServiceAboutright_section">
<h4>Commit: Define Your Sustainable Strategy</h4>
<h5>Evaluate and Innovate</h5>
<p>Assess your current state and collaborate with MAPL to craft net zero and circular economy ambitions. Develop a bespoke roadmap, leveraging optimum levers, including innovative business models for the next economy. Engage stakeholders to shape a universal, sustainable future.</p>
</section>
</span>
</div>
<div id="ServiceAbout_div">
<span class="ServiceAboutright_span">
<section class="ServiceAboutleft_section">
<h4>Act: Put Sustainability at the Heart</h4>
<h5>Transformation Levers</h5>
<p>Embarking on sustainable actions is a significant challenge, and MAPL is here to guide you. We've established transformation levers across the value chain, providing a roadmap to navigate the most appropriate course of action for your organization.</p>
</section>
<div><img src="MAPL IMAGES/SUSTAINABILITY/Act_ Put Sustainability at the Heart.png" loading="lazy" alt="" /></div>
</span>
</div>
<div id="ServiceAbout_div">
<span class="ServiceAboutleft_span">
<div><img src="MAPL IMAGES/SUSTAINABILITY/Monitor and Report_ Enable a Net Zero Strategy.png" loading="lazy" alt="" /></div>
<section class="ServiceAboutright_section">
<h4>Monitor and Report: Enable a Net Zero Strategy</h4>
<h5>Leveraging Reliable Data</h5>
<p>MAPL emphasizes the importance of data in sustainable transformation. Enable and scale your journey by leveraging reliable data. Create a coherent strategy, utilizing data to transform your value chains. Scale up data initiatives and embrace AI to power sustainable actions, ensuring your organization is on the path to a net-zero future.</p>
</section>
</span>
</div>
<div id="ServiceThird_div">
<img src="MAPL IMAGES/SUSTAINABILITY/We're Your Partners in Climate Transition.png" loading="lazy" alt="" />
<div>
<h4>We're Your Partners in Climate Transition</h4>
<p>MAPL is committed to partnering on your climate transition, assisting in reinventing your organization to create enduring sustainable value and impact. Let's collaborate to build a sustainable future together.</p>
</div>
</div>
</div>
        </div>
    )
}
export default SustainabilityService;