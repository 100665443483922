
import './terms.css';
import React from 'react';
function Terms(){
    return(

        <div>
            <div id="Terms_of_Service">
<div id="TermsHomeDiv">
<div>
<h1>
Terms of Service
</h1>
</div>
</div>
<h3>TERMS OF USE</h3>
<p id="mainpragrap">Thank you for visiting our Website.</p>
<div id="TermsAboutDiv">
<span>Please read these terms of use <b>(“Terms”)</b> carefully before you start to use the website <a href>www.maplworld.com </a> <b>(“Website”).</b> By using our Website, you indicate that you accept these Terms and you agree to abide by them.</span>
<br/>
<h4>Applicability</h4>
<p>The term MAPL shall mean Millenium Automation Pvt. Ltd. and the term User shall mean such person who is browsing the Website. By using the Website, the User agrees to follow and be bound by these Terms and these Terms may be amended or updated at any time by MAPL, without any notice to User. The Website is owned and operated by MAPL, a company incorporated under the Companies Act, 2013, having its registered office at E-48/9, Okhla Industrial Area, Phase II, New Delhi-110020. All content present on this Website is the exclusive property of MAPL including all the software, text, images and graphics used on this Website.</p>
<h4>Amendments</h4>
<p>We reserve the right to amend/modify these Terms from time to time. We will notify you of any changes or amendments to these Terms as and when required under law to do so. Your access to, and use of the Website following any change or amendment constitutes your agreement to follow and be bound by the updated Terms. You are encouraged to check this page for any amendments or modifications to the Terms to understand how it affects your access to, and usage of the Website. </p>
<ul>
<h4></h4>
<li>You may use the Website only if you are recognized as a legal person under law and are capable of entering into a formal contract as per all applicable laws and regulations. In the event you are a minor (below the age of 18), kindly do not provide your contact details to us, until and unless an adult accepts these Terms on your behalf. </li>
<li>No material from this Website may be copied, modified, reproduced, republished, uploaded, transmitted, posted or distributed in any form without obtaining prior written consent from MAPL.</li>
<li>The unauthorized use of any the materials appearing on this Website may violate the proprietary rights of MAPL, including copyright, trademark and other applicable laws. MAPL shall have the right to initiate civil and/or criminal proceedings for any unauthorized use. </li>
<li>You will not attempt to gain unauthorized access to any functions or features, computing systems or networks connected to any of our servers in any manner including through hacking, password mining or any other means. You will not make any attempts to obtain or obtain any information, through any means, which is intentionally not made available on the Website.</li>
<li>MAPL may terminate User's access to the Website at any time for any reason, without giving any notice of the same to the User.</li>
<li>You may not create any link to our site without our prior written consent.</li>
<li>The User understands and agrees that MAPL shall have no responsibility for any damage to the User's computer system or loss of data that results from the use of or download of any content, materials, or information from the Site.</li>
<li>The User shall access the Website and the information and material available on it only for lawful purposes. Any attempt by the User to copy, modify or reverse engineer the Website shall give right to MAPL to initiate legal action against such User. </li>
</ul>
<h4>Disruption of Services</h4>
<p>While we will endeavor to ensure the Website is operational at all times for your use, your access to the Website may be disrupted on account of a technical or operational difficulty. In such an event, we will not be required to provide any notice of such disruption to you. </p>
<ul>
<h4>Disclaimers</h4>
<li>We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the Website or the information, products, services, or related graphics contained on the Website for any purpose. Please note that any reliance you place on such information is therefore strictly at your own risk.</li>
<li>MAPL makes no warranties or representations as to the fitness or merchantability, whether express or implied, for a particular purpose, with respect to any information, data, or statements made available on the Website.</li>
<li>3. You agree and understand that in no event will MAPL be liable for damages of any kind whatsoever, including without limitation, direct, incidental or consequential damages, arising out of the use of or inability to use the Website, or any information provided on the Website, or for any other reason.</li>
</ul>
<h4>Reliance on the Website</h4>
<p>Information and materials posted on our Website are not intended to amount to advice of any manner whatsoever on which reliance should be placed. We therefore disclaim all liability and responsibility arising from any reliance placed on such materials by any visitor to our site, or by anyone who may be informed of any of its contents.</p>
<h4>Hacking and Viruses</h4>
<p>You undertake not to commit any action or attempt to commit any action that may introduce any virus, trojan or other such material which is malicious on our Website. Upon breach of this condition, you shall be liable to indemnify MAPL against any loss suffered by it on account of your unlawful activities. </p>
<h4>Indemnity</h4>
<p>The User agrees to indemnify and hold harmless MAPL (including its directors, employees, agents, representatives and affiliates) against any and all losses, claims, damages as well as costs and expenses, arising from or related to your use and/or access to the Website, including to all material, graphics and information available on the Website. </p>
<h4>Governing Law and Dispute Resolution</h4>
<p>The interpretation and construction of these Terms and the use of the Website by you shall be construed according to the laws of India. Any dispute arising from the use of the Website shall be subject to the exclusive jurisdiction of the courts at New Delhi, India. </p>
<h4>Entire Agreement</h4>
<p>The Terms contained herein along with any amendments, privacy policy and any additional agreements you may enter into with us in connection with the use of the Website, shall constitute the entire agreement between you and us, concerning the use of the Website. </p>
<h4>Waiver</h4>
<p>No waiver of any Terms of use of the Website shall be deemed to be a waiver of any other Term, and our failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.</p>
<h4>Grievance Officer</h4>
<p>In case of any queries or concerns, kindly contact our grievance officer:</p>
<p>Grievance Officer: _____________</p>
<p>Email ID: ___________</p>
<p><b>The grievance officer will acknowledge your queries within a period of 24 hours and undertake to dispose it off within a period of 15 days. </b></p>
</div>
</div>
        </div>
    )
}
export default Terms;